$white: #ffffff;
$black: #000000;
$black2: #323436;
$black3: #3F4042;
$black4: #515461;
$black5: #333333;
$gray: #CBCBCB;
$gray2: #EDEDED;
$gray3: #9A9494;
$gray4: #F5F4F4;
$gray5: #9DA3AE;
$gray6: #B0BBCC;
$gray7: #536474;
$gray8: #CAD1DB;
$gray9: #677C90;
$gray10: #E5E5E5;
$gray11: #7C7C7C;
$green: #7ABC2F;
$green-hov: #4F742E;
$green2: #C7E893;
$green3: #78A93F;
$green4: #ABCC83;
$green5: #82A343;
$green8:  #4A7336;
$green9: #5DAF5B;
$green5: #333850;
$green6: #DFF0D8;
$orange: #FFB800;
$orange2: #B47600;
$orange3: #C78300;
$orange4: #FFDA7B;
$orange5: #FAB70C;
$bord: #EBEBEB;
$bord2: #DADADA;
$bord3: #ECEEF3;
$bord4: #D3D3D3;
$bord5: #ECECEC;
$bord6: #EAEAEA;
$bord7: #F9F9F9;
$bord8: #D7D7D7;
$bg: #FAFAFA;
$bg2: #FBF8FA;
$bg3: #F6F9F9;
$blue1: #468294;
$blue2: #8ECCDE;
$red1: #B16E6E;


$font-family: 'Ubuntu', sans-serif;
$font-family2: 'ProximaNova', sans-serif;
