@import "variables.scss";

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Regular.eot');
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Semibold.eot');
  src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
}


body {
  min-width: 320px;

  font-family: $font-family;
  //background: $bg;

  &.overfl {
    overflow: hidden;
    height: 100vh;
  }
}

.start-page {
  padding: 30px;

  &-logo {
    display: block;
    margin-bottom: 30px;
  }

  ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 60px;

    li {
      padding: 5px 0;

      b {
        font-size: 20px;
      }

      a {
        font-size: 16px;
        line-height: 140%;
        color: $black;

        &:hover {

        }
      }
    }
  }
}

div,
span,
a,
input,
textarea,
select,
button,
.button {
  &:focus {
    outline: none;
  }
}

svg {
  use {
    transition: 0.3s;
  }
}

ul {
  li {
    list-style: none;
  }
}

a {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
  }
}

.button {
  border: none;
  box-shadow: none;
  outline: none;
  transition: 0.3s;
  text-align: center;
  justify-content: center;

  &-bord {
    padding: 11px 24px;
    display: inline-block;

    border: 2px solid $black;
    border-radius: 10px;
    font-size: 16px;
    line-height: 19px;
    color: $black;

    &:hover {
      background: $black;
      color: $white;
    }
  }

  &-bord2 {
    display: inline-flex;
    align-items: center;
    padding: 11px 15px;

    font-size: 16px;
    line-height: 19px;
    color: $black;

    border: 2px solid $black;
    border-radius: 10px;

    img {
      margin-left: 5px;
    }

    &:hover {
      color: $white;
      background: $black;
    }
  }

  &-all {
    display: inline-flex;
    align-items: center;
    padding: 11px 15px;

    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    color: $black3;

    border: 1px solid $green;
    border-radius: 10px;

    img {
      margin-left: 5px;
    }

    &:hover {
      color: $black3;
      background: $green;

      .catalog-min & {
        color: $white;
      }
    }

    span {
      margin-right: 3px;
    }
  }

  &-primary {
    padding: 11px 24px;
    display: inline-flex;
    align-items: center;

    border-radius: 10px;
    background: $green;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    color: $white!important;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    img {
      margin-left: 5px;
    }

    &:hover {
      background: $green-hov;
    }
  }

  &-secondary {
    display: inline-flex;
    align-items: center;
    padding: 11px 24px;

    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $white;
    background: $green;
    border-radius: 10px;

    &:hover {
      background: $green-hov;
      color: $white;
    }
  }

  &-gr {
    padding: 11px 24px;
    display: inline-flex;
    align-items: center;

    color: $green-hov;
    background: $green6;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    border-radius: 10px;

    &:hover {
      color: $white;
      background: $green-hov;
    }
  }

  &-tx {
    padding: 11px 24px;
    display: inline-flex;

    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $black3;

    &:hover {
      color: $green-hov;
    }
  }
}

.container {
  max-width: 100%;

  padding-right: 130px;
  padding-left: 130px;
}

@media screen and (max-width: 1200px) and (min-width: 1500px) {
  .col-lg-3 {
    .catalog-min & {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
  }
}

.col-lg-2-1 {
  margin-bottom: 30px;

  @media (min-width: 1500px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}


h2 {
  margin-bottom: 0;

  font-weight: 500;
  font-size: 26px;
  line-height: 130%;
  color: $black;
}

h1 {
  margin-bottom: 0;
  margin-right: 8px;

  display: inline-block;
  vertical-align: bottom;

  font-weight: 500;
  font-size: 29px;
  line-height: 32px;
  color: $black;
}

h3 {
  margin-bottom: 30px;

  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: $black;
}

.wrapper {
  padding-left: 315px;

  &__inner {
    padding-left: 50px;
  }
}

.header {
  position: relative;

  border-bottom: 1px solid $bord;

  &__top {
    padding: 6px 0;

    background: $black2;

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-info {
      display: flex;
      align-items: center;
    }

    &-menu {
      ul {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        li {
          margin-left: 34px;

          a {
            font-size: 14px;
            line-height: 16px;
            color: $white;

            &:hover {
              color: $green;
            }
          }
        }
      }
    }
  }

  &__city {
    display: flex;
    align-items: center;
    margin-right: 52px;

    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $white;

    img {
      margin-right: 12px;
    }
  }

  &__tel {
    font-size: 14px;
    line-height: 16px;
    color: $gray;

    a {
      margin-left: 10px;

      font-weight: 500;
      font-size: 15px;
      line-height: 121.03%;
      color: $green;

      &:hover {
        color: $green-hov;
      }
    }
  }

  &__bottom {
    padding: 17px 0;

    background: $bg2;
    border-bottom: 1px solid $bord;

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-info {
      display: flex;
      align-items: center;

      &-item {
        margin-left: 37px;

        position: relative;

        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $black3;

        &-cart {
          .header__bottom-info-notif {
            left: 17px;
          }
        }

        img {
          height: 29px;
          margin-right: 10px;
          margin-bottom: 5px;
        }

        &:hover {
          color: $orange;
        }
      }

      &-notif {
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        left: 26px;
        top: 0;

        background: $green-hov;
        border-radius: 100px;
        font-weight: 700;
        font-size: 10px;
        line-height: 121.03%;
        color: $white;
      }
    }
  }

  &__search {
    width: 52%;

    position: relative;

    input {
      width: 100%;
      height: 46px;
      padding: 10px 55px;

      border-radius: 14px;
      background: $gray2;
      font-size: 16px;
      line-height: 19px;
      color: $black2;
      border: none;
      box-shadow: none;
      background: $gray2;

      &::-webkit-input-placeholder { color: $gray3; }
      &:-ms-input-placeholder { color: $gray3; }
      &::placeholder { color: $gray3; }
    }

    &-btn {
      width: 24px;
      height: 29px;

      border: none;
      background: transparent;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      margin: auto;
      z-index: 2;

      cursor: pointer;
    }

    &-clean {
      width: 12px;
      height: 12px;

      position: absolute;
      top: -11px;
      bottom: 0;
      right: 19px;
      margin: auto;
      z-index: 2;
      cursor: pointer;
    }
  }

  &__toggler {
    display: none;
  }

  &__logo {
    &-mob {
      display: none;
    }
  }

  &__menu {
    &-mob {
      display: none;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 38px;

  font-weight: 700;
  font-size: 13px;
  line-height: 160.03%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $green-hov!important;

  img {
    margin-right: 15px;
  }

  span {
    position: relative;
    top: 12px;
  }
}

.menu {
  width: 315px;
  height: 100vh;
  padding: 12px 36px;
  min-height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  background: $white;
  box-shadow: 1px 4px 13px rgba(173, 168, 154, 0.12);
  overflow: auto;

  &__top {
    display: none;
  }

  ul {
    & > li {
      border-top: 1px solid $bord;

      & > a {
        display: block;
        padding: 18px 0;

        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $black3;

        background-image: url("../images/svg/arrow2.svg");
        background-size: 7px 11px;
        background-repeat: no-repeat;
        background-position: center right;

        &:hover {
          color: $green-hov;
        }
      }

      @media screen and(min-width: 991px) {
        &:hover {
          & > .menu__submenu {
            visibility:visible;
            opacity:1;

          }
        }
      }
    }
  }

  &__submenu {
    padding: 55px 182px 30px 82px;
    width: calc(100% - 279px);
    height: 100vh;
    min-height: 100%;
    overflow: auto;

    position: fixed;
    top: 0;
    left: 279px;

    visibility:hidden;
    opacity:0;
    transition:visibility 0.3s linear,opacity 0.3s linear;

    background: $white;
    //column-count: 2;
    //column-gap: 20px;

    &-list {
      float: left;
      width: 48%;

      margin-bottom: 30px;
    }

    &-logo {
      width: 130px;
      padding: 55px 0 30px;

      position: absolute;
      right: 20px;
      top: 0;

      text-align: center;

      img{
        display: block;
        margin-bottom: 16px;
      }
    }

    &-title {
      display: block;
      margin-bottom: 12px;

      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $black3;

      &:hover {
        color: $green-hov;
      }
    }

    ul {
      display: inline-block;

      li {
        margin-bottom: 15px;
        border: none;

        &:last-of-type {
          margin-bottom: 0;
        }

        a {
          padding: 0!important;

          text-transform: inherit;
          font-size: 14px;
          line-height: 16px;
          color: $gray11;
          background: transparent;
          font-weight: 400;
          letter-spacing: 0;

          &:hover {
            color: $green-hov;
          }
        }
      }
    }
  }
}

.banner {
  padding: 45px 0 20px;

  &__big {
    height: 375px;

    &-slider {
      opacity: 0;

      &.slick-initialized {
        display: block;
        opacity:1;
        transition:opacity .3s ease-out;
      }

      &-item {
        height: 375px;
        padding: 45px 50px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 5px;
      }

      &-title {
        margin-bottom: 11px;

        font-weight: 500;
        font-size: 32px;
        line-height: 37px;
        color: $black;
      }

      &-tx {
        margin-bottom: 25px;

        font-size: 16px;
        line-height: 140%;
        color: $black;
      }
    }
  }

  &__min {
    display: block;
    height: 177px;
    margin-bottom: 20px;

    padding: 30px 20px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 5px;

    &-title {
      display: block;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;
    }
  }
}

.slick {
  &-arrow {
    width: 35px;
    height: 35px;

    position: absolute;
    bottom: 40px;
    z-index: 3;

    background-color: rgba(255,255,255,0.25);
    border-radius: 100px;
    box-shadow: none;
    border: none;
    overflow: hidden;
    color: transparent;
    background-image: url("../images/svg/arrow.svg");
    background-repeat: no-repeat;
    background-size: 5px 9px;
    background-position: center;
    cursor: pointer;

    .banner & {
      background-color: rgba(255,255,255,0.25);
      border-radius: 100px;
      border: none;

      &:beefore {
        content: '';
        display: block;
        width: 5px;
        height: 9px;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        background-image: url("../images/svg/arrow.svg");
        background-repeat: no-repeat;
        background-size: 5px 9px;
        background-position: center;
      }
    }

    &:hover {
      background-color: $white;
    }
  }

  &-prev {
    left: 50px;
  }

  &-next {
    left: 95px;
    transform: rotate(180deg);
  }
}

.delivery {
  padding: 45px 0 95px;

  &-tw {
    padding: 100px 0;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__img {

    }

    &__info {
      padding-left: 60px;

      p {
        margin-bottom: 35px;

        font-size: 16px;
        line-height: 154%;
        color: $black4;
      }
    }

    &__title {
      margin-bottom: 27px;

      font-family: $font-family2;
      font-weight: 600;
      font-size: 30px;
      line-height: 39px;
      color: $black;
    }

    &__list {
      &-item {
        display: inline-flex;
        align-items: center;
        margin-right: 80px;
        margin-bottom: 20px;

        font-size: 14px;
        line-height: 16px;
        color: $black3;

        img {
          margin-right: 15px;
        }
      }
    }
  }

  &__info {
    padding: 0 50px;

    text-align: center;

    &-title {
      margin-top: 27px;
      margin-bottom: 20px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;
    }

    p {
      font-size: 16px;
      line-height: 154%;
      color: $black4;
    }
  }

  &__order {
    height: 100%;
    padding: 67px 115px 80px;

    border-radius: 5px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    &-title {
      margin-bottom: 15px;

      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
    }

    p {
      margin-bottom: 30px;

      font-size: 16px;
      line-height: 154%;
      color: $black4;
    }
  }
}

.bottom-block {
  background: $gray4;

  &__inner {
    display: flex;
  }

  &__title {
    margin-bottom: 25px;

    font-family: $font-family2;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black;
  }

  &__btns {
    &-item {
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }

  &__info {
    width: 40%;
    padding: 65px 0;
  }

  &__img {
    position: relative;

    &-tx {
      position: absolute;
      right: 47px;
      top: 101px;
      z-index: 2;

      font-size: 16px;
      line-height: 22px;
      color: $black3;
    }
  }
}

.footer {
  background: $gray4;
  border-top: 1px solid $bord;

  &__top {
    &-inner {
      padding: 22px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__bottom {
    border-top: 1px solid $bord;

    &-inner {
      padding: 8px 0;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-descr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 312px;
    }

    &-info {
      margin-bottom: 0;

      li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 22px;

        position: relative;

        font-size: 13px;
        line-height: 18px;
        color: $gray3;

        &:after {
          content: '';
          width: 3px;
          height: 3px;
          display: block;

          position: absolute;
          right: -15px;
          top: 0;
          bottom: 0;
          margin: auto;

          background: $gray3;
          border-radius: 10px;
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }

        a {
          color: $gray3;
          font-size: 13px;
          line-height: 18px;

          &:hover {
            color: $orange;
          }
        }
      }
    }
  }

  &__dev {
    display: inline-block;
    vertical-align: middle;

    font-size: 13px;
    line-height: 18px;
    color: $gray3;

    &:hover {
      color: $orange;
    }
  }

  &__soc {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;

    li {
      margin-right: 7px;
      display: inline-block;
      vertical-align: middle;

      a {
        svg {
          width: 22px;
          height: 22px;

          use {
            color: $black3;
          }
        }

        &:hover {
          svg {
            use {
              color: $orange;
            }
          }
        }
      }
    }
  }

  &__menu {
    margin-bottom: 0;

    li {
      display: inline-block;
      margin-right: 36px;

      a {
        letter-spacing: 0.07em;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        color: $black3;

        &:hover {
          color: $orange;
        }
      }
    }
  }

  &__tel,
  &__mail {
    margin-left: 46px;

    font-weight: 500;
    font-size: 14px;
    line-height: 121.03%;
    color: $black3;
    text-transform: uppercase;

    &:hover {
      color: $orange;
    }
  }
}

.catalog__item-amount {
  display: none;

  .ct & {
    display: block;
  }
}

.ct {
  .catalog__item-buy {
    display: none;
  }
}

.catalog__item-buy {
  cursor: pointer;
}

.catalog {
  margin: 50px 0 100px;

  &-min {
    margin-bottom: 25px;

    &-tw {
      margin-bottom: 85px;
    }

    &__top {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9px 20px;

      background: $gray4;
      border-radius: 14px;

      &-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: $black;

        img {
          margin-right: 20px;
        }
      }
    }

    &__labels {
      margin-bottom: 10px;

      &-item {
        padding: 3px 21px;
        margin-right: 5px;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: middle;

        background: $gray2;
        border-radius: 10px;
        border: 1px solid transparent;


        span {
          font-size: 16px;
          line-height: 175%;
          color: $black4;
        }

        img {
          margin-right: 10px;
        }

        &:hover {
          border-color: $green;
          background: $white;
        }

      }
    }
  }

  &__item {
    height: 100%;
    padding-bottom: 90px;

    position: relative;

    border: 1px solid $bord;
    border-radius: 15px;
    background: $white;
    overflow: hidden;

    &-bt {
      padding-bottom: 120px;
    }

    &-top {
      position: relative;
    }

    &-bottom {
      width: 100%;

      position: absolute;
      left: 0;
      bottom: 0;
    }

    &-label {
      width: 43px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 8px;
      left: 11px;
      z-index: 3;

      border-radius: 100px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;

      & + .catalog__item-label {
        left: 45px;
        z-index: 2;

        & + .catalog__item-label {
          left: 82px;
          z-index: 1;
        }
      }

      span {
        position: relative;
        z-index: 3;
      }

      &:before {
        content: '';
        width: 39px;
        height: 39px;
        display: block;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;

        border: 1px solid transparent;
        border-radius: 100px;
      }
      &:after {
        content: '';
        width: 37px;
        height: 37px;
        display: block;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;

        border: 1px solid transparent;
        border-radius: 100px;
      }

      &-hit {
        background: $gray6;
        color: $gray7;

        &:before {
          border-color: $gray9;
        }

        &:after {
          border-color: $gray8;
          background: $gray6;
        }
      }

      &-new {
        background: $green2;
        color: $green3;

        &:before {
          border-color: $green3;
        }

        &:after {
          border-color: $green4;
          background: $green2;
        }
      }

      &-sale {
        background: $orange;
        color: $orange2;

        &:before {
          border-color: $orange3;
        }

        &:after {
          border-color: $orange4;
          background: $orange;
        }
      }
    }

    &-fav {
      width: 25px;
      height: 25px;

      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;

      cursor: pointer;

      svg {
        width: 25px;
        height: 25px;

        use {
          color: $gray5;
        }
      }

      &:hover {
        svg {
          use {
            color: $green;
          }
        }
      }

      .catalog__item-favorites & {
        background-image: url("../images/svg/like2.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px 18px;

        svg {
          width: 21px;
          height: 18px;
          display: none;
        }
      }
    }

    &-img {
      //height: 247px;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        width: 100%;
        max-width: 100%;
        max-height: 247px;
      }
    }

    &-tx {
      padding: 18px 15px 0 15px;
    }

    &-title {
      display: block;
      margin-bottom: 5px;

      font-size: 16px;
      line-height: 19px;
      color: $black3;

      &:hover {
        color: $orange;
      }
    }

    &-numb {
      font-size: 14px;
      line-height: 16px;
      color: $gray3;
    }

    &-info {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-price {
      &-old {
        margin-bottom: 3px;

        font-size: 12px;
        line-height: 14px;
        color: $gray3;
        text-decoration: line-through;
      }

      &-now {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: $green-hov;
      }
    }

    &-buy {
      width: 123px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $white;
      border-radius: 10px;
      background: $green;

      img {
        margin-left: 5px;
      }

      &:hover {
        background: $green-hov;
        color: $white;
      }
    }

    &-offer {
      width: 100%;
      display: block;
      padding: 10px 15px;

      font-size: 14px;
      line-height: 16px;
      color: $green-hov;
      border-top: 1px solid $bord;
      text-align: center;
    }

    &-amount {
      width: 123px;
      height: 38px;

      position: relative;

      border-radius: 10px;
      border: 1px solid $bord;
      overflow: hidden;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;

        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;

        background: transparent;
      }
      input {
        width: 100%;
        height: 100%;

        font-size: 18px;
        line-height: 21px;
        color: $black3;
        text-align: center;

        border: none;
        box-shadow: none;
        outline: none;
      }

      .up,
      .down {
        width: 35px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;

        border-radius: 10px;
        background: $gray4;
        cursor: pointer;
      }

      .up {
        right: 2px;
      }

      .down {
        left: 2px;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 0;
    margin-bottom: 30px;

    border-bottom: 1px solid $bord;
  }

  &__sorting {
    width: 100%;
    text-align: right;

    &-title {
      display: inline-block;
      vertical-align: middle;

      position: relative;
      top: -2px;

      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $black3;
    }

    &-list {
      margin-bottom: 0;
      display: inline-block;
      vertical-align: middle;

      li {
        height: 30px;
        display: inline-block;
        vertical-align: middle;

        a {
          padding: 7px 18px;

          font-weight: 500;
          font-size: 13px;
          line-height: 121.03%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: $gray3;
          border: 1px solid transparent;
          border-radius: 12px;

          &:hover {
            color: $green;
          }
        }

        &.active {
          a {
            color: $black3;
            border-color: $green;
          }
        }
      }
    }
  }

  &__list {

  }

  &-brand-title {
    padding: 30px 0;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__in {
      position: relative;
      
      &:hover {
        .catalog-brand-title__in-list {
          display: block;
        }
      }

      &-list {
        display: none;
        width: 260px;
        padding: 15px 30px;

        position: absolute;
        top: 60px;
        right: 0;
        z-index: 5;

        background: $white;
        border-radius: 10px;

        &:before {
          content: '';
          width: 100%;
          height: 30px;

          background: transparent;
          position: absolute;
          top: -23px;
          left: 0;
        }

        &:after {
          content: '';
          width: 21px;
          height: 21px;
          display: block;
          position: absolute;
          right: 77px;
          top: -8px;
          background-image: url(../images/svg/ar1.svg);
          background-repeat: no-repeat;
        }

        &-item {
          padding: 12px 0;
          display: block;

          border-bottom: 1px solid $bord;

          &:last-of-type {
            border: none;
          }

          span {
            margin-left: 15px;
            font-size: 14px;
            line-height: 16px;
            color: $black3;
          }

          &:hover {
            span {
              color: $green;
            }
          }
        }
      }
    }

    &__inner {

    }

    &__holder {
      margin-top: 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__logo {
      width: 80px;
      height: 80px;

      position: absolute;
      top: 0;
      left: 0;

      border-radius: 1000px;
      background: $white;

      &:before {
        content: '';
        width: 76px;
        height: 76px;
        display: block;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;

        border: 2px solid $blue1;
        border-radius: 100px;
      }
      &:after {
        content: '';
        width: 70px;
        height: 70px;
        display: block;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;

        border: 2px solid $blue2;
        border-radius: 100px;
      }
    }

    &__main {
      min-height: 80px;
      position: relative;
      padding-left: 110px;

      h1 {
        display: block;
        padding-top: 14px;
        margin-bottom: 5px;
      }
    }
  }

  &-subscription {
    margin-bottom: 70px;

    &-bord {
      margin: 70px 0;
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      margin-bottom: 25px;

      border-bottom: 1px solid $bord;

      p {
        margin-bottom: 0;

        font-size: 16px;
        line-height: 21px;
        color: $green5;
      }

      &-link {
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        color: $green-hov;
        text-decoration: underline;

        &:hover {
          color: $green;
        }
      }
    }

    &__list {
      .catalog-subscription-bord & {
        padding: 35px 0;

        border-top: 1px solid $bord;
      }
    }

    &__item {
      padding-bottom: 50px;
      margin-bottom: 20px;
      height: 100%;

      position: relative;

      border-radius: 15px;
      overflow: hidden;
      border: 1px solid $bord;
      text-align: center;

      &-img {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: block;
          width: 100%;
          max-width: 100%;
          max-height: 247px;
        }
      }

      &-title {
        padding: 20px 20px 0;
        display: block;

        font-size: 16px;
        line-height: 110%;
        color: $black3;
        text-align: center;

        &:hover {
          color: $green-hov;
        }
      }

      &-offer {
        width: 100%;
        display: block;
        padding: 10px 15px;

        position: absolute;
        left: 0;
        bottom: 0;

        font-size: 14px;
        line-height: 16px;
        color: $green-hov;
        border-top: 1px solid $bord;
        text-align: center;

        &:hover {
          color: $green;
        }
      }
    }
  }
}

.subscription {
  &-list {
    margin-bottom: 45px;

    .col-lg-4 {
      &:last-of-type {
        .subscription-list__item {
          &:after {
            display: none;
          }
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;

      position: relative;

      &:after {
        content: '';
        width: 25px;
        height: 3px;
        display: block;

        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

        background-image: url("../images/svg/point.svg");
        background-repeat: no-repeat;
        background-size: 25px 3px;
      }

      &-img {
        width: 62px;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: center;

        box-shadow: 3px 4px 14px rgba(67, 26, 26, 0.08);
        border-radius: 10px;
        background: $white;
      }

      &-tx {
        width: calc(100% - 62px);
        padding-left: 20px;

        font-size: 16px;
        line-height: 20px;
        color: $green-hov;
      }
    }
  }

  &-tx {
    margin: 20px 0;

    & + .catalog {
      margin-top: 30px;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin-bottom: 0;

        font-size: 16px;
        line-height: 140%;
        color: $black5;
      }
    }

    &__link {
      font-weight: 500;
      font-size: 15px;
      line-height: 140%;
      color: $green-hov;
      text-decoration: underline;

      &:hover {
        color: $green;
      }
    }
  }
}

.breadcrumb {
  background: transparent;
  border-radius: 0;
  padding: 0;
  margin-bottom: 0;

  &-block {
    margin: 30px 0;

    .catalog-brand-title & {
      margin: 0;
    }
  }

  &-item {
    font-size: 14px;
    line-height: 16px;
    color: $black3;

    a {
      font-size: 14px;
      line-height: 16px;
      color: $gray3;

      &:hover {
        color: $orange;
      }
    }

    & + .breadcrumb-item::before {
      content: '';
      width: 3px;
      height: 3px;
      display: inline-block;
      margin: 0 10px;
      padding: 0;

      position: relative;
      top: -4px;

      border-radius: 10px;
      background: $gray3;
    }
  }
}

.title-main {
  margin: 30px 0 45px;

  &.tw {
    margin-bottom: 0!important;

    & + .catalog {
      margin-top: 20px;
    }
  }

  &__inner {
    h2 {
      margin-bottom: 0;
      margin-right: 8px;

      display: inline-block;
      vertical-align: bottom;

      font-weight: 500;
      font-size: 29px;
      line-height: 32px;
      color: $black;
    }
  }

  &__numb {
    display: inline-block;
    vertical-align: bottom;

    position: relative;
    top: -4px;

    font-size: 14px;
    line-height: 16px;
    color: $black3;
  }

  &-tw {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      position: relative;
      top: -4px;
    }

    .actions-slider & {
      padding-right: 105px;
    }
  }
}

.actions {
  margin-bottom: 110px;

  .col-sm-6 {
    margin-bottom: 30px;
  }

  &-slider {
    margin: 55px 0;

    &__inner {

    }

    &__sldr {
      margin: 0 -10px;
    }

    &__item {
      padding: 0 10px;
    }
  }

  &__item {
    position: relative;

    border: 1px solid $bord;
    border-radius: 15px;
    overflow: hidden;
    background: $white;

    &-label {
      display: inline-block;
      padding: 3px 8px;

      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 2;

      font-size: 13px;
      line-height: 16px;
      color: $white;
      background: $green;
      border-radius: 10px;
    }

    &-tw {
      padding: 15px 20px;
    }

    &-img {
      display: block;
      width: 100%;
      height: 190px;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &-data {
      margin-bottom: 7px;

      font-size: 14px;
      line-height: 16px;
      color: $gray3;
    }

    &-title {
      display: block;
      margin-bottom: 10px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;

      &:hover {
        color: $orange;
      }
    }

    &-firm {
      font-size: 14px;
      line-height: 16px;
      color: $green-hov;
    }

    &-tx {
      padding: 25px 20px 45px;
    }
  }

  &-item {
    margin-bottom: 100px;

    &__inner {
      display: flex;
    }

    &__left {
      width: 522px;
    }

    &__right {
      width: calc(100% - 522px);
      padding-left: 30px;

      p {
        font-size: 16px;
        line-height: 175%;
        color: $black4;
      }
    }

    &__img {
      margin-bottom: 20px;
    }

    &__info {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 50%;
        flex: 0 0 50%;
      }

      &-title {
        margin-bottom: 13px;

        font-size: 14px;
        line-height: 16px;
        color: $gray3;
      }

      &-tx {
        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $black;
      }
    }

    &__top {
      margin-bottom: 13px;
    }

    &__logo {
      width: 58px;
      height: 58px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;

      border-radius: 100px;
      border: 2px solid $blue1;
    }

    &__title {
      display: inline-block;
      vertical-align: middle;

      font-weight: 500;
      font-size: 26px;
      line-height: 130%;
      color: $black;
    }
  }
}

.slick {
  &-arrow {
    width: 39px;
    height: 39px;

    position: absolute;

    border-radius: 10px;
    border: 1px solid $green;
    background-color: transparent;
    background: url("../images/svg/arrow2.svg");
    background-repeat: no-repeat;
    background-size: 7px 11px;
    background-position: center;

    .card-product__slider & {
      bottom: 25px;
    }

    &:hover {
      background-color: $green;
    }
  }

  &-prev {
    transform: rotate(180deg);

    .actions-slider__sldr & {
      top: -63px;
      right: 65px;
      left: inherit;
    }

    .card-product__slider & {
      right: 70px;
      left: inherit;
    }
  }

  &-next {
    transform: rotate(0deg);

    .actions-slider__sldr & {
      top: -63px;
      right: 15px;
      left: inherit;
      transform: none;
    }

    .card-product__slider & {
      right: 20px;
      left: inherit;
    }
  }

  &-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 10px;

    li {
      width: 6px;
      height: 6px;
      display: inline-block;
      margin: 0 4px;

      background: $bord;
      border-radius: 10px;

      button {
        display: none;
      }

      &.slick-active {
        background: $green;
      }
    }
  }
}

.nicescroll {
  &-cursors {
    width: 5px!important;
    background: $green!important;
    border: none!important;
    cursor: pointer;
  }

  &-rails-vr {
    width: 5px!important;
    background: $bord!important;
  }
}

.filter {
  width: 100%;

  position: relative;

  &.open {
    display: block;
  }

  &-bl {
    display: none;
    padding-bottom: 100px;
    width: 315px;
    height: 100vh;
    min-height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    overflow: auto;

    background: $white;

    .open & {
      display: block;
    }
  }

  &__top {
    padding: 32px 38px;
    margin-bottom: 20px;

    background: $gray4;

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black5;
    }

    &-close {
      display: none;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;

      position: fixed;
      right: 0;
      left: 345px;
      top: 50px;
      z-index: 10;

      border-radius: 100px;
      border: 1px solid $bord;
      cursor: pointer;
      background:  $gray6;

      .open & {
        display: flex;
      }

      .filter-bl & {
        display: none;

        position: absolute;
        left: -70px;
        top: 50px;
      }

      &:hover {
        border-color: $green;
        background: $green;
      }
    }
  }

  &__title {
    padding: 13px 0;
    margin-bottom: 13px;

    position: relative;

    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $black3;
    border-bottom: 1px solid $bord;

    &:after {
      content: '';
      width: 12px;
      height: 8px;
      display: block;

      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;

      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../images/svg/arrow2.svg");
      background-size: 12px 8px;
      transform: rotate(90deg);
      transition: 0.3s;
    }

    &.open {
      &:after {
        transform: rotate(-90deg);
      }
    }
  }

  &__item {
    padding: 6px 38px;
    margin-bottom: 20px;

    position: relative;

    &:last-of-type {
      margin-bottom: 20px;
    }

    &-bord {
      margin-bottom: 0;
      padding: 0 38px;
      margin-top: -1px;
    }
  }

  &__range {
    &-item {
      width: 50%;
      padding-right: 10px;

      position: absolute;
      top: -85px;

      &:before {
        content: '';
        display: block;

        position: absolute;
        left: 16px;
        top: 4px;

        font-size: 13px;
        line-height: 18px;
        color: $black4;
      }

      &:nth-of-type(1) {
        left: 0;

        &:before {
          content: 'От';
        }
      }

      &:nth-of-type(2) {
        right: 0;

        &:before {
          content: 'До';
        }
      }
    }

    &.polzunok-container-6 {
      margin: 105px 0 0;

      box-sizing: border-box;
      outline: none;

      &:after {
        content: "";
        clear: both;
        display: table;
      }

      .ui-slider {
        height: 4px;

        position: relative;

        background: $bord;
        border-radius: 10px;

        .ui-slider-range {
          height: 4px;

          position: absolute;
          z-index: 1;

          border: none;
          background: $green;
        }
      }

      .ui-slider-handle {
        width: 16px;
        height: 16px;
        margin-left: -6px;

        position: absolute;
        top: -7px;

        cursor: pointer;
        background: $green;
        z-index: 2;

        border-radius: 100px;
        border: 4px solid $white;

        &:focus {
          outline: none;
        }
      }

      .polzunok-input-6-left,
      .polzunok-input-6-right {
        display: inline-block;
        width: 100%;
        height: 58px;
        padding: 24px 15px 10px;

        font-size: 16px;
        line-height: 19px;
        color: $black3;
        border: 1px solid $bord;
        border-bottom: 3px solid $bord;
        border-radius: 3px;
        text-align: left;
      }

      .polzunok-input-6-left {
        left: 0;
      }

      .polzunok-input-6-right {
        right: 0;

      }
    }
  }

  &__group {
    &-check {
      margin-bottom: 7px;

      input {
        &[type=checkbox] {
          display: none;

          & + label {
            width: 100%;
            padding-left: 24px;
            margin-bottom: 0;

            font-size: 14px;
            line-height: 16px;
            color: $black5;

            cursor: pointer;


            &:before {
              content: "";
              width: 16px;
              height: 16px;
              display: inline-block;
              margin: -5px 5px 0 0;

              position: absolute;
              top: 10px;
              left: 0;

              background-color: $bord;
              border-radius: 3px;

              transition: color ease .3s;
            }
          }

          &:checked  {
            & + label {
              &:before {
                background-repeat: no-repeat;
                background-image: url("../images/svg/check2.svg");
                background-size: 10px 8px;
                background-position: center;
                background-color: $green;
              }
            }
          }
        }
      }

      &.open {
        display: block;
      }
    }
  }

  &__holder {
    position: relative;

    .filter__item-list & {
      height: 178px;
      overflow: hidden;
    }

    .filter__item-bord & {
      padding: 7px 0;

      border-top: 1px solid $bord;
      border-bottom: 1px solid $bord;
    }
  }

  &__btn {
    height: 19px;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $black3;
    cursor: pointer;

    svg {
      width: 17px;
      height: 11px;
      margin-right: 5px;

      use {
        color: $black3;
      }
    }

    &:hover {
      color: $orange;

      svg {
        use {
          color: $orange;
        }
      }
    }

    &-b {
      padding: 0 38px;
      margin-top: 65px;

      .button {
        width: 100%;
        display: block;
      }
    }
  }

  &__check {
    position: relative;
  }

}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  &-bl {
    margin: 20px 0;
  }

  .page {
    &-item {
      margin: 0 2px;

      a, span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 39px;
        height: 39px;

        font-weight: 500;
        font-size: 15px;
        line-height: 121.03%;
        text-transform: uppercase;
        color: $black5!important;

        border-radius: 10px;
        border: 1px solid transparent;
        background: $white!important;

        &:hover {
          background: $green!important;
        }
      }

      span {
        cursor: inherit!important;
        color: $black4;
        transition: 0.3s;

        &:hover {
          color: $black5;
          background: $white!important;
        }
      }

      &.active {
        a {
          border-color: $green;
        }
      }

      &.prev,
      &.next {
        width: auto;

        position: relative;

        .tx {
          width: auto;
        }

        .arrow {
          width: 39px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          margin: 0;

          border: 1px solid $bord;
        }

        a {
          width: auto;

          font-weight: 500;
          font-size: 13px;
          line-height: 121.03%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          background: transparent!important;

          &:hover {
            color: $green!important;

            .arrow {
              border-color: $green;
              background: $green!important;
            }
          }
        }
      }

      &.next {
        margin-left: 25px;

        a {
          padding-right: 60px;
        }

        .arrow {
          right: 0;
        }
      }

      &.prev {
        margin-right: 15px;

        a {
          padding-left: 60px;
        }

        .arrow {
          left: 0;

          transform: rotate(180deg);
        }
      }
    }
  }
}

.notice {
  margin: 20px 0;

  &__inner {
    padding: 15px 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: $green6;

    p {
      margin-bottom: 0;

      font-size: 15px;
      line-height: 140%;
      color: $black3;
    }
  }

  &__link {
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $black3;

    &:hover {
      color: $green-hov;
    }
  }
}

.card-product {
  margin-bottom: 80px;

  position: relative;

  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-tw {
      display: flex;
      justify-content: space-between;
      margin: 45px 0;

      position: relative;
    }
  }

  &__slider {
    width: 42%;
    display: flex;
    padding-right: 20px;

    position: relative;

    &-label {
      height: 43px;
      margin-top: 25px;
      position: relative;
    }

    &-nav {
      width: 78px;

      &-item {
        cursor: pointer;
        padding: 0 0 8px;

        &-img {
          width: 77px;
          height: 77px;

          position: relative;

          border: 1px solid $bord;
          overflow: hidden;
          border-radius: 8px;

          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;

            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            opacity: 0;
            background: rgba(130, 163, 67, 0.2);
          }
        }
      }

      .slick-current {
        .card-product__slider-nav-item-img {
          border-color: rgba(130, 163, 67, 1);

          &:after {
            opacity: 1;
          }
        }
      }
    }

    &-for {
      width: calc(100% - 78px);
      margin-left: 30px;

      &-item {
        display: block;
        width: 428px;
        height: 428px;

        overflow: hidden;
        border-radius: 8px;
        border: 1px solid $bord;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .slick {
        &-arrow {
          background: url(../images/svg/arrow6.svg);
          background-repeat: no-repeat;
          background-position: center;
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &__description {
    width: 360px;

    .catalog__item-amount {
      display: none;
      width: 120px;
      height: 37px;

      margin-right: 15px;
      margin-bottom: 10px;


      @media screen and(min-width: 768px) {
        width: 147px;
        height: 41px;

        position: relative;
        top: 2px;

        input {
          height: 41px;
        }
      }
    }

    h1 {
      margin-bottom: 23px;

      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      color: $black;
      font-family: $font-family2;
    }

    p {
      margin-bottom: 30px;

      font-family: $font-family2;
      color: $black3;
      font-size: 16px;
      line-height: 21px;
    }

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      &-item {
        position: relative;

        font-size: 14px;
        line-height: 16px;
        color: $black3;

        & + .card-product__description-top-item {
          padding-left: 30px;

          &:before {
            content: '';
            width: 2px;
            height: 10px;
            display: inline-block;

            position: absolute;
            left: 15px;
            top: 3px;

            background: $gray3;
          }
        }
      }
    }

    &-points {
      margin-bottom: 10px;
      padding-left: 25px;

      font-size: 14px;
      line-height: 16px;
      color: $black3;

      background-image: url("../images/svg/check.svg");
      background-size: 15px 15px;
      background-repeat: no-repeat;
      background-position: left center;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-numb {
      font-size: 16px;
      line-height: 140%;
      color: $black5;
    }

    &-btns {
      //display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .button {
        display: inline-block;
        vertical-align: top;
        padding: 9px 33px;
        margin-right: 8px;
        margin-bottom: 10px;

        &-all {
          padding: 5px 33px;
        }

        @media screen and(min-width: 768px) {
          &-all {
            padding: 7px 25px;
          }
        }
      }

      &.am {
        .button-primary {
          display: none;
        }

        .catalog__item-amount {
          display: inline-flex;
          position: relative;
          top: 0;
        }
      }
    }

    &-price {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;

      &-old {
        font-size: 18px;
        line-height: 130%;
        color: $gray3;
        text-decoration: line-through;
      }

      &-now {
        margin-right: 20px;

        font-weight: 700;
        font-size: 26px;
        line-height: 130%;
        color: $black5;
      }
    }

    &-link {
      display: flex;
      align-items: center;
      padding: 15px;

      font-size: 16px;
      line-height: 22px;
      color: $green-hov;

      background-color: $white;
      box-shadow: 3px 4px 14px rgba(67, 26, 26, 0.08);
      border-radius: 10px;
      background-image: url("../images/svg/arrow2.svg");
      background-size: 7px 9px;
      background-position: 95% center;
      background-repeat: no-repeat;

      span {
        padding-left: 20px;
      }
    }
  }

  &__all {
    width: 195px;
    padding-left: 20px;

    &.lnk {
      position: relative;

      a {
        width: 100%;
        height: 100%;
        display: block;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }

    &-logo {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      height: 90px;
      border-radius: 14px;
      border: 1px solid $bord;
      text-align: center;

      img {
        max-width: 100%;
      }
    }

    &-reviews {
      padding: 15px;
      margin-bottom: 20px;
      height: 90px;
      border-radius: 14px;
      border: 1px solid $bord;
      text-align: center;

      position: relative;

      &-rating {
        margin-bottom: 5px;

        &-now {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;

          font-weight: 500;
          font-size: 20px;
          line-height: 130%;
          color: $black5;
        }

        &-all {
          display: inline-block;
          vertical-align: middle;

          font-size: 14px;
          line-height: 16px;
          color: $black3;
        }
      }

      &-list {
        &-item {
          display: inline-block;
          vertical-align: middle;

          &-act {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }

  &__left {
    width: 100%;
  }

  &__right {
    width: 303px;
  }

  &__tabs {
    .nav-tabs {
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;

        position: absolute;
        left: 0;
        bottom: 0;

        background: $bord;
      }
    }

    &-holder {
      display: flex;
      justify-content: space-between;
    }

    &-inner {
      padding-right: 120px;
      width: calc(100% - 303px);
    }

    &-text {
      margin-bottom: 30px;

      &-mob {
        &-btn {
          display: none;
        }
      }

      p {
        font-size: 16px;
        line-height: 175%;
        color: $black4;
      }

      &-title {
        margin-top: 35px;
        margin-bottom: 15px;

        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: $black;
      }

      ol {
        padding-left: 25px;

        li {
          font-size: 16px;
          line-height: 200%;
          color: $black4;
        }
      }
    }

    &-label {
      &-item {
        display: inline-flex;
        align-items: center;
        min-height: 47px;
        padding-left: 55px;
        margin-right: 45px;
        margin-bottom: 10px;

        position: relative;

        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        color: $black3;
        letter-spacing: 0.07em;
        text-transform: uppercase;

        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &-storage {
      margin-bottom: 35px;

      &-title {
        margin-bottom: 15px;

        font-size: 16px;
        line-height: 19px;
        color: $black3;
      }

      p {
        margin-bottom: 0;

        font-size: 16px;
        line-height: 23px;
        color: $black4;
      }
    }

    &-table {
      width: 60%;

      margin-bottom: 40px;

      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        position: relative;

        &:after {
          content: '';
          width: 100%;
          height: 1px;
          display: block;

          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;

          border: 1px dashed $bord2;
        }
      }

      &-cell {
        position: relative;
        z-index: 2;

        font-size: 16px;
        line-height: 19px;
        color: $black3;
        background: $white;

        &-city {
          color: $green-hov;
        }
      }
    }
  }

  &__other {
    width: 85%;
    margin-top: 80px;
  }

  &__product {
    &-row {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-top: 1px solid $bord;
    }

    &-descr {
      padding: 14px 0;
      min-height: 84px;
      padding-left: 122px;

      position: relative;
    }

    &-btn {
      .button {
        padding: 11px 36px;
      }

      &-main {
        display: block;
        width: 100%;
        padding: 16px;

        background: $gray4;
        cursor: pointer;

        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        color: $black3;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        text-align: center;

        .open & {
          display: none;
        }
      }
    }

    &-img {
      width: 84px;
      height: 84px;

      position: absolute;
      top: 0;
      left: 0;

      border: 1px solid $bord;
      border-radius: 80px;
      overflow: hidden;

      img {
        max-width: 100%;
      }
    }

    &-title {
      margin-bottom: 12px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;

      &:hover {
        color: $green-hov;
      }
    }

    &-firm {
      font-size: 14px;
      line-height: 19px;
      color: $black3;
    }

    &-price {
      &-now {
        color: $green-hov;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
      }

      &-old {
        font-size: 12px;
        line-height: 14px;
        text-decoration: line-through;
        color: $gray3;
      }
    }
  }

  &__deliv {
    display: flex;
    align-items: center;
    padding: 17px 24px;

    border-radius: 15px;
    border: 1px solid $bord;
    text-align: center;

    &-tx {
      font-size: 14px;
      line-height: 19px;
      color: $black3;
    }

    &-icon {
      margin-left: 13px;

      position: relative;

      &-inf {
        width: 224px;
        display: none;
        padding: 10px 15px;

        position: absolute;
        bottom: 36px;
        right: -31px;

        font-size: 16px;
        line-height: 21px;
        color: $black3;
        background: $white;
        border-radius: 15px;
        box-shadow: 3px 4px 14px rgba(67, 26, 26, 0.08);

        &:after {
          content: '';
          width: 21px;
          height: 21px;
          display: block;

          position: absolute;
          right: 30px;
          bottom: -10px;

          background-image: url("../images/svg/ar1.svg");
          background-repeat: no-repeat;
        }
      }
      
      &:hover {
        img ~ .card-product__deliv-icon-inf {
          display: block;
        }
      }
    }
  }

  &__bann {
    padding: 30px 50px 45px;
    margin-bottom: 20px;

    border-radius: 15px;
    border: 1px solid $bord;
    text-align: center;

    &-icon {
      margin-bottom: 27px;
    }

    &-tx {
      margin-bottom: 32px;

      font-weight: 500;
      font-size: 18px;
      line-height: 139%;
      color: $green5;
    }
  }

  &__reviews {
    &-none {
      &-title {
        margin-bottom: 15px;

        font-size: 16px;
        line-height: 19px;
        color: $black3;
      }

      p {
        font-size: 16px;
        line-height: 23px;
        color: $black4;
      }
    }

    &-btn {
      display: block;
      width: 100%;
      padding: 16px;

      background: $gray4;
      cursor: pointer;
      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      color: $black3;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      text-align: center;

      .open & {
        display: none;
      }
    }

    &-info {
      width: 250px;
    }

    &-item {
      display: flex;
      padding: 26px 40px;
      margin-bottom: 13px;

      border-radius: 15px;
      border: 1px solid $bord;
    }

    &-title {
      margin-bottom: 7px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;
    }

    &-data {
      font-size: 14px;
      line-height: 16px;
      color: $gray3;
    }

    &-rating {
      margin-bottom: 10px;

      &-item {
        display: inline-block;
        vertical-align: middle;

        img {
          width: 14px;
        }
      }
    }

    &-description {
      width: calc(100% - 250px);
      padding-left: 25px;

      p {
        font-size: 14px;
        line-height: 161.5%;
        color: $black4;
      }
    }

    &-list {
      &-img {
        width: 79px;
        height: 77px;
        margin-bottom: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        margin-right: 9px;

        border: 1px solid $bord3;
        border-radius: 8px;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;

        span {
          font-weight: 500;
          font-size: 13px;
          line-height: 121.03%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: $green-hov;
        }

        &-all {
          border-color: $green;
          position: relative;
          opacity: 0.9;
          cursor: pointer;

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;

            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            background: rgba(130, 163, 67, 0.2);
          }
        }
      }
    }
  }

  &__top {
    &-mob {
      display: none;
    }
  }

  &__shop {
    display: block;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    align-items: center;

    border: 1px solid $bord;
    border-radius: 15px;

    &:hover {
      .card-product__shop-title {
        color: $green-hov;
      }
    }

    &-img {
      width: 75px;
      height: 75px;
      margin-right: 20px;

      img {
        max-width: 100%;
        max-height: 75px;
      }
    }

    &-tx {
      width: calc(100% - 75px);
    }

    &-tt {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 16px;
      color: $gray3;
    }

    &-title {
      transition: 0.3s;
      margin-bottom: 5px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;
    }
  }
}

.nav {
  &-tabs {
    margin-bottom: 40px;

    border: none;
  }

  &-item {

  }

  &-link {
    padding: 33px 20px;
    margin-right: 10px;

    position: relative;

    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $gray3;
    border: none!important;
    background: transparent!important;
    border-radius: 0!important;

    &:after {
      content: '';
      height: 4px;
      width: 100%;
      display: block;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;

      background: $black3;
      opacity: 0;
      transition: 0.3s;
    }

    &.active {
      &:after {
        opacity: 1;
      }
    }

    &:hover {
      color: $black3;
    }
  }
}

.cart {
  &__inner {

  }

  &-banner {
    margin-bottom: 60px;

    &-tw {
      padding-left: 22px;
    }

    &__inner {
      display: flex;
      align-items: center;
      padding: 0 20px;

      background: $green6;

      .cart-banner-tw & {
        border-radius: 8px;
        overflow: hidden;
      }
    }

    &__img {
      .cart-banner-tw & {
      }
    }

    &__tx {
      width: 100%;

      padding-left: 30px;

      .cart-banner-tw & {
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      p {
        margin-bottom: 0;

        font-size: 15px;
        line-height: 140%;
        color: $black5;

        a {
          font-weight: 500;
          color: $green-hov;

          text-decoration: underline;

          &:hover {
            color: $green;
          }
        }
      }
    }

    &__title {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 10px;
      margin-right: 10px;

      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: $black;
    }

    &__label {
      display: inline-block;
      vertical-align: middle;
      padding: 3px 8px;

      position: relative;
      top: -2px;

      font-size: 12px;
      line-height: 14px;
      color: $white;
      background: $green-hov;
      border-radius: 8px;
    }

    &__btn {
      display: block;

      font-size: 14px;
      line-height: 19px;
      color: $black3;

      &-tw {
        font-weight: 500;
        color: $green-hov;

        text-decoration: underline;

        &:hover {
          color: $green;
        }
      }

      img {
        margin-left: 20px;
      }

      &:hover {
        color: $green-hov;
      }
    }
  }

  &__list {
    h3 {
      margin-top: 40px;

    }

    &-promo {
      height: 40px;
      width: 74%;

      position: relative;

      input {
        width: 100%;
        height: 40px;
        padding: 5px 30px;

        border-radius: 3px;
        border: 1px solid $bord;
        font-size: 14px;
        line-height: 16px;
        color: $black5;
        background: transparent;
      }

      &-btn {
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        right: 0;
        top: 0;

        border-radius: 3px;
        background: $green-hov;
        border: none;
        box-shadow: none;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background: $green;
        }
      }

      &-done {
        padding-left: 20px;
        margin-top: 10px;

        color: $green-hov;
        font-size: 12px;
        line-height: 14px;

        background-image: url("../images/svg/check3.svg");
        background-repeat: no-repeat;
        background-size: 11px 11px;
        background-position: center left;
      }
    }

    &-all {
      display: flex;
      align-items: center;
      margin: 25px 0;
      padding: 14px 29px 45px;

      //background: red;
      border-radius: 8px;

      &-price,
      &-btn {
        max-width: 19.333%;
        flex: 0 0 19.333%;
      }

      &-btn {
        text-align: right;
      }

      &-title {
        margin-bottom: 5px;

        font-size: 14px;
        line-height: 19px;
        color: $black3;
      }

      &-tx {
        max-width: 19.333%;
        flex: 0 0 19.333%;

        &-title {
          margin-bottom: 15px;

          font-weight: 500;
          font-size: 18px;
          line-height: 130%;
          color: $black;
        }

        &-text {
          font-size: 14px;
          line-height: 19px;
          color: $black3;
        }
      }
    }

    &-item {
      padding: 20px 0;
      display: flex;
      align-items: center;

      border-top: 1px solid $bord;

      &:last-of-type {
        border-bottom: 1px solid $bord;
      }

      .order & {
        &:first-of-type {
          border-top: none;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    &-descr {
      max-width: 42%;
      flex: 0 0 42%;

      min-height: 93px;
      padding-top: 15px;
      padding-left: 130px;

      position: relative;

      .cart__list-all & {
        padding-left: 0;
        padding-top: 0;
      }

      .order & {
        max-width: 71%;
        flex: 0 0 71%;
      }
    }

    &-img {
      width: 93px;
      height: 93px;

      position: absolute;
      left: 0;
      top: 0;

      border: 1px solid $bord3;
      border-radius: 8px;
      overflow: hidden;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-title {
      display: block;
      margin-bottom: 15px;

      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: $black;

      &:hover {
        color: $green-hov;
      }
    }

    &-article {
      font-size: 14px;
      line-height: 19px;
      color: $black3;
    }

    &-numb {
      max-width: 14.5%;
      flex: 0 0 14.5%;

      &.last {
        text-align: right;
      }

      &-tx {
        font-size: 14px;
        line-height: 19px;
        color: $black3;
      }
    }

    &-price {
      margin-bottom: 12px;

      &-now {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;

        color: $green-hov;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
      }

      &-old {
        display: inline-block;
        vertical-align: middle;

        font-size: 12px;
        line-height: 14px;
        text-decoration: line-through;
        color: $gray3;
      }
    }

    &-delete {
      max-width: 14.5%;
      flex: 0 0 14.5%;

      text-align: right;

      svg {
        width: 18px;
        height: 20px;

        use {
          color: $gray3;
        }
      }
    }

    &-amount {
      max-width: 14.5%;
      flex: 0 0 14.5%;

      input {
        background: transparent!important;
      }
    }
  }
}

.tx {
  &__inner {
    padding: 95px 100px;
    margin-bottom: 60px;

    display: flex;
    align-items: center;
  }

  &__img {
    display: flex;
    align-items: center;
  }

  &__text {
    padding-left: 20px;

    svg {
      width: 19px;
      height: 16px;

      use {
        color: $green;
      }
    }

    p {
      margin-bottom: 30px;

      font-size: 16px;
      line-height: 175%;
      color: $black4;
    }
  }

  &__title {
    margin-bottom: 20px;

    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: $black;

    &.big {
      font-weight: 500;
      font-size: 100px;
      line-height: 121px;
      color: $black;
      font-family: $font-family2;
    }
  }
}

.order {
  margin-bottom: 70px;

  .polzunok-container-5 {
    margin: 85px 0 28px;

    box-sizing: border-box;
    outline: none;

    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  .ui-slider {
    height: 3px;
    margin-top: 25px;

    position: relative;

    background: $bord;
    border-radius: 10px;

    &-range {
      height: 3px;

      position: absolute;
      z-index: 1;

      border: none;

      background: $green;

      &:before {
        content: '';
        width: 19px;
        height: 19px;
        display: block;

        position: absolute;
        top: -7px;
        right: -7px;

        border-radius: 100px;
        background: $white;
        box-shadow: 0px 1px 6px $bord5;
        cursor: pointer;
      }
      &:after {
        content: '';
        width: 7px;
        height: 7px;
        display: block;

        position: absolute;
        top: -1px;
        right: -1px;
        z-index: 1;

        border-radius: 100px;
        background: $green;
        cursor: pointer;
      }
    }

    &-handle {
      width: 19px;
      height: 19px;
      display: none;

      border-radius: 100px;
      background: $white;
      box-shadow: 0px 1px 6px $bord5;

      &:focus {
        outline: none;
      }
    }
  }

  .polzunok-input-5-left {
    width: 100%;
    display: inline-block;
    height: 58px;
    padding: 26px 10px 10px 18px;

    font-size: 16px;
    line-height: 26px;
    background: $white;
    border: 1px solid $bord4;
    border-bottom: 2px solid $bord4;
    border-radius: 0;
    color: $black2;

    &:focus {

    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__left {
    width: calc(100% - 317px);
    padding-right: 140px;
  }

  &__right {
    width: 317px;
  }

  &__wrap {
    padding: 20px 25px;
    margin-bottom: 35px;

    border: 1px solid $bord;
    border-radius: 15px;
    overflow: hidden;

    &-top {
      margin: -20px -25px 25px;
      padding: 23px 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      background: $bg;
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;
    }

    &-list {
      &-item {
        display: inline-block;
        vertical-align: middle;
        padding-right: 20px;

        position: relative;

        font-size: 14px;
        line-height: 16px;
        color: $black3;

        &.act {
          color: $green-hov;
        }

        &:after {
          content: '';
          width: 3px;
          height: 3px;
          display: block;

          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
          margin: auto;

          border-radius: 100px;
          background: $black3;
        }

        &:last-of-type {
          padding-right: 0;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__delivery {

  }

  &__input {
    &-cell {
      margin-bottom: 30px;

      position: relative;

      input {
        width: 100%;
        height: 58px;
        padding: 12px 18px 0 18px;

        border-radius: 3px 3px 0 0;
        border: 1px solid $bord;
        border-bottom: 2px solid $bord4;

        &.form__input-effect,
        &.form__textarea-effect{
          & ~ label{
            position: absolute;
            left: 18px;
            top: 17px;
            width: 100%;

            transition: 0.3s;
            z-index: 10;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            font-size: 16px;
            line-height: 19px;
            color: $black3;
            cursor: text!important;

            &:hover {
              cursor: text;
            }
          }


          &:focus ~ label,
          &.has-content ~ label {
            bottom: inherit;
            top: 4px;

            transition: 0.3s;

            font-size: 13px;
            line-height: 18px;
            color: $black4;
          }
        }
      }

      &-error {
        input {
          border-bottom-color: $red1;
        }
      }
    }
  }

  &__payment {
    &-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px 25px;

      &.fl {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .order__delivery & {
        margin-left: 0;
        margin-right: 0;
      }

      &-change {
        display: flex;
        align-items: center;

        font-size: 14px;
        line-height: 16px;
        color: $black3;

        &-tx {
          padding-right: 20px;

          font-size: 16px;
          line-height: 19px;
          color: $black5;
        }

        svg {
          width: 16px;
          height: 16px;
          margin-right: 5px;

          use {
            color: $black3;
          }
        }

        &:hover {
          color: $green;

          svg {
            use {
              color: $green;
            }
          }
        }
      }

      &-item {
        position: relative;

        .order__delivery & {
          margin-bottom: 7px;
        }

        &-in {
          width: 33.333%;
          max-width: 33.333%;
          padding: 0 15px;

          .order__delivery & {
            width: 100%;
            max-width: 100%;
            padding: 0;
            margin-bottom: 16px;
          }
        }

        input {
          width: 100%;

          &[type=checkbox],
          &[type=radio] {
            display: none;

            & + label {
              width: 100%;
              padding: 13px 10px 13px 47px;
              margin-bottom: 0;


              font-size: 16px;
              line-height: 19px;
              color: $black5;
              border-radius: 3px;
              border: 1px solid $bord;
              cursor: pointer;

              &:before {
                content: "";
                width: 19px;
                height: 19px;
                display: inline-block;
                margin: -5px auto 0 auto;

                position: absolute;
                top: 20px;
                bottom: 0;
                left: 20px;

                background-color: $white;
                box-shadow: 0px 1px 6px $bord3;

                text-align: center;
                vertical-align: middle;
                transition: color ease .3s;
                border-radius: 100px;
              }

              &:after {
                content: '';
                width: 7px;
                height: 7px;
                display: block;
                opacity: 0;

                position: absolute;
                top: 21px;
                left: 26px;
                z-index: 1;

                border-radius: 100px;
                background: $green;
              }
            }
          }

          &:checked  {
            & + label {
              border-color: $green;

              &:after {
                opacity: 1;
              }
            }
          }
        }
      }

      &-tx {
        margin-bottom: 15px;

        font-size: 14px;
        line-height: 19px;
        color: $black3;
      }
    }
  }

  &__point {
    &-check {
      position: relative;
      margin-bottom: 10px;

      input {
        &[type=checkbox] {
          display: none;

          & + label {
            width: 100%;
            padding-left: 24px;

            font-size: 14px;
            line-height: 16px;
            color: $black5;

            cursor: pointer;


            &:before {
              content: "";
              width: 16px;
              height: 16px;
              display: inline-block;
              margin: -5px 5px 0 0;

              position: absolute;
              top: 10px;
              left: 0;

              background-color: $bord;
              border-radius: 3px;

              transition: color ease .3s;
            }
          }

          &:checked  {
            & + label {
              &:before {
                background-repeat: no-repeat;
                background-image: url("../images/svg/check2.svg");
                background-size: 10px 8px;
                background-position: center;
                background-color: $green;
              }
            }
          }
        }
      }
    }
  }

  &__range {
    width: 280px;

    &-item {
      position: relative;

      label {
        position: absolute;
        top: 5px;
        left: 18px;

        font-size: 13px;
        line-height: 18px;
        color: $black4;
      }
    }
  }

  &__list {
    padding: 25px;

    border: 1px solid $bord;
    border-radius: 15px;

    &-title {
      margin-bottom: 22px;

      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      color: $black;
    }

    &-all {
      &.prom {
        margin-bottom: 0;
      }

      & + .order__list-all {
        margin-top: 4px;
      }

      &-item {
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: $black;

        .prom & {
          font-size: 14px;
          line-height: 16px;
          color: $green-hov;
        }
      }
    }

    &-descr {
      margin-top: 10px;

      font-size: 12px;
      line-height: 15px;
      color: $gray3;
    }

    &-table {
      margin: 0 -5px 15px;

      &-tw {
        padding-bottom: 11px;

        border-bottom: 1px solid $bord;

        .order__list-table-row {
          &:after {
            display: none;
          }
        }
      }

      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        position: relative;

        &:after {
          content: '';
          width: 100%;
          height: 1px;
          display: block;

          position: absolute;
          bottom: 3px;
          left: 0;
          z-index: 1;

          border-bottom: 1px dashed $bord;
        }
      }

      &-item {
        padding: 7px 5px;

        position: relative;
        z-index: 3;

        font-size: 14px;
        line-height: 16px;
        color: $black;
        background: $white;

        a {
          font-weight: 500;
          font-size: 15px;
          line-height: 140%;
          color: $green-hov;
          text-decoration: underline;

          &:hover {
            color: $green;
          }
        }
      }
    }

    &-promo {
      margin-bottom: 25px;

      .cart__list-promo {
        width: 100%;
      }
    }

    &-all {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 25px 0 20px;
    }


  }
}

.form__input-error {
  display: block;
  margin-top: 5px;
  padding-left: 18px;

  font-size: 13px;
  line-height: 15px;
  color: $red1;
}

.check-in {
  margin: 70px 0 120px;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__img {
    width: calc(100% - 465px);
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  &__tx {
    width: 465px;

    h1 {
      margin-bottom: 50px;
    }

    p {
      margin-bottom: 15px;

      font-size: 14px;
      line-height: 19px;
      color: $black3;

      a {
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        text-decoration: underline;
        color: $green-hov;

        &:hover {
          color: $green;
        }
      }
    }
  }

  &__input {
    .order__input-cell {
      margin-bottom: 20px;
    }
  }

  &__soc {
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      margin: 42px 0 27px;

      background: $bord4;
    }

    &:after {
      content: 'или';
      display: block;
      width: 45px;
      padding: 5px 10px;

      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      margin: auto;

      background: $white;
      font-size: 14px;
      line-height: 19px;
      color: $gray3;
    }

    &-item {
      display: block;
      padding: 14px 30px;
      margin-bottom: 10px;

      position: relative;

      border-radius: 3px;
      border: 1px solid $bord;
      font-size: 14px;
      line-height: 19px;
      color: $black3;
      text-align: center;

      img {
        height: 22px;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
        margin: auto;
      }

      &:hover {
        color: $green-hov;
        border-color: $green-hov;
      }
    }
  }
}

.lk {
  margin-bottom: 100px;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__left {
    width: calc(100% - 317px);
    padding-right: 133px;
  }

  &__group {
    .col-lg-4 {
      margin-bottom: 20px;
    }

    &-item {
      padding: 30px;
      display: block;
      margin-bottom: 20px;

      position: relative;

      background: $white;
      box-shadow: 1px 4px 13px rgba(173, 168, 154, 0.12);
      border-radius: 5px;
      transition: 0.3s;
      overflow: hidden;

      a {
        width: 100%;
        height: 100%;
        display: block;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }

      &-img {
        margin-bottom: 35px;
      }

      &-tx {
        font-size: 16px;
        line-height: 21px;
        color: $black3;
      }

      &-title {
        margin-bottom: 15px;

        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: $black;
      }

      &-label {
        display: inline-block;
        padding: 3px 10px;

        position: absolute;
        right: 10px;
        top: 11px;
        z-index: 1;

        font-size: 13px;
        line-height: 16px;
        color: $white;
        background: $green;
        border-radius: 10px;
      }

      &:hover {
        box-shadow: 1px 4px 13px rgba(173, 168, 154, 0.32);

        .lk__group-item-title {
          color: $green-hov;
        }
      }
    }
  }

  &__menu {
    width: 317px;

    &-inner {
      padding: 0 27px;

      border: 1px solid $bord;
      border-radius: 15px;
      background: $white;
    }

    ul {
      margin-bottom: 0;

      li {
        border-bottom: 1px solid $bord;

        a {
          display: flex;
          align-items: center;
          padding: 19px 0;

          font-weight: 500;
          font-size: 13px;
          line-height: 121.03%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: $black3;

          svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;

            use {
              color: $black3;
            }
          }

          &:hover {
            color: $green-hov;

            svg {
              use {
                color: $green-hov;
              }
            }
          }
        }

        &.active {
          a {
            color: $green-hov;

            svg {
              use {
                color: $green-hov;
              }
            }
          }
        }


        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  &__setting {
    .button-group {
      margin-top: 30px;
    }

    p {
      font-size: 16px;
      line-height: 19px;
      color: $black5;
    }

    &-form {
      width: 400px;
      margin-top: 45px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black5;

      &-title {
        margin-bottom: 25px;
      }

      &-message {
        width: 423px;

        padding: 13px 25px;

        position: absolute;
        right: -450px;
        top: 0;

        border-radius: 3px;
        background: $green6;
        font-size: 13px;
        line-height: 137.9%;
        color: $black5;
        background: url('../images/svg/bg.svg');
        background-size: cover;
        background-position: center left;
      }

      &-btn {
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 8px;
        z-index: 11;
        border-radius: 3px;
        background: $green-hov;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: pointer;
        -webkit-transition: 0.3s;
        transition: 0.3s;

        &:hover {
          background: $green;
        }
      }

      &-edit {
        width: 18px;
        height: 26px;
        cursor: pointer;

        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        margin: auto;

        img {
        }

        &:hover {
          opacity: 0.7;
        }
      }

      &-cell {
        margin-bottom: 25px;

        position: relative;

        input {
          width: 100%;
          height: 58px;
          padding: 12px 18px 0 18px;

          border-radius: 3px 3px 0 0;
          border: 1px solid $bord;
          border-bottom: 2px solid $bord4;

          &.form__input-effect,
          &.form__textarea-effect{
            & ~ label{
              position: absolute;
              left: 18px;
              top: 17px;
              width: 100%;

              transition: 0.3s;
              z-index: 10;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              font-size: 16px;
              line-height: 19px;
              color: $black3;
              cursor: text!important;
              font-weight: 400;

              &:hover {
                cursor: text;
              }
            }


            &:focus ~ label,
            &.has-content ~ label {
              bottom: inherit;
              top: 4px;

              transition: 0.3s;

              font-size: 13px;
              line-height: 18px;
              color: $black4;
            }
          }
        }
      }
    }
  }
}

.orders {
  margin-bottom: 80px;

  &__prod {
    display: none;

    .open & {
      display: block;
    }

    .catalog__item-list {
      padding: 20px 30px;
      border: none;
      border-bottom: 1px solid $bord5;

      &:last-of-type {
        border: none;
      }

      &:hover {
        border-color: $bord5;
      }
    }
  }

  &__form {
    width: 65%;

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      &-bt {
        align-items: flex-start;
      }
    }

    &-title {
      width: 200px;
      padding-right: 20px;

      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: $black2;
    }

    &-inp {
      width: calc(100% - 200px);

      input {
        width: 100%;
        height: 55px;
        padding: 5px 20px;

        font-size: 16px;
        margin-bottom: 0;
        border: 1px solid $bord4;
      }

      p {
        margin-top: 15px;

        font-size: 13px;
        line-height: 140%;
        color: $gray;
      }
    }

    &-btns {
      .button {
        margin-bottom: 10px;
        min-width: 230px;
        display: inline-block;
        margin-right: 20px;

        font-weight: 700;
        font-size: 12px;
        line-height: 125%;

        text-align: center;
      }
    }
  }

  &__inner {
    display: flex;
  }

  &__left {
    width: calc(100% - 240px);
    padding-right: 50px;
  }

  &__item {
    padding: 20px 25px;
    margin-bottom: 20px;

    border: 1px solid $bord6;
    border-radius: 12px;

    & + .button-center {
      margin-top: 30px;
    }

    &-product {
      padding: 30px 0 0;

      &-btn {
        display: block;
        padding: 17px 10px;

        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        color: $black3;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        background: $gray4;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: $green;
          color: $white;
        }

        .open & {
          display: none;
        }
      }

      &-title {
        font-size: 16px;
        line-height: 19px;
        color: $black;
      }

      &-row {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;


        border-bottom: 1px solid $bord7;

        &:last-of-type {
          border-bottom: none;
        }
      }

      &-left {
        padding-top: 7px;
        min-height: 64px;
        padding-left: 87px;
        position: relative;
      }

      &-img {
        width: 64px;
        height: 64px;
        border: 1px solid $bord3;
        border-radius: 8px;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      &-name {
        display: block;
        margin-bottom: 10px;

        color: $black;
        font-size: 14px;
        line-height: 16px;

        &:hover {
          color: $green-hov;
        }
      }

      &-price {
        font-weight: 500;
        font-size: 16px;
        line-height: 121.03%;
        color: $green-hov;
      }

      &-rating {
        &-title {
          font-size: 13px;
          line-height: 18px;
          color: $black5;
        }

        &-im {
          &-item {
            display: inline-block;
          }
        }
      }
    }

    &-bottom {
      display: inline-block;
      padding-left: 28px;

      position: relative;

      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: $black2;

      img {
        position: absolute;
        left: 0;
      }

      &:hover {
        color: $black2;
        opacity: 0.8;
      }
    }

    &-descr {
      padding: 15px 22px 10px;

      background: $bg;

      .map {
        height: 195px;
      }

      p {
        margin-bottom: 2px;

        font-size: 13px;
        line-height: 18px;
        color: $black3;
      }

      &-rating {
        .orders__item-descr-title {
          display: inline-block;
          margin-right: 25px;
        }

        &-im {
          display: inline-block;

          &-item {
            display: inline-block;
          }
        }
      }

      &-link {
        display: inline-block;

        font-weight: 700;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: $black2;

      }

      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;

        color: $gray11;
        font-size: 14px;
        line-height: 16px;

        a {
          font-weight: 500;
          font-size: 15px;
          line-height: 140%;
          color: $green-hov;
          text-decoration: underline;

          &:hover {
            color: $green;
          }
        }
      }

      &-count {
        //margin-bottom: 10px;

        &-tit {
          display: inline-block;
          margin-right: 5px;

          color: $black3;
          font-size: 16px;
          line-height: 19px;
        }

        &-st {
          display: inline-block;
          padding: 2px 5px;

          font-size: 13px;
          line-height: 16px;

          color: $white;
          background: $orange;
          border-radius: 10px;

          &.tw {
            background: $green;
          }
          &.th {
            background: $green2;
          }
          &.fr {
            background: $orange;
          }
        }
      }

      &-recipient {
        &-tx {
          display: inline-block;

          color: $black2;
          font-size: 16px;
          line-height: 140%;
        }
      }
    }

    &-al {
      padding: 20px 25px 20px 65px;

      cursor: pointer;

      .orders__title-main {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    position: relative;

    &-arrow {
      width: 18px;
      height: 18px;

      position: absolute;
      top: 0;
      bottom: 0;
      left: -40px;
      margin: auto;

      svg {
        width: 18px;
        height: 18px;
        transform: rotate(-90deg);

        use {
          color: $gray;
        }
      }

      .open & {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .orders__item-al & {
      margin-bottom: 0;
    }

    &-big {
      margin-top: 40px;
      align-items: flex-end;
    }
  }

  &__title {
    p {
      margin-bottom: 5px;
      font-size: 13px;
      line-height: 140%;
      color: $gray;
    }

    .orders__status {
      margin-bottom: 10px;
      text-transform: inherit;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;

      &-success {
        color: $black2;

        &:before {
          background-color: $black2;
         // background-image: url("../images/svg/icon-status1.svg");
          background-size: 8px 6px;
        }
      }
    }

    &-main {
      margin-bottom: 5px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;

      .orders__item-al & {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
      }
    }

    &-min {
      font-size: 14px;
      line-height: 16px;
      color: $black3;

      .orders__top-big & {
        font-size: 18px;
        line-height: 120%;
        color: $black2;
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    text-align: right;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $black3;

    &:before {
      content: '';
      width: 6px;
      height: 6px;
      display: inline-block;
      margin-right: 7px;

      border-radius: 100px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-success {

      &:before {
        background-color: $green;
        //background-image: url("../images/svg/icon-status1.svg");
        background-size: 8px 6px;
      }
    }
    &-cancel {
      color: $red1;

      &:before {
        //background-image: url("../images/svg/icon-status3.svg");
        background-size: 16px 16px;
      }
    }
  }

  &__right {
    width: 240px;
  }

  &__pages {
    &-item {
      display: flex;
      align-items: center;
      min-height: 39px;
      padding: 12px 10px 9px 52px;
      margin-bottom: 5px;

      position: relative;

      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: $black2;
      border-radius: 4px;

      svg {
        width: 24px;
        height: 24px;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 20px;
        margin: auto;

        use {
          color: $black2;
        }
      }

      &.active {
        color: $red1;
        background: $black2;
        border-radius: 7px;

        svg {
          use {
            color: $red1;
          }
        }
      }

      &:hover {
        color: $red1;

        svg {
          use {
            color: $red1;
          }
        }
      }
    }
  }
}

.policy {
  margin-bottom: 100px;

  &__inner {
    h3 {
      margin-bottom: 16px;

      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      color: $black5;
    }

    p {
      margin-bottom: 45px;

      font-size: 16px;
      line-height: 154%;
      color: $black4;
    }

    ul {
      margin-bottom: 40px;

      li {
        margin-bottom: 2px;
        padding-left: 25px;

        position: relative;

        font-size: 16px;
        line-height: 154%;
        color: $black4;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          display: block;

          position: absolute;
          top: 9px;
          left: 0;

          border-radius: 10px;
          background: $green;
        }
      }
    }
  }
}

.modal {
  .close {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: -75px;
    top: 14px;

    border: 1px solid $bord;
    border-radius: 100px;
  }

  &-content {
    padding: 50px 70px;

    border: none;
    border-radius: 0;
    background: $white;
  }

  &-dialog {
    max-width: 600px;

    border: none;
    border-radius: 0;
  }

  &-reviews {
    p {
      font-size: 16px;
      line-height: 170%;
      color: $green5;
    }

    &__title {
      margin-bottom: 25px;

      font-weight: 500;
      font-size: 26px;
      line-height: 130%;
      color: $black;
    }

    &__rating {
      flex-wrap: wrap;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      &-numb {
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;

        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        color: $black5;
      }

      &-all {
        margin-right: 20px;
        display: inline-block;
        vertical-align: middle;

        font-size: 14px;
        line-height: 16px;
        color: $green-hov;
      }

      &-title {
        margin-right: 20px;
        display: inline-block;
        vertical-align: middle;

        font-size: 14px;
        line-height: 16px;
        color: $black5;
      }

      &-list {
        margin-right: 20px;
        display: inline-block;
        vertical-align: middle;
      }

      &-item {
        display: inline-block;
        vertical-align: middle;
      }
    }

    &__inp {
      margin-bottom: 20px;

      position: relative;

      textarea {
        width: 100%;
        height: 175px;
        padding: 25px 18px 0 18px;

        border-radius: 3px 3px 0 0;
        border: 1px solid $bord;
        border-bottom: 2px solid $bord4;

        &.form__input-effect,
        &.form__textarea-effect{
          & ~ label{
            position: absolute;
            left: 18px;
            top: 17px;
            width: 100%;

            transition: 0.3s;
            z-index: 10;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            font-size: 16px;
            line-height: 19px;
            color: $black3;
            cursor: text!important;

            &:hover {
              cursor: text;
            }
          }


          &:focus ~ label,
          &.has-content ~ label {
            bottom: inherit;
            top: 4px;

            transition: 0.3s;

            font-size: 13px;
            line-height: 18px;
            color: $black4;
          }
        }
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
    }

    &__upload {
      display: flex;
      align-items: center;

      &-list {

      }

      &-item {
        width: 83px;
        height: 83px;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        position: relative;

        border-radius: 3px;
        overflow: hidden;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid $bord;

        &-none {
          border: none;
        }

        .close-i {
          position: absolute;
          right: 5px;
          top: 0;
          z-index: 3;

          cursor: pointer;

          img {
            width: 9px;
          }
        }
      }

      &-tx {
        padding-left: 30px;

        &-title {
          margin-bottom: 10px;

          font-size: 14px;
          line-height: 140%;
          color: $black5;
        }

        &-text {
          font-size: 12px;
          line-height: 14px;
          color: $gray3;
        }
      }
    }
  }
}


.point {
  &__inner {
    margin-bottom: 80px;
  }

  &__table {
    width: 81%;
    margin-bottom: 30px;

    &-row {
      display: flex;
      width: 100%;

      border-bottom: 1px solid $bord;
    }

    &-cell {
      padding-top: 23px;
      padding-bottom: 23px;

      padding-right: 10px;

      &-title {
        padding-top: 15px;
        padding-bottom: 15px;

        font-size: 14px;
        line-height: 16px;
        color: $gray11;
      }

      &:first-of-type,
      &:last-of-type {
        width: 200px;
      }

      &:last-of-type {
        text-align: right;
        padding-right: 0;
      }

      &:nth-of-type(2) {
        width: calc(100% - 400px);
      }
    }
  }

  &__data {
    font-size: 14px;
    line-height: 19px;
    color: $black3;
  }

  &__title {
    font-size: 16px;
    line-height: 19px;
    color: $black;
  }

  &__price {
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: $green-hov;

    &:after {
      display: inline-block;
      margin-left: 3px;
      font-family: "PT Sans", sans-serif;
      content: "\20cf";
      font-weight: 700;
    }
  }
}

.brands {
  &__inner {
    margin-bottom: 80px;
  }

  &__list {
    margin-bottom: 5px;

    &-item {
      height: 33px;
      padding: 5px 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;

      font-size: 16px;
      line-height: 19px;
      color: $black3;

      &.not {
        color: $bord8!important;

        &:hover {

        }
      }

      &.active {
        font-size: 16px;
        line-height: 19px;
        color: $green8;
        background: $green6;
        border-radius: 10px;

        &:hover {
          color: $white!important;
          background: $green-hov;
        }
      }



      &:hover {
        color: $green;
      }
    }
  }

  &__all {
    margin-top: 43px;

    &-title {
      margin-bottom: 26px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;
    }

    &-item {
      margin-bottom: 18px;
    }

    &-inner {
      display: flex;
      flex-wrap: wrap;

      &-tw {
        width: 50%;
      }
    }

    &-item {
      flex: 0 0 25%;
      max-width: 25%;
      display: inline-block;

      color: $black3;
      font-size: 16px;
      line-height: 19px;

      span {
        padding-right: 10px;
      }

      &-link {
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 140%;
          color: $green-hov;
          text-decoration: underline;

          &:hover {
            color: $green;
          }
        }
      }

      span {
        transition: 0.3s;
      }

      &:hover {
        span {
          color: $green;
        }
      }

      .brands__all-inner-tw & {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

.about {
  margin-top: -30px;
  padding-top: 30px;

  background-color: $bg3;

  &-text {
    padding: 77px 0;
    margin-bottom: -250px;

    position: relative;
    z-index: 3;

    .container {
      position: relative;
      z-index: 4;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;

      background-image: url("../images/bg-b1.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    h1 {
      margin-bottom: 28px;

      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
    }

    &__img {
      img {
        max-width: 100%;
      }
    }

    &__tx {
      p {
        margin-bottom: 45px;

        font-size: 24px;
        line-height: 157.5%;
        color: $black3;

        span {
          color: #7ABC2F;
          font-style: italic;

          position: relative;


          &:after {
            content: '';
            width: 100%;
            height: 20px;
            display: block;

            position: absolute;
            bottom: -15px;
            left: 0;
            z-index: 2;

            background-image: url("../images/svg/line.svg");
            background-repeat: no-repeat;
            background-position: 10px center;
          }
        }
      }
    }
  }

  &-all {
    padding: 300px 0 150px;

    background-color: $green9;
    background-image: url("../images/svg/line2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &__item {
      p {
        font-size: 18px;
        line-height: 154%;
        color: $black;
      }

      &-title {
        margin-left: 20px;

        font-weight: 500;
        font-size: 32px;
        line-height: 37px;
        color: $black;

        &-min {
          margin-bottom: 10px;

          font-size: 18px;
          line-height: 154%;
          color: $white;

          &:before {
            content: '';
            width: 10px;
            height: 12px;
            display: inline-block;
            margin-right: 20px;

            background-image: url("../images/svg/about-icon-l.svg");
            background-repeat: no-repeat;
            background-size: 10px 12px;
          }
        }
      }

      &-top {
        margin-bottom: 70px;

        min-height: 77px;
        display: flex;
        align-items: center;
      }
    }
  }

  &-work {
    padding: 115px 0;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    h2 {
      margin-bottom: 30px;

      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      text-align: center;
    }

    .col-md-6 {
      margin-bottom: 20px;
    }

    &__inner {
      position: relative;

      &:before {
        content: '';
        width: 257px;
        height: 146px;
        display: block;

        position: absolute;
        top: 0;
        right: -30px;
        z-index: 2;

        background-image: url("../images/about-work-img1.png");
        background-repeat: no-repeat;
      }
      &:after {
        content: '';
        width: 221px;
        height: 89px;
        display: block;

        position: absolute;
        bottom: -39px;
        left: 182px;
        z-index: 2;

        background-image: url("../images/about-work-img2.png");
        background-repeat: no-repeat;
      }
    }

    &__item {
      padding: 30px;
      height: 100%;
      min-height: 193px;

      border-radius: 10px;
      background: $white;
      border: 1px solid $black3;

      &-title {
        margin-bottom: 20px;

        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: $black;
      }

      &-tx {
        font-size: 16px;
        line-height: 154%;
        color: $black4;
      }
    }
  }
}

.align-items-flex-end {
 align-items: flex-end;
}

.pay {
  &-list {
    margin-bottom: 90px;

    &__inner {

    }

    &__row {
      padding: 20px 0 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-top: 1px solid $bord;

      &:last-of-type {
        border-bottom: 1px solid $bord;
      }
    }

    &__icon {
      width: 200px;
    }

    &__title {
      width: 445px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;
    }

    &__tx {
      width: calc(100% - 645px);

      font-size: 16px;
      line-height: 154%;
      color: $black4;
    }
  }

  &-descrip {
    margin-bottom: 120px;

    font-size: 18px;
    line-height: 154%;
    color: $black3;

    p {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 154%;
      color: $black3;
    }

    h2 + p {
      margin-top: 20px;
    }

    .col-xl-6 {
      margin-bottom: 30px;
    }

    &__img {
      img {
        max-width: 100%;

      }
    }

    &__item {
      padding: 30px;
      height: 100%;

      border: 1px solid $black3;
      border-radius: 10px;
      background: $white;

      &-all {
        font-size: 16px;
        line-height: 154%;
        color: $black4;

        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
          width: 23px;
          height: 23px;

          background-image: url("../images/svg/inf2.svg");
          background-repeat: no-repeat;
        }
      }

      &-title {
        margin-bottom: 20px;

        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: $black;
      }

      &-tx {
        font-size: 16px;
        line-height: 154%;
        color: $black4;
      }
    }
  }
}

.alphabet {
  margin-bottom: 85px;

  .information__alphabet & {
    margin-top: 50px;
  }

  &__list {
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__item {
    display: inline-block;
    vertical-align: middle;
    height: 33px;
    padding: 6px 10px;

    color: $black3;
    font-size: 16px;
    line-height: 19px;
    border-radius: 10px;
    text-transform: uppercase;

    &.not {
      color: $bord8;
    }

    &.active {
      background: $green6;
      color: $green8;
      text-transform: inherit;


      &:hover {
        background: $green6;
        color: $green8;
      }
    }

    &:hover {
      color: $green8;
    }
  }

  &-list {
    margin-top: 40px;

    &-letters {
      margin-bottom: 22px;

      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: $black;
    }

    &-lt {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;

      position: relative;

      &.tw {
        width: 50%;
      }
    }

    &-item {
      padding: 0 5px;
      margin-bottom: 13px;
      display: block;
      flex: 0 0 25%;
      max-width: 25%;

      font-size: 18px;
      line-height: 24px;
      color: $black;

      &.link {
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        color: $green-hov;
        text-decoration: underline;

        &:hover {
          color: $green;
        }
      }

      .tw & {
        flex: 0 0 50%;
        max-width: 50%;
      }

      &:hover {
        color: $blue1;
      }
    }
  }
}


@media screen and (max-width: 1850px) {
  .header__bottom-info-item {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1700px) {
  .catalog-min {
    .col-lg-2-1 {
      flex: 0 0 25%;
      max-width: 25%;

      &:last-of-type {
        display: none;
      }
    }
  }

  .catalog-subscription {
    .col-lg-2-1 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

@media screen and (max-width: 1600px) {
  .container {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 1500px) {
  .header {
    &__top-menu ul li {
      margin-left: 15px;
    }

    &__search {
      width: 40%;
    }

    &__city {
      margin-right: 20px;
    }
  }

  .menu {
    width: 260px;
    padding: 12px 20px;

    &__submenu {
      width: calc(100% - 240px);
      left: 240px;
    }
  }

  .wrapper {
    padding-left: 260px;
  }

  .bottom-block__info {
    padding: 50px 0 30px;
  }

  .card-product__slider-for {
   // width: 380px;
  }

  .lk {
    &__left {
      width: calc(100% - 250px);
      padding-right: 30px;
    }

    &__menu {
      width: 250px;
    }
  }

  .card-product__all {
    width: 160px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 1400px) {
  .card-product__slider-label {
    display: inline-block;
  }

  .about {
    &-text {
      padding: 77px 0 200px;
    }
  }


  .pay-list {
    &__title {
      width: 300px;
    }

    &__icon {
      width: 100px;
    }

    &__tx {
      width: calc(100% - 400px);
    }
  }


  .catalog-min {
    .col-lg-2-1 {
      flex: 0 0 33.333%;
      max-width: 33.333%;

      &:nth-of-type(4) {
        display: none;
      }
    }
  }

  .catalog-subscription {
    .col-lg-2-1 {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
   }


  .delivery__order {
    padding: 40px 45px;
  }

  .card-product {
    &__inner {
      flex-wrap: wrap;
    }

    &__slider {
      width: calc(100% - 520px);
      margin-right: 0;

      &-for {
        //width: 380px;
      }
    }

    &__all {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      margin-top: 20px;

      &-reviews,
      &-logo {
        margin-right: 20px;
        height: 110px;
        padding: 25px;
        display: inline-block;
        vertical-align: top;
      }
    }

    &__description {
      width: 500px;
    }
  }

  .order {
    &__left {
      padding-right: 50px;
    }

    &__payment-list {
      margin: 0 -5px;

      &-item {
        label {
          padding: 13px 6px 13px 44px!important;
        }

        &-in {
          padding: 0 5px;
          margin-bottom: 10px;
        }
      }
    }

    &__wrap {
      .cart {
        &__list {
          &-item {
            padding: 20px 0 40px;
            position: relative;
            justify-content: space-between;
          }

          &-numb {
            padding-left: 130px;
            max-width: 100%;
            flex: 0 0 100%;
            position: absolute;
            bottom: 20px;

            &.last {
              padding-left: 0;
              max-width: 29%;
              flex: 0 0 29%;

              position: relative;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .bottom-block {
    &__inner {
      display: block;
    }

    &__info {
      width: 100%;
    }
  }

  .footer {
    &__menu  {
      li {
        margin-right: 15px;
      }
    }

    &__tel,
    &__mail {
      display: flex;
      justify-content: flex-end;
      margin-left: 15px;
      margin-bottom: 7px;
    }

    &__top {
      &-info {
        display: block;

        text-align: right;
      }
    }
  }

  .card-product {
    &__tabs {
      &-holder {
        //display: block;
      }

      &-table {
        width: 100%;
      }

      &-inner {
        padding-right: 50px;
      }
    }

    &__reviews {
      &-info {
        width: 160px;
      }

      &-description {
        width: calc(100% - 160px);
        padding-left: 10px;
      }
    }
  }

  .tx {
    &__inner {
      padding: 95px 30px;
    }
  }

  .actions-item {
    &__left {
      width: 400px;
    }

    &__right {
      width: calc(100% - 400px);
    }
  }
}

@media screen and (max-width: 1250px) {
  .header__top-inner {
    display: block;
  }
}

@media screen and (max-width: 1200px) {
  .about-work__inner:before {
    right: 0;
    top: -25px;
  }

  .about-all__item-title {
    font-size: 26px;
    line-height: 34px;
  }

  .container {
    padding-right: 20px;
    padding-left: 20px;
  }

  .banner {
    &__al {
      margin: 0 -15px;
      display: flex;
      align-items: center;
    }

    &__min {
      &-in {
        max-width: 50%;
        flex: 0 0 50%;
        padding: 0 15px;
      }
    }

    &__big {
      margin-bottom: 20px;
    }
  }

  .subscription-list__item {
    margin-bottom: 20px;
  }


  .card-product {
    &__slider {
      width: 410px;

      &-for {
        width: 300px;
      }
    }

    &__description {
      width: calc(100% - 410px);
      padding-left: 20px;
    }

    &__other {
      width: 100%;
    }

    &__reviews {
      display: block;

      &-item {
        display: block;
      }

      &-info {
        width: 100%;
        margin-bottom: 20px;
      }

      &-description {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  .cart {
    &__list {
      &-numb {
        max-width: 21.75%;
        flex: 0 0 21.75%;

        &-tw {
          display: none;
        }
      }

      &-amount {
        padding: 0 5px;
        max-width: 21.75%;
        flex: 0 0 21.75%;
      }
    }
  }

  .order {
    &__inner {
      display: block;
    }

    &__left,
    &__right {
      padding-right: 0;
      width: 100%;
    }
  }

  .lk__setting-form-message {
    width: 100%;
    margin-top: 10px;

    position: relative;
    left: 0;
    right: 0;

    background-image: none;
    background-color: $green6;
    border-radius: 3px;
  }

  .point {
    &__inner {

    }

    &__table {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1100px) {
  .header__bottom-info-item {

  }

  .catalog-min {
    .col-lg-2-1 {
      flex: 0 0 50%;
      max-width: 50%;

      &:nth-of-type(3) {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .card-product__reviews-none p br {
    display: none;
  }

  .card-product__slider .card-product__slider-label {
    left: 10px;
  }

  .about {
    &-text {
      padding: 20px 0 200px;

      &__img {
        margin-top: 30px;

        text-align: center;
      }
    }

    &-all {
      padding: 150px 0 50px;

      &__item {
        margin-bottom: 40px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-top {
          margin-bottom: 30px;
        }
      }
    }
  }

  .pay {
    &-descrip {
      &__img {
        text-align: center;
        margin-top: 30px;
      }
    }
  }

  .header {
    overflow: hidden;
    background: $white;

    &__top {
      display: none;
    }

    &__toggler {
      width: 28px;
      height: 28px;
      display: block;

      position: absolute;
      top: -1px;
      left: 0;
      z-index: 5;

      border-radius: 100px;
      background-color: $orange5;
      background-image: url("../images/svg/toggler.svg");
      background-repeat: no-repeat;
      background-size: 11px 11px;
      background-position: center;
      cursor: pointer;

      .open & {
        background-image: url("../images/svg/close2.svg");
        background-size: 15px 15px;
      }
    }

    &__search {
      width: 100%;
      margin: 12px 0 0;

      input {
        height: 43px;

        font-size: 15px;
        line-height: 121.03%;
      }
    }

    &__logo {
      &-mob {
        display: block;

        img {
          display: block;
          margin: 0 auto;
        }
      }
    }

    &__bottom {
      //height: 53px;
      padding: 14px 0;
      background: $white;

      &-inner {
        display: block;

        position: relative;
      }

      &-info {
        &-notif {
          left: 22px;
          top: -3px;
        }

        &-item {
          width: 29px;
          height: 29px;
          display: none;

          color: transparent!important;

          img {
            display: none;
          }

          &-cart,
          &-enter {
            display: block;

            position: absolute;
            top: 0;
            z-index: 5;
          }

          &-cart {
            right: 10px;

            background-image: url("../images/svg/icon-menu2.svg");
            background-repeat: no-repeat;
            background-size: 29px 29px;
          }

          &-enter {
            right: 55px;

            background-image: url("../images/svg/icon-menu.svg");
            background-repeat: no-repeat;
            background-size: 29px 29px;
          }
        }
      }
    }

    &__menu {
      &-mob {
        display: none;
        width: 100%;
        height: calc(100vh - 53px);
        min-height: 100%;
        padding-bottom: 100px;

        position: fixed;
        top: 53px;
        left: 0;
        z-index: 110;

        background: $white;
        border-top: 1px solid $bord;
        overflow: auto;

        .open & {
          display: block;
        }
      }

      &-icon {
        display: flex;
        border-bottom: 1px solid $bord;

        &-item {
          flex: 0 0 50%;
          max-width: 50%;
          padding: 20px 10px 20px 20px;

          position: relative;

          &:first-of-type {
            border-right: 1px solid $bord;
          }
        }

        &-notif {
          width: 17px;
          height: 17px;
          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: 20px;
          left: 40px;

          border-radius: 100px;
          background: $green-hov;
          font-weight: 700;
          font-size: 10px;
          line-height: 121.03%;
          text-transform: uppercase;
          color: $white;
        }

        &-tit {
          margin-left: 15px;

          font-weight: 500;
          font-size: 13px;
          line-height: 121.03%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: $black3;
        }
      }

      &-btn {
        &-catalog {
          display: block;
          padding: 22px 20px;

          font-weight: 500;
          font-size: 13px;
          line-height: 121.03%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: $black3;

          background-image: url("../images/svg/arrow2.svg");
          background-repeat: no-repeat;
          background-position: 98% center;
          background-size: 9px 12px;
          border-bottom: 1px solid $bord;
        }
      }

      &-main {
        padding: 20px;

        border-bottom: 1px solid $bord;

        li {
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }

          a {
            font-size: 16px;
            line-height: 19px;
            color: $black3;
          }
        }
      }

      &-info {
        padding: 20px;
      }
    }

    &__city {
      margin-bottom: 20px;

      color: $black3;
      margin-right: 0;
    }

    &__tel {
      color: $black3;

      a {
        display: block;
        margin-left: 0;
        margin-top: 13px;

        font-weight: 500;
        font-size: 18px;
        line-height: 121.03%;
      }
    }
  }

  .menu {
    display: none;
    padding: 0 0 100px;

    width: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 112;

    overflow: auto;
    background: $white;

    &.open {
      display: block;
    }

    .logo {
      display: none;
    }

    &__submenu {
      width: 100%;
      height: auto;
      display: none;

      left: 0;
      padding: 20px 0;
      position: relative;

      border-top: 1px solid $bord;
      overflow: inherit;

      &-list {
        display: block;
        float: none;
      }

      &-logo {
        width: 100%;
        padding: 20px 0 10px;
        position: relative;
        right: 0;

        img {
          max-width: 120px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    & > ul {
      padding: 0 20px;

      & > li {
        & > a {
          pointer-events: none;
          cursor: default;
        }

        &.open {
          .menu__submenu {
            display: block
          }
        }
      }
    }

    &__top {
      display: block;

      position: relative;
      text-align: center;

      &-title {
        width: 100%;
        padding: 20px;

        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $black3;
        background: $gray4;
      }

      &-arrow {
        width: 9px;
        height: 26px;

        position: absolute;
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;

        img {
          width: 9px;
          height: 12px;
        }
      }

      &-close {
        width: 14px;
        height: 26px;

        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .banner {
    padding: 20px 0;
  }

  .wrapper {
    padding-left: 0;
  }

  .catalog-min {
    .col-lg-2-1 {
      flex: 0 0 33.333%;
      max-width: 33.333%;

      &:nth-of-type(3) {
        display: block;
      }
    }
  }

  .bottom-block {
    background: $gray4;

    &__img {
      display: none;
    }
  }

  .delivery-tw {
    margin-bottom: 30px;
  }

  .card-product {
    margin-bottom: 40px;

    &__slider {
      width: 100%;
      flex-wrap: wrap;

      &-for {
        width: 100%;
        order: 1;
        margin-left: 0;

        .slick-arrow {
          display: none!important;
        }

        &-item {
          height: 330px;

          background-size: contain;
        }
      }

      &-nav {
        margin: 0 -3px;

        .slick-slide {
          padding: 0 3px;
        }

        &-item {
          width: 75px;
        }
      }

      &-nav {
        width: 100%;
        order: 2;
      }
    }

    h1 {
      margin-bottom: 25px;
      display: none;
    }

    &__description {
      width: 100%;
      padding-left: 0;
      margin-top: 25px;

      p {
        margin-bottom: 20px;

      }

      &-top {
        margin-bottom: 15px;
        display: none;
      }

      &-link {
        background-position: 98% center;
      }

      &-btns {
        margin-bottom: 20px;

        .button {
          margin-right: 17px;
        }
      }
    }

    &__top {
      &-mob {
        display: block;

        h1,
        .card-product__description-top {
          display: flex;
        }
      }
    }

    &__all {
      &-reviews,
      &-logo {
        height: 94px;
        padding: 18px 10px;
        margin-right: 14px;
      }
    }

    &__reviews {
      &-item {
        padding: 18px 15px;
      }

      &-info {
        margin-bottom: 10px;

        &-rating,
        &-data {
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;
        }
      }

    }

    &__tabs {
      &-holder {
        display: block;
      }

      &-label {
        &-item {
          width: 84px;
          margin-right: 9px;
          padding: 60px 20px 13px;

          background: $white;
          box-shadow: 3px 4px 14px rgba(67, 26, 26, 0.08);
          border-radius: 10px;
          text-align: center;
          justify-content: center;

          &:last-of-type {
            margin-right: 0;
          }

          img {
            top: 0;
            right: 0;
            margin: auto;
          }
        }
      }

      &-inner {
        width: 100%;
        padding-right: 0;
      }

      &-table {

      }

      &-storage {

      }

      &-text {
        margin-bottom: 55px;

        &-mob {
          display: none;

          &.open {
            display: block;
          }

          &-btn {
            display: block;
            margin-top: 20px;
            padding: 13px;

            font-weight: 500;
            font-size: 13px;
            line-height: 121.03%;
            color: $black3;
            text-align: center;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            border: 1px solid $black3;
            border-radius: 10px;

            &.open {
              display: none;
            }
          }
        }
      }
    }

    &__right {
      width: 100%;
      margin-top: 30px;
    }

    &__inner {
      &-tw {
        margin: 0;
      }
    }

    &__bann {
      margin-bottom: 10px;
    }

    &__other {
      margin-top: 35px;
      width: 100%;
    }
  }

  .nav {
    &-link {
      padding: 15px;
    }

    &-tabs {
      overflow: auto;
      display: flex;
      flex-wrap: inherit;
      margin-bottom: 25px;
    }
  }

  .tx {
    &__inner {
      padding: 45px 0px;
    }
  }

  .check-in {
    margin: 50px 0;

    &__inner {
      display: block;
    }

    &__tx {
      width: 100%;
    }

    &__img {
      display: none;
    }
  }

  .lk {
    margin-bottom: 50px;

    &__left {
      width: 100%;
      padding-right: 0;
      order: 2;

    }

    &__menu {
      order: 1;
      width: 100%;
      margin-bottom: 30px;
    }

    &__setting {
      &-form {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .order__delivery .order__payment-list.fl {
    display: block;
  }

  .order__payment-list-change-tx {
    font-size: 14px;
    margin-bottom: 10px;
    padding-right: 0;
  }

  .card-product__slider-label {
    display: block;
    margin-top: 0;
  }

  .about {
    &-text {
      h1 {
        font-weight: 500;
        font-size: 26px;
        line-height: 130%;
      }

      &__tx {
        p {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    &-work {
      padding: 70px 0;

      h2 {
        font-size: 26px;
        line-height: 130%;
      }

      &__inner {
        &:before {
          display: none;
        }

        &:after {
          left: 0;
        }
      }
    }

    &-all {
      &__item {
        p {
          font-size: 16px;
          line-height: 24px;
        }

        &-title {
          font-size: 22px;
          line-height: 30px;

          &-min {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .catalog-brand-title__in-list {
    right: inherit;
    left: 0;

    &:after {
      right: inherit;
      left: 30px;
    }
  }

  .alphabet {
    margin-bottom: 50px;

    &-list {
      &-item {
        flex: 0 0 50%;
        max-width: 50%;

        font-size: 16px;
        line-height: 22px;
      }

      &-lt {
        &.tw {
          width: 100%;
        }
      }
    }
  }

  .pay {
    &-descrip {
      margin-bottom: 50px;

      p {
        font-size: 16px;
        line-height: 22px;
      }

      &__item {
        padding: 15px;
      }
    }

    &-list {
      margin-bottom: 50px;

      &__row {
        display: block;
      }

      &__icon,
      &__title,
      &__tx {
        width: 100%;
      }

      &__icon {
        margin-bottom: 10px;
      }

      &__title {
        margin-bottom: 10px;
      }

    }
  }

  h1 {
    font-weight: 500;
    font-size: 26px;
    line-height: 130%;
  }

  .actions {
    margin-bottom: 50px;
  }

  .title-main {
    margin: 11px 0 25px;

    &-tw {
      padding-right: 0 !important;
    }

    &__numb {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .button {
    &-all {
      padding: 7px 15px;

      font-size: 10px;
      line-height: 121.03%;

      span {
        display: none;
      }
    }

    &-bord {
      padding: 8px 19px;

      font-size: 14px;
    }
  }

  .banner {
    padding: 20px 0;

    &__big {
      height: auto;

      &-slider {
        &-item {
          padding: 19px 16px 40px;
          height: auto;

          .button {
            display: none;
          }
        }

        &-title {
          margin-bottom: 8px;

          font-weight: 500;
          font-size: 20.0608px;
          line-height: 130%;
          color: $black;
        }

        &-tx {
          margin-bottom: 10px;
          font-size: 14.0426px;
          line-height: 17px;
        }
      }
    }

    &__al {
      margin: 0 -10px;
    }

    &__min {
      height: auto;
      padding: 15px 9px;
      min-height: 65px;
      margin-bottom: 0;

      &-in {
        padding: 0 10px;
      }

      &-title {
        font-weight: 500;
        font-size: 6.58741px;
        line-height: 130%;
      }
    }
  }

  .slick {
    &-arrow {
      .banner & {
        display: none !important;
      }
    }
  }

  .catalog {
    margin: 30px 0 50px;

    &-min {
      margin-bottom: 45px;

      &-tw {
        .col-lg-2-1 {
          flex: 0 0 100%!important;
          max-width: 100%!important;
          margin-bottom: 0;
        }
      }

      .row {
        margin: 0 -7px;
      }

      .col-lg-2-1 {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 7px;

        &:nth-of-type(3) {
          display: none;
        }
      }

      &__top {
        margin-bottom: 10px;
        padding: 7px 12px;

        &-title {
          width: 55%;

          font-size: 14px;
          line-height: 16px;

          img {
            display: none;
          }
        }
      }
    }

    &__item {
      &-bt {
        padding-bottom: 105px;
      }

      &-label {
        width: 27px;
        height: 27px;


        & + .catalog__item-label {
          left: 35px;

          & + .catalog__item-label {
            left: 57px;
          }
        }

        &:before {
          width: 23px;
          height: 23px;
        }

        &:after {
          width: 21px;
          height: 21px;
        }

        span {
          font-size: 7.57895px;
          line-height: 9px;
        }
      }

      &-title {
        font-size: 12px;
        line-height: 121.03%;
      }

      &-numb {
        font-size: 10px;
        line-height: 11px;
      }

      &-info {
        display: block;
        padding-bottom: 10px;
      }

      &-buy {
        height: 27px;
        width: 100%;

        font-size: 10px;
        line-height: 121.03%;
        border-radius: 5px;

        img {
          width: 15px;
        }
      }

      &-amount {
        width: 100%;
        height: 30px;

        .up,
        .down {
          width: 22px;
          height: 22px;
        }

        input {
          font-size: 12px;
        }
      }

      &-offer {
        padding: 7px 15px;

        font-size: 10px;
        line-height: 11px;
      }

      &-price {
        margin-bottom: 5px;

        &-old {
          display: inline-block;
          margin-right: 5px;

          font-size: 10px;
          line-height: 11px;
        }

        &-now {
          display: inline-block;

          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    &-brand {
      &-title {
        padding: 15px 0 30px;

        &__holder {
          display: block;
          margin-top: 17px;
        }

        &__main {
          margin-bottom: 18px;
        }
      }
    }

    &__top {
      flex-wrap: wrap;
      margin-bottom: 0;

      border: none;
      padding-top: 0;
    }

    &__sorting {
      margin-bottom: 20px;
      order: 1;
      max-width: 100%;
      flex: 0 0 100%;
      text-align: left;

      &-list {
        margin-left: 0;

        li {
          a {
            font-size: 12px;
            padding: 7px 11px;
          }
        }
      }

      &-title {
        font-size: 12px;
      }
    }

    &__list {
      .row {
        margin: 0 -7px;
      }

      .col-lg-2-1 {
        max-width: 50%;
        flex: 0 0 50%;

        padding: 0 7px;
      }
    }

    &-subscription {
      margin-bottom: 20px;

      .row {
        margin: 0 -7px;
      }

      .col-lg-2-1 {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 7px;
      }

      &__top {
        display: block;
        margin-bottom: 10px;

        border: none;

        p {
          margin-bottom: 10px;

          font-size: 14px;
          line-height: 18px;
        }
      }

      &__item {
        &-title {
          padding-top: 15px;

          font-size: 14px;
        }
      }
    }
  }

  .delivery {
    padding: 15px 0 40px;

    &-tw {
      padding: 30px 0;

      &__title {
        margin-bottom: 10px;

        font-size: 19px;
        line-height: 26px;
      }

      &__img {
        margin-bottom: 30px;
      }

      &__info {
        padding-left: 0;

        p {
          margin-bottom: 25px;

          font-size: 14px;
        }
      }

      &__list {
        display: block;

        &-item {
          display: flex;
          margin-right: 0;
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    &__info {
      padding: 0 0 30px;

      &-title {
        margin-bottom: 16px;
      }

      p {
        font-size: 13px;
      }
    }

    &__order {
      padding: 29px 16px 40px;

      &-title {
        font-size: 18px;
      }

      p {
        margin-bottom: 25px;

        font-size: 14px;
      }
    }
  }

  .bottom-block {
    &__title {
      margin-bottom: 20px;

      font-size: 20px;
      line-height: 25px;
    }

    &__info {
      padding: 30px 0 15px;
    }
  }

  .footer {
    &__menu {
      margin-bottom: 25px;

      li {
        display: block;
        margin-bottom: 10px;
        margin-right: 0;

        a {
          text-transform: inherit;
        }
      }
    }

    &__top {
      &-inner {
        display: block;
        padding: 18px 0;
      }

      &-info {

      }
    }

    &__tel,
    &__mail {
      justify-content: flex-start;
      margin-left: 0;
      margin-bottom: 4px;
    }

    &__tel {
      font-weight: 500;
      font-size: 18px;
      line-height: 121.03%;
      color: $green-hov;
    }

    &__mail {
      font-size: 14px;
      line-height: 16px;
      text-transform: inherit;
    }

    &__bottom {
      position: relative;

      &-inner {
        display: block;
        padding: 24px 0;
      }

      &-descr {
        display: block;
        width: 100%;
      }

      &-info {
        li {
          a {
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
    }

    &__dev {
      margin-top: 15px;

      font-size: 12px;
      line-height: 24px;
    }

    &__soc {
      position: absolute;
      top: -56px;
      right: 0;
    }
  }

  .breadcrumb {
    &-block {
      margin: 8px 0;
    }

    &-item {
      display: none;

      &:before {
        display: none !important;
      }

      &:nth-last-of-type(2) {
        display: inline-block;

        a {
          display: flex;
          align-items: center;

          font-size: 14px;
          line-height: 150%;

          &:before {
            content: '<';
            display: inline-block;
            vertical-align: middle;
            width: 14px;
            height: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }

  .actions {
    &-item {
      margin-bottom: 50px;

      &__info {
        display: block;

        &-item {
          width: 100%;
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 20px;
        }
      }

      &__left {
        width: 100%;
        margin-bottom: 30px;
      }

      &__right {
        width: 100%;
        padding-left: 0;
      }

      &__inner {
        display: block;
      }
    }

    &__item {
      &-img {
        height: 160px;
      }

      &-tx {
        padding: 19px 17px 45px;
      }
    }

    &-slider {
      margin: 30px 0;

      &__inner {
        .title-main-tw {
          .button {
            display: none;
          }
        }
      }

      &__sldr {
        .slick-slide {
          width: 266px;
        }
      }
    }
  }

  .filter {
    order: 2;
    max-width: 100%;
    flex: 0 0 100%;

    &__btn {
      height: auto;
      width: 100%;
      padding: 12px;

      text-align: center;
      border: 2px solid $black3;
      border-radius: 10px;
    }
  }

  .pagination {
    padding: 0 39px;
    position: relative;

    .page {
      &-item {
        margin: 0;

        position: relative;

        &.prev,
        &.next {
          width: 39px;
          height: 39px;
          margin: 0;

          position: relative;

          .tx {
            display: none;
          }

          a {
            padding: 0;
          }
        }

        &.next {
          margin-left: 0;

          right: -42px;
        }

        &.prev {
          left: -42px;
        }
      }
    }
  }

  .subscription {
    &-list {
      margin-bottom: 10px;

      &__item {
        &-tx {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    &-tx {
      &__inner {
        display: block;
      }
    }
  }

  .card-product {
    &__reviews {
      &-btn {
        display: block;
        margin-top: 20px;
        padding: 13px;

        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        color: $black3;
        text-align: center;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        border: 1px solid $black3;
        border-radius: 10px;

        background: transparent;
      }
    }

    &__product {
      &-row {
        display: block;
        padding-left: 110px;

        position: relative;
      }

      &-descr {
        padding: 0;
        position: inherit;
        min-height: inherit;
      }

      &-btn {
        margin-top: 22px;

        &-main {
          padding: 13px;

          border-radius: 10px;
          border: 1px solid $black3;
          background: transparent;
        }
      }

      &-price {
        margin-top: 13px;

        &-now,
        &-old {
          display: inline-block;
        }
      }

      &-img {
        position: absolute;
        left: -110px;
      }

      &-title {
        margin-bottom: 5px;
      }
    }

    &__slider {
      width: 100%;
      flex-wrap: wrap;

      &-for {
        width: 100%;
        order: 1;
        margin-left: 0;

        .slick-arrow {
          display: none !important;
        }

        &-item {
          height: 330px;

          background-size: contain;
        }
      }

      &-nav {
        margin: 0 -3px;

        .slick-slide {
          padding: 0 3px;
        }

        &-item {
          width: 75px;
        }
      }

      &-nav {
        width: 100%;
        order: 2;
      }
    }

    &__description {
      p {
        margin-bottom: 20px;

        font-size: 14px;
        line-height: 146%;
      }

      &-link {
        font-size: 14px;
        line-height: 146%;

        background-position: 98% center;
      }
    }

    &__tabs {
      &-holder {
        display: block;
      }

      &-table {
        margin-bottom: 20px;

        &-cell {
          font-size: 14px;
          line-height: 160%;

          &-city {
            color: $green-hov;
          }
        }

        &-row {
          margin-bottom: 10px;

          &:after {
            bottom: 4px;
          }
        }
      }

      &-storage {
        &-title {
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          line-height: 170%;
        }
      }

      &-label {

        &-text {
          p, ol li {
            font-size: 14px;
            line-height: 170%;
          }
        }
      }

    }

  }

  .subscription {
    &-tx {
      &__inner {
        p {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  .cart {
    &-banner {
      margin-bottom: 50px;

      &-tw {
        padding-left: 0;

        .cart-banner__inner {
          display: block;
          padding: 130px 20px 30px;

          text-align: center;
          border-radius: 15px;

          background-image: url("../images/cart-banner2-2.png");
          background-repeat: no-repeat;
          background-position: center 11%;
        }

        .cart-banner__tx {
          padding: 0;
          display: block;

          p {
            margin-bottom: 12px;
          }
        }
      }

      &__inner {
        display: block;
        padding: 135px 20px 30px;

        text-align: center;
        border-radius: 15px;

        background-image: url("../images/cart-banner1-1.png");
        background-repeat: no-repeat;
        background-position: top center;
      }

      &__tx {
        padding-left: 0;
      }

      &__img {
        display: none;
      }

      &__title {
        margin-right: 0;
        margin-bottom: 0;
      }

      &__btn {
        margin-top: 20px;
        text-align: center;

        img {
          display: block;
          margin: 10px auto 0;
        }
      }

      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 139%;
      }
    }

    &__list {
      &-item {
        display: block;
        padding: 25px 15px 15px 110px;

        position: relative;
      }

      &-title {
        margin-bottom: 8px;
      }

      &-descr,
      &-numb,
      &-amount,
      &-delete {
        max-width: 100%;
        flex: 0 0 100%;
      }

      &-descr {
        padding: 0;
        position: inherit;
        min-height: inherit;
        margin-bottom: 20px;
      }

      &-amount {
        margin-bottom: 25px;

        .catalog__item-amount {
          width: 128px;
        }
      }

      &-img {
        position: absolute;
        left: -110px;
        top: 0;
      }

      &-delete {
        width: 20px;
        height: 20px;

        position: absolute;
        right: 0;
        top: 15px;

        background-repeat: no-repeat;
        background-image: url("../images/svg/close-i.svg");
        background-size: 13px 13px;

        svg {
          display: none;
        }
      }

      &-all {
        flex-wrap: wrap;
        padding: 0;
        margin: 45px 0;

        .cart__list-descr {
          margin-bottom: 30px;
        }

        &-btn {
          max-width: 100%;
          flex: 0 0 100%;

          .button {
            width: 100%;
            margin-top: 20px;
          }
        }

        &-tx, &-price {
          max-width: 50%;
          flex: 0 0 50%;
        }

        &-tx {
          &-title {
            margin-bottom: 10px;
          }
        }

        &-price {
          text-align: right;
        }
      }

      &-promo {
        width: 100%;
      }
    }
  }

  .tx {
    &__inner {
      display: block;
      padding: 25px 0px;
      margin-bottom: 40px;

      text-align: center;
    }

    &__title {
      font-size: 20px;
      line-height: 24px;

      &.big {
        font-size: 60px;
        line-height: 80px;
      }
    }

    &__img {
      img {
        display: block;
        margin: auto;
        max-width: 200px;
      }
    }

    &__text {
      padding-left: 0;
      margin-top: 20px;

      p {
        font-size: 14px;
        line-height: 146%;
      }

      .button {
        display: block;
        width: 100%;
        padding: 15px 24px;
      }
    }
  }

  .order {
    &__delivery {
      padding-bottom: 15px;
    }

    &__range {
      width: 100%;
      padding-bottom: 30px;
    }

    &__wrap {
      padding: 0 10px;

      &-top {
        display: block;
        margin: 0 -10px 20px;
        padding: 10px;
      }
    }

    &__payment {
      &-list {
        display: block;

        &-item {
          &-in {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
          }
        }
      }
    }

    &__input {
      &-cell {
        margin-bottom: 20px;
      }
    }

    .cart {
      &__list {
        &-item {
          padding: 25px 15px 15px 110px;
        }

        &-descr {
          margin-bottom: 10px;
        }

        &-numb {
          &.last {
            max-width: 100%;
            flex: 0 0 100%;
            margin-top: 16px;
          }
        }

        &-descr,
        &-numb {
          max-width: 100%;
          flex: 0 0 100%;
          padding: 0;

          position: relative;
          bottom: 0;

          text-align: left;
        }
      }
    }
  }

  .policy {
    margin-bottom: 50px;

    &__inner {
      p, ul li {
        font-size: 14px;
        line-height: 20px;
      }

      p {
        margin-bottom: 30px;
      }

      h3 {
        font-size: 18px;
      }
    }
  }

  .orders {
    &__top {
      display: block;
    }

    &__title {
      margin-bottom: 10px;
    }

    &__item {
      padding: 20px 10px;

      &-descr {
        &-title {
          display: block;

          span {
            display: block;
          }
        }
      }

      &-product {
        &-row {
          display: block;
        }

        &-right {
          margin-top: 10px;
        }
      }
    }
  }

  .filter {
    &-bl {
      width: 100%;
    }

    &__top {
      &-close {
        display: none!important;

        .filter-bl & {
          display: flex!important;
          left: inherit;
          right: 10px;
          top: 17px;
        }
      }
    }
  }

  .modal {
    &-content {
      padding: 20px 20px 40px;
    }

    .close {
      right: 10px;

      border-color: $green;
      background: $green;
    }

    &-reviews {
      &__title {
        padding-right: 70px;
      }

      &__upload {
        display: block;

        &-tx {
          padding-left: 0;
          margin-top: 15px;
        }
      }

      &__bottom {
        display: block;
      }

      &__rating {
        &-list {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 10px;
        }
      }
    }
  }


  .point {
    &__inner {
      margin-bottom: 40px;
    }

    &__table {
      width: 100%;

      &-row {
        padding: 15px 0;
        display: block;
      }

      &-cell {
        width: 100%!important;
        padding: 3px 0;

        text-align: left!important;
      }
    }
  }

  .brands {
    &__inner {
      margin-bottom: 40px;
    }

    &__all {
      &-inner {
        &-tw {
          width: 100%;
        }
      }

      &-item {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

}




