@charset "UTF-8";
@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Regular.eot");
  src: url("../fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Regular.woff") format("woff"), url("../fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Semibold.eot");
  src: url("../fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Semibold.woff") format("woff"), url("../fonts/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

* {
  margin: 0;
  padding: 0; }

body {
  min-width: 320px;
  font-family: "Ubuntu", sans-serif; }
  body.overfl {
    overflow: hidden;
    height: 100vh; }

.start-page {
  padding: 30px; }
  .start-page-logo {
    display: block;
    margin-bottom: 30px; }
  .start-page ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 60px; }
    .start-page ul li {
      padding: 5px 0; }
      .start-page ul li b {
        font-size: 20px; }
      .start-page ul li a {
        font-size: 16px;
        line-height: 140%;
        color: #000000; }

div:focus,
span:focus,
a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus,
.button:focus {
  outline: none; }

svg use {
  transition: 0.3s; }

ul li {
  list-style: none; }

a {
  cursor: pointer;
  transition: 0.3s; }
  a:hover {
    text-decoration: none; }

.button {
  border: none;
  box-shadow: none;
  outline: none;
  transition: 0.3s;
  text-align: center;
  justify-content: center; }
  .button-bord {
    padding: 11px 24px;
    display: inline-block;
    border: 2px solid #000000;
    border-radius: 10px;
    font-size: 16px;
    line-height: 19px;
    color: #000000; }
    .button-bord:hover {
      background: #000000;
      color: #ffffff; }
  .button-bord2 {
    display: inline-flex;
    align-items: center;
    padding: 11px 15px;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    border: 2px solid #000000;
    border-radius: 10px; }
    .button-bord2 img {
      margin-left: 5px; }
    .button-bord2:hover {
      color: #ffffff;
      background: #000000; }
  .button-all {
    display: inline-flex;
    align-items: center;
    padding: 11px 15px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    color: #3F4042;
    border: 1px solid #7ABC2F;
    border-radius: 10px; }
    .button-all img {
      margin-left: 5px; }
    .button-all:hover {
      color: #3F4042;
      background: #7ABC2F; }
      .catalog-min .button-all:hover {
        color: #ffffff; }
    .button-all span {
      margin-right: 3px; }
  .button-primary {
    padding: 11px 24px;
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    background: #7ABC2F;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    color: #ffffff !important;
    letter-spacing: 0.07em;
    text-transform: uppercase; }
    .button-primary img {
      margin-left: 5px; }
    .button-primary:hover {
      background: #4F742E; }
  .button-secondary {
    display: inline-flex;
    align-items: center;
    padding: 11px 24px;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #ffffff;
    background: #7ABC2F;
    border-radius: 10px; }
    .button-secondary:hover {
      background: #4F742E;
      color: #ffffff; }
  .button-gr {
    padding: 11px 24px;
    display: inline-flex;
    align-items: center;
    color: #4F742E;
    background: #DFF0D8;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    border-radius: 10px; }
    .button-gr:hover {
      color: #ffffff;
      background: #4F742E; }
  .button-tx {
    padding: 11px 24px;
    display: inline-flex;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #3F4042; }
    .button-tx:hover {
      color: #4F742E; }

.container {
  max-width: 100%;
  padding-right: 130px;
  padding-left: 130px; }

@media screen and (max-width: 1200px) and (min-width: 1500px) {
  .catalog-min .col-lg-3 {
    flex: 0 0 33.333%;
    max-width: 33.333%; } }

.col-lg-2-1 {
  margin-bottom: 30px; }
  @media (min-width: 1500px) {
    .col-lg-2-1 {
      flex: 0 0 20%;
      max-width: 20%; } }

h2 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 26px;
  line-height: 130%;
  color: #000000; }

h1 {
  margin-bottom: 0;
  margin-right: 8px;
  display: inline-block;
  vertical-align: bottom;
  font-weight: 500;
  font-size: 29px;
  line-height: 32px;
  color: #000000; }

h3 {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #000000; }

.wrapper {
  padding-left: 315px; }
  .wrapper__inner {
    padding-left: 50px; }

.header {
  position: relative;
  border-bottom: 1px solid #EBEBEB; }
  .header__top {
    padding: 6px 0;
    background: #323436; }
    .header__top-inner {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .header__top-info {
      display: flex;
      align-items: center; }
    .header__top-menu ul {
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .header__top-menu ul li {
        margin-left: 34px; }
        .header__top-menu ul li a {
          font-size: 14px;
          line-height: 16px;
          color: #ffffff; }
          .header__top-menu ul li a:hover {
            color: #7ABC2F; }
  .header__city {
    display: flex;
    align-items: center;
    margin-right: 52px;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #ffffff; }
    .header__city img {
      margin-right: 12px; }
  .header__tel {
    font-size: 14px;
    line-height: 16px;
    color: #CBCBCB; }
    .header__tel a {
      margin-left: 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 121.03%;
      color: #7ABC2F; }
      .header__tel a:hover {
        color: #4F742E; }
  .header__bottom {
    padding: 17px 0;
    background: #FBF8FA;
    border-bottom: 1px solid #EBEBEB; }
    .header__bottom-inner {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .header__bottom-info {
      display: flex;
      align-items: center; }
      .header__bottom-info-item {
        margin-left: 37px;
        position: relative;
        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #3F4042; }
        .header__bottom-info-item-cart .header__bottom-info-notif {
          left: 17px; }
        .header__bottom-info-item img {
          height: 29px;
          margin-right: 10px;
          margin-bottom: 5px; }
        .header__bottom-info-item:hover {
          color: #FFB800; }
      .header__bottom-info-notif {
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 26px;
        top: 0;
        background: #4F742E;
        border-radius: 100px;
        font-weight: 700;
        font-size: 10px;
        line-height: 121.03%;
        color: #ffffff; }
  .header__search {
    width: 52%;
    position: relative; }
    .header__search input {
      width: 100%;
      height: 46px;
      padding: 10px 55px;
      border-radius: 14px;
      background: #EDEDED;
      font-size: 16px;
      line-height: 19px;
      color: #323436;
      border: none;
      box-shadow: none;
      background: #EDEDED; }
      .header__search input::-webkit-input-placeholder {
        color: #9A9494; }
      .header__search input:-ms-input-placeholder {
        color: #9A9494; }
      .header__search input::placeholder {
        color: #9A9494; }
    .header__search-btn {
      width: 24px;
      height: 29px;
      border: none;
      background: transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      margin: auto;
      z-index: 2;
      cursor: pointer; }
    .header__search-clean {
      width: 12px;
      height: 12px;
      position: absolute;
      top: -11px;
      bottom: 0;
      right: 19px;
      margin: auto;
      z-index: 2;
      cursor: pointer; }
  .header__toggler {
    display: none; }
  .header__logo-mob {
    display: none; }
  .header__menu-mob {
    display: none; }

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 38px;
  font-weight: 700;
  font-size: 13px;
  line-height: 160.03%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4F742E !important; }
  .logo img {
    margin-right: 15px; }
  .logo span {
    position: relative;
    top: 12px; }

.menu {
  width: 315px;
  height: 100vh;
  padding: 12px 36px;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: #ffffff;
  box-shadow: 1px 4px 13px rgba(173, 168, 154, 0.12);
  overflow: auto; }
  .menu__top {
    display: none; }
  .menu ul > li {
    border-top: 1px solid #EBEBEB; }
    .menu ul > li > a {
      display: block;
      padding: 18px 0;
      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #3F4042;
      background-image: url("../images/svg/arrow2.svg");
      background-size: 7px 11px;
      background-repeat: no-repeat;
      background-position: center right; }
      .menu ul > li > a:hover {
        color: #4F742E; }
    @media screen and (min-width: 991px) {
      .menu ul > li:hover > .menu__submenu {
        visibility: visible;
        opacity: 1; } }
  .menu__submenu {
    padding: 55px 182px 30px 82px;
    width: calc(100% - 279px);
    height: 100vh;
    min-height: 100%;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 279px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear,opacity 0.3s linear;
    background: #ffffff; }
    .menu__submenu-list {
      float: left;
      width: 48%;
      margin-bottom: 30px; }
    .menu__submenu-logo {
      width: 130px;
      padding: 55px 0 30px;
      position: absolute;
      right: 20px;
      top: 0;
      text-align: center; }
      .menu__submenu-logo img {
        display: block;
        margin-bottom: 16px; }
    .menu__submenu-title {
      display: block;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #3F4042; }
      .menu__submenu-title:hover {
        color: #4F742E; }
    .menu__submenu ul {
      display: inline-block; }
      .menu__submenu ul li {
        margin-bottom: 15px;
        border: none; }
        .menu__submenu ul li:last-of-type {
          margin-bottom: 0; }
        .menu__submenu ul li a {
          padding: 0 !important;
          text-transform: inherit;
          font-size: 14px;
          line-height: 16px;
          color: #7C7C7C;
          background: transparent;
          font-weight: 400;
          letter-spacing: 0; }
          .menu__submenu ul li a:hover {
            color: #4F742E; }

.banner {
  padding: 45px 0 20px; }
  .banner__big {
    height: 375px; }
    .banner__big-slider {
      opacity: 0; }
      .banner__big-slider.slick-initialized {
        display: block;
        opacity: 1;
        transition: opacity .3s ease-out; }
      .banner__big-slider-item {
        height: 375px;
        padding: 45px 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 5px; }
      .banner__big-slider-title {
        margin-bottom: 11px;
        font-weight: 500;
        font-size: 32px;
        line-height: 37px;
        color: #000000; }
      .banner__big-slider-tx {
        margin-bottom: 25px;
        font-size: 16px;
        line-height: 140%;
        color: #000000; }
  .banner__min {
    display: block;
    height: 177px;
    margin-bottom: 20px;
    padding: 30px 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 5px; }
    .banner__min-title {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }

.slick-arrow {
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 40px;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 100px;
  box-shadow: none;
  border: none;
  overflow: hidden;
  color: transparent;
  background-image: url("../images/svg/arrow.svg");
  background-repeat: no-repeat;
  background-size: 5px 9px;
  background-position: center;
  cursor: pointer; }
  .banner .slick-arrow {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 100px;
    border: none; }
    .banner .slick-arrow:beefore {
      content: '';
      display: block;
      width: 5px;
      height: 9px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-image: url("../images/svg/arrow.svg");
      background-repeat: no-repeat;
      background-size: 5px 9px;
      background-position: center; }
  .slick-arrow:hover {
    background-color: #ffffff; }

.slick-prev {
  left: 50px; }

.slick-next {
  left: 95px;
  transform: rotate(180deg); }

.delivery {
  padding: 45px 0 95px; }
  .delivery-tw {
    padding: 100px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    .delivery-tw__info {
      padding-left: 60px; }
      .delivery-tw__info p {
        margin-bottom: 35px;
        font-size: 16px;
        line-height: 154%;
        color: #515461; }
    .delivery-tw__title {
      margin-bottom: 27px;
      font-family: "ProximaNova", sans-serif;
      font-weight: 600;
      font-size: 30px;
      line-height: 39px;
      color: #000000; }
    .delivery-tw__list-item {
      display: inline-flex;
      align-items: center;
      margin-right: 80px;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 16px;
      color: #3F4042; }
      .delivery-tw__list-item img {
        margin-right: 15px; }
  .delivery__info {
    padding: 0 50px;
    text-align: center; }
    .delivery__info-title {
      margin-top: 27px;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }
    .delivery__info p {
      font-size: 16px;
      line-height: 154%;
      color: #515461; }
  .delivery__order {
    height: 100%;
    padding: 67px 115px 80px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; }
    .delivery__order-title {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 20px;
      line-height: 130%; }
    .delivery__order p {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 154%;
      color: #515461; }

.bottom-block {
  background: #F5F4F4; }
  .bottom-block__inner {
    display: flex; }
  .bottom-block__title {
    margin-bottom: 25px;
    font-family: "ProximaNova", sans-serif;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #000000; }
  .bottom-block__btns-item {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px; }
  .bottom-block__info {
    width: 40%;
    padding: 65px 0; }
  .bottom-block__img {
    position: relative; }
    .bottom-block__img-tx {
      position: absolute;
      right: 47px;
      top: 101px;
      z-index: 2;
      font-size: 16px;
      line-height: 22px;
      color: #3F4042; }

.footer {
  background: #F5F4F4;
  border-top: 1px solid #EBEBEB; }
  .footer__top-inner {
    padding: 22px 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .footer__bottom {
    border-top: 1px solid #EBEBEB; }
    .footer__bottom-inner {
      padding: 8px 0;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .footer__bottom-descr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 312px; }
    .footer__bottom-info {
      margin-bottom: 0; }
      .footer__bottom-info li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 22px;
        position: relative;
        font-size: 13px;
        line-height: 18px;
        color: #9A9494; }
        .footer__bottom-info li:after {
          content: '';
          width: 3px;
          height: 3px;
          display: block;
          position: absolute;
          right: -15px;
          top: 0;
          bottom: 0;
          margin: auto;
          background: #9A9494;
          border-radius: 10px; }
        .footer__bottom-info li:last-of-type:after {
          display: none; }
        .footer__bottom-info li a {
          color: #9A9494;
          font-size: 13px;
          line-height: 18px; }
          .footer__bottom-info li a:hover {
            color: #FFB800; }
  .footer__dev {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    line-height: 18px;
    color: #9A9494; }
    .footer__dev:hover {
      color: #FFB800; }
  .footer__soc {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle; }
    .footer__soc li {
      margin-right: 7px;
      display: inline-block;
      vertical-align: middle; }
      .footer__soc li a svg {
        width: 22px;
        height: 22px; }
        .footer__soc li a svg use {
          color: #3F4042; }
      .footer__soc li a:hover svg use {
        color: #FFB800; }
  .footer__menu {
    margin-bottom: 0; }
    .footer__menu li {
      display: inline-block;
      margin-right: 36px; }
      .footer__menu li a {
        letter-spacing: 0.07em;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        color: #3F4042; }
        .footer__menu li a:hover {
          color: #FFB800; }
  .footer__tel, .footer__mail {
    margin-left: 46px;
    font-weight: 500;
    font-size: 14px;
    line-height: 121.03%;
    color: #3F4042;
    text-transform: uppercase; }
    .footer__tel:hover, .footer__mail:hover {
      color: #FFB800; }

.catalog__item-amount {
  display: none; }
  .ct .catalog__item-amount {
    display: block; }

.ct .catalog__item-buy {
  display: none; }

.catalog__item-buy {
  cursor: pointer; }

.catalog {
  margin: 50px 0 100px; }
  .catalog-min {
    margin-bottom: 25px; }
    .catalog-min-tw {
      margin-bottom: 85px; }
    .catalog-min__top {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9px 20px;
      background: #F5F4F4;
      border-radius: 14px; }
      .catalog-min__top-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: #000000; }
        .catalog-min__top-title img {
          margin-right: 20px; }
    .catalog-min__labels {
      margin-bottom: 10px; }
      .catalog-min__labels-item {
        padding: 3px 21px;
        margin-right: 5px;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: middle;
        background: #EDEDED;
        border-radius: 10px;
        border: 1px solid transparent; }
        .catalog-min__labels-item span {
          font-size: 16px;
          line-height: 175%;
          color: #515461; }
        .catalog-min__labels-item img {
          margin-right: 10px; }
        .catalog-min__labels-item:hover {
          border-color: #7ABC2F;
          background: #ffffff; }
  .catalog__item {
    height: 100%;
    padding-bottom: 90px;
    position: relative;
    border: 1px solid #EBEBEB;
    border-radius: 15px;
    background: #ffffff;
    overflow: hidden; }
    .catalog__item-bt {
      padding-bottom: 120px; }
    .catalog__item-top {
      position: relative; }
    .catalog__item-bottom {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0; }
    .catalog__item-label {
      width: 43px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 8px;
      left: 11px;
      z-index: 3;
      border-radius: 100px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px; }
      .catalog__item-label + .catalog__item-label {
        left: 45px;
        z-index: 2; }
        .catalog__item-label + .catalog__item-label + .catalog__item-label {
          left: 82px;
          z-index: 1; }
      .catalog__item-label span {
        position: relative;
        z-index: 3; }
      .catalog__item-label:before {
        content: '';
        width: 39px;
        height: 39px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        border: 1px solid transparent;
        border-radius: 100px; }
      .catalog__item-label:after {
        content: '';
        width: 37px;
        height: 37px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        border: 1px solid transparent;
        border-radius: 100px; }
      .catalog__item-label-hit {
        background: #B0BBCC;
        color: #536474; }
        .catalog__item-label-hit:before {
          border-color: #677C90; }
        .catalog__item-label-hit:after {
          border-color: #CAD1DB;
          background: #B0BBCC; }
      .catalog__item-label-new {
        background: #C7E893;
        color: #78A93F; }
        .catalog__item-label-new:before {
          border-color: #78A93F; }
        .catalog__item-label-new:after {
          border-color: #ABCC83;
          background: #C7E893; }
      .catalog__item-label-sale {
        background: #FFB800;
        color: #B47600; }
        .catalog__item-label-sale:before {
          border-color: #C78300; }
        .catalog__item-label-sale:after {
          border-color: #FFDA7B;
          background: #FFB800; }
    .catalog__item-fav {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
      cursor: pointer; }
      .catalog__item-fav svg {
        width: 25px;
        height: 25px; }
        .catalog__item-fav svg use {
          color: #9DA3AE; }
      .catalog__item-fav:hover svg use {
        color: #7ABC2F; }
      .catalog__item-favorites .catalog__item-fav {
        background-image: url("../images/svg/like2.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px 18px; }
        .catalog__item-favorites .catalog__item-fav svg {
          width: 21px;
          height: 18px;
          display: none; }
    .catalog__item-img {
      display: flex;
      align-items: center;
      justify-content: center; }
      .catalog__item-img img {
        display: block;
        width: 100%;
        max-width: 100%;
        max-height: 247px; }
    .catalog__item-tx {
      padding: 18px 15px 0 15px; }
    .catalog__item-title {
      display: block;
      margin-bottom: 5px;
      font-size: 16px;
      line-height: 19px;
      color: #3F4042; }
      .catalog__item-title:hover {
        color: #FFB800; }
    .catalog__item-numb {
      font-size: 14px;
      line-height: 16px;
      color: #9A9494; }
    .catalog__item-info {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .catalog__item-price-old {
      margin-bottom: 3px;
      font-size: 12px;
      line-height: 14px;
      color: #9A9494;
      text-decoration: line-through; }
    .catalog__item-price-now {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #4F742E; }
    .catalog__item-buy {
      width: 123px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #ffffff;
      border-radius: 10px;
      background: #7ABC2F; }
      .catalog__item-buy img {
        margin-left: 5px; }
      .catalog__item-buy:hover {
        background: #4F742E;
        color: #ffffff; }
    .catalog__item-offer {
      width: 100%;
      display: block;
      padding: 10px 15px;
      font-size: 14px;
      line-height: 16px;
      color: #4F742E;
      border-top: 1px solid #EBEBEB;
      text-align: center; }
    .catalog__item-amount {
      width: 123px;
      height: 38px;
      position: relative;
      border-radius: 10px;
      border: 1px solid #EBEBEB;
      overflow: hidden; }
      .catalog__item-amount:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        background: transparent; }
      .catalog__item-amount input {
        width: 100%;
        height: 100%;
        font-size: 18px;
        line-height: 21px;
        color: #3F4042;
        text-align: center;
        border: none;
        box-shadow: none;
        outline: none; }
      .catalog__item-amount .up,
      .catalog__item-amount .down {
        width: 35px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;
        border-radius: 10px;
        background: #F5F4F4;
        cursor: pointer; }
      .catalog__item-amount .up {
        right: 2px; }
      .catalog__item-amount .down {
        left: 2px; }
  .catalog__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #EBEBEB; }
  .catalog__sorting {
    width: 100%;
    text-align: right; }
    .catalog__sorting-title {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -2px;
      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #3F4042; }
    .catalog__sorting-list {
      margin-bottom: 0;
      display: inline-block;
      vertical-align: middle; }
      .catalog__sorting-list li {
        height: 30px;
        display: inline-block;
        vertical-align: middle; }
        .catalog__sorting-list li a {
          padding: 7px 18px;
          font-weight: 500;
          font-size: 13px;
          line-height: 121.03%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: #9A9494;
          border: 1px solid transparent;
          border-radius: 12px; }
          .catalog__sorting-list li a:hover {
            color: #7ABC2F; }
        .catalog__sorting-list li.active a {
          color: #3F4042;
          border-color: #7ABC2F; }
  .catalog-brand-title {
    padding: 30px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    .catalog-brand-title__in {
      position: relative; }
      .catalog-brand-title__in:hover .catalog-brand-title__in-list {
        display: block; }
      .catalog-brand-title__in-list {
        display: none;
        width: 260px;
        padding: 15px 30px;
        position: absolute;
        top: 60px;
        right: 0;
        z-index: 5;
        background: #ffffff;
        border-radius: 10px; }
        .catalog-brand-title__in-list:before {
          content: '';
          width: 100%;
          height: 30px;
          background: transparent;
          position: absolute;
          top: -23px;
          left: 0; }
        .catalog-brand-title__in-list:after {
          content: '';
          width: 21px;
          height: 21px;
          display: block;
          position: absolute;
          right: 77px;
          top: -8px;
          background-image: url(../images/svg/ar1.svg);
          background-repeat: no-repeat; }
        .catalog-brand-title__in-list-item {
          padding: 12px 0;
          display: block;
          border-bottom: 1px solid #EBEBEB; }
          .catalog-brand-title__in-list-item:last-of-type {
            border: none; }
          .catalog-brand-title__in-list-item span {
            margin-left: 15px;
            font-size: 14px;
            line-height: 16px;
            color: #3F4042; }
          .catalog-brand-title__in-list-item:hover span {
            color: #7ABC2F; }
    .catalog-brand-title__holder {
      margin-top: 27px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .catalog-brand-title__logo {
      width: 80px;
      height: 80px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 1000px;
      background: #ffffff; }
 .catalog-brand-title__logo img { height: 68px; width: 68px; top: 6px; position: absolute; left: 6px;}.catalog-brand-title__logo:before {
        content: '';
        width: 76px;
        height: 76px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        border: 2px solid #468294;
        border-radius: 100px; }
      .catalog-brand-title__logo:after {
        content: '';
        width: 70px;
        height: 70px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        border: 2px solid #8ECCDE;
        border-radius: 100px; }
    .catalog-brand-title__main {
      min-height: 80px;
      position: relative;
      padding-left: 110px; }
      .catalog-brand-title__main h1 {
        display: block;
        padding-top: 14px;
        margin-bottom: 5px; }
  .catalog-subscription {
    margin-bottom: 70px; }
    .catalog-subscription-bord {
      margin: 70px 0; }
    .catalog-subscription__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      margin-bottom: 25px;
      border-bottom: 1px solid #EBEBEB; }
      .catalog-subscription__top p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 21px;
        color: #333850; }
      .catalog-subscription__top-link {
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        color: #4F742E;
        text-decoration: underline; }
        .catalog-subscription__top-link:hover {
          color: #7ABC2F; }
    .catalog-subscription-bord .catalog-subscription__list {
      padding: 35px 0;
      border-top: 1px solid #EBEBEB; }
    .catalog-subscription__item {
      padding-bottom: 50px;
      margin-bottom: 20px;
      height: 100%;
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      border: 1px solid #EBEBEB;
      text-align: center; }
      .catalog-subscription__item-img {
        display: flex;
        align-items: center;
        justify-content: center; }
        .catalog-subscription__item-img img {
          display: block;
          width: 100%;
          max-width: 100%;
          max-height: 247px; }
      .catalog-subscription__item-title {
        padding: 20px 20px 0;
        display: block;
        font-size: 16px;
        line-height: 110%;
        color: #3F4042;
        text-align: center; }
        .catalog-subscription__item-title:hover {
          color: #4F742E; }
      .catalog-subscription__item-offer {
        width: 100%;
        display: block;
        padding: 10px 15px;
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 14px;
        line-height: 16px;
        color: #4F742E;
        border-top: 1px solid #EBEBEB;
        text-align: center; }
        .catalog-subscription__item-offer:hover {
          color: #7ABC2F; }

.subscription-list {
  margin-bottom: 45px; }
  .subscription-list .col-lg-4:last-of-type .subscription-list__item:after {
    display: none; }
  .subscription-list__item {
    display: flex;
    align-items: center;
    position: relative; }
    .subscription-list__item:after {
      content: '';
      width: 25px;
      height: 3px;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-image: url("../images/svg/point.svg");
      background-repeat: no-repeat;
      background-size: 25px 3px; }
    .subscription-list__item-img {
      width: 62px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 3px 4px 14px rgba(67, 26, 26, 0.08);
      border-radius: 10px;
      background: #ffffff; }
    .subscription-list__item-tx {
      width: calc(100% - 62px);
      padding-left: 20px;
      font-size: 16px;
      line-height: 20px;
      color: #4F742E; }

.subscription-tx {
  margin: 20px 0; }
  .subscription-tx + .catalog {
    margin-top: 30px; }
  .subscription-tx__inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .subscription-tx__inner p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 140%;
      color: #333333; }
  .subscription-tx__link {
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
    color: #4F742E;
    text-decoration: underline; }
    .subscription-tx__link:hover {
      color: #7ABC2F; }

.breadcrumb {
  background: transparent;
  border-radius: 0;
  padding: 0;
  margin-bottom: 0; }
  .breadcrumb-block {
    margin: 30px 0; }
    .catalog-brand-title .breadcrumb-block {
      margin: 0; }
  .breadcrumb-item {
    font-size: 14px;
    line-height: 16px;
    color: #3F4042; }
    .breadcrumb-item a {
      font-size: 14px;
      line-height: 16px;
      color: #9A9494; }
      .breadcrumb-item a:hover {
        color: #FFB800; }
    .breadcrumb-item + .breadcrumb-item::before {
      content: '';
      width: 3px;
      height: 3px;
      display: inline-block;
      margin: 0 10px;
      padding: 0;
      position: relative;
      top: -4px;
      border-radius: 10px;
      background: #9A9494; }

.title-main {
  margin: 30px 0 45px; }
  .title-main.tw {
    margin-bottom: 0 !important; }
    .title-main.tw + .catalog {
      margin-top: 20px; }
  .title-main__inner h2 {
    margin-bottom: 0;
    margin-right: 8px;
    display: inline-block;
    vertical-align: bottom;
    font-weight: 500;
    font-size: 29px;
    line-height: 32px;
    color: #000000; }
.title-main__inner .catalog-brand-title__main-show {min-height: 80px;position: relative;padding-left: 110px;}.title-main__inner .catalog-brand-title__main-show h1 {display: block;padding-top: 14px;margin-bottom: 5px;}.title-main__inner .catalog-brand-title__logo-show {width: 80px;height: 80px;position: absolute;top: 0;left: 0;border-radius: 1000px;background: #ffffff;}.title-main__inner .catalog-brand-title__logo-show img {height: 80px;width: 80px;}.title-main__inner .catalog-brand-title__logo-show:before {content: '';width: 76px;height: 76px;display: block;position: absolute;top: 0;bottom: 0;left: 0;right: 0;margin: auto;z-index: 1;border: 2px solid #468294;border-radius: 100px;}.title-main__numb {
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    top: -4px;
    font-size: 14px;
    line-height: 16px;
    color: #3F4042; }
  .title-main-tw {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .title-main-tw .button {
      position: relative;
      top: -4px; }
    .actions-slider .title-main-tw {
      padding-right: 105px; }

.actions {
  margin-bottom: 110px; }
  .actions .col-sm-6 {
    margin-bottom: 30px; }
  .actions-slider {
    margin: 55px 0; }
    .actions-slider__sldr {
      margin: 0 -10px; }
    .actions-slider__item {
      padding: 0 10px; }
  .actions__item {
    position: relative;
    border: 1px solid #EBEBEB;
    border-radius: 15px;
    overflow: hidden;
    background: #ffffff; }
    .actions__item-label {
      display: inline-block;
      padding: 3px 8px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 2;
      font-size: 13px;
      line-height: 16px;
      color: #ffffff;
      background: #7ABC2F;
      border-radius: 10px; }
    .actions__item-tw {
      padding: 15px 20px; }
    .actions__item-img {
      display: block;
      width: 100%;
      height: 190px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
    .actions__item-data {
      margin-bottom: 7px;
      font-size: 14px;
      line-height: 16px;
      color: #9A9494; }
    .actions__item-title {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }
      .actions__item-title:hover {
        color: #FFB800; }
    .actions__item-firm {
      font-size: 14px;
      line-height: 16px;
      color: #4F742E; }
    .actions__item-tx {
      padding: 25px 20px 45px; }
  .actions-item {
    margin-bottom: 100px; }
    .actions-item__inner {
      display: flex; }
    .actions-item__left {
      width: 522px; }
    .actions-item__right {
      width: calc(100% - 522px);
      padding-left: 30px; }
      .actions-item__right p {
        font-size: 16px;
        line-height: 175%;
        color: #515461; }
    .actions-item__img {
      margin-bottom: 20px; }
    .actions-item__info {
      display: flex;
      flex-wrap: wrap; }
      .actions-item__info-item {
        width: 50%;
        flex: 0 0 50%; }
      .actions-item__info-title {
        margin-bottom: 13px;
        font-size: 14px;
        line-height: 16px;
        color: #9A9494; }
      .actions-item__info-tx {
        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #000000; }
    .actions-item__top {
      margin-bottom: 13px; }
    .actions-item__logo {
      width: 58px;
      height: 58px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      border-radius: 100px;
      border: 2px solid #468294; }
    .actions-item__title {
      display: inline-block;
      vertical-align: middle;
      font-weight: 500;
      font-size: 26px;
      line-height: 130%;
      color: #000000; }

.slick-arrow {
  width: 39px;
  height: 39px;
  position: absolute;
  border-radius: 10px;
  border: 1px solid #7ABC2F;
  background-color: transparent;
  background: url("../images/svg/arrow2.svg");
  background-repeat: no-repeat;
  background-size: 7px 11px;
  background-position: center; }
  .card-product__slider .slick-arrow {
    bottom: 25px; }
  .slick-arrow:hover {
    background-color: #7ABC2F; }

.slick-prev {
  transform: rotate(180deg); }
  .actions-slider__sldr .slick-prev {
    top: -63px;
    right: 65px;
    left: inherit; }
  .card-product__slider .slick-prev {
    right: 70px;
    left: inherit; }

.slick-next {
  transform: rotate(0deg); }
  .actions-slider__sldr .slick-next {
    top: -63px;
    right: 15px;
    left: inherit;
    transform: none; }
  .card-product__slider .slick-next {
    right: 20px;
    left: inherit; }

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 10px; }
  .slick-dots li {
    width: 6px;
    height: 6px;
    display: inline-block;
    margin: 0 4px;
    background: #EBEBEB;
    border-radius: 10px; }
    .slick-dots li button {
      display: none; }
    .slick-dots li.slick-active {
      background: #7ABC2F; }

.nicescroll-cursors {
  width: 5px !important;
  background: #7ABC2F !important;
  border: none !important;
  cursor: pointer; }

.nicescroll-rails-vr {
  width: 5px !important;
  background: #EBEBEB !important; }

.filter {
  width: 100%;
  position: relative; }
  .filter.open {
    display: block; }
  .filter-bl {
    display: none;
    padding-bottom: 100px;
    width: 315px;
    height: 100vh;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    overflow: auto;
    background: #ffffff; }
    .open .filter-bl {
      display: block; }
  .filter__top {
    padding: 32px 38px;
    margin-bottom: 20px;
    background: #F5F4F4; }
    .filter__top-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #333333; }
    .filter__top-close {
      display: none;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      position: fixed;
      right: 0;
      left: 345px;
      top: 50px;
      z-index: 10;
      border-radius: 100px;
      border: 1px solid #EBEBEB;
      cursor: pointer;
      background: #B0BBCC; }
      .open .filter__top-close {
        display: flex; }
      .filter-bl .filter__top-close {
        display: none;
        position: absolute;
        left: -70px;
        top: 50px; }
      .filter__top-close:hover {
        border-color: #7ABC2F;
        background: #7ABC2F; }
  .filter__title {
    padding: 13px 0;
    margin-bottom: 13px;
    position: relative;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #3F4042;
    border-bottom: 1px solid #EBEBEB; }
    .filter__title:after {
      content: '';
      width: 12px;
      height: 8px;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../images/svg/arrow2.svg");
      background-size: 12px 8px;
      transform: rotate(90deg);
      transition: 0.3s; }
    .filter__title.open:after {
      transform: rotate(-90deg); }
  .filter__item {
    padding: 6px 38px;
    margin-bottom: 20px;
    position: relative; }
    .filter__item:last-of-type {
      margin-bottom: 20px; }
    .filter__item-bord {
      margin-bottom: 0;
      padding: 0 38px;
      margin-top: -1px; }
  .filter__range-item {
    width: 50%;
    padding-right: 10px;
    position: absolute;
    top: -85px; }
    .filter__range-item:before {
      content: '';
      display: block;
      position: absolute;
      left: 16px;
      top: 4px;
      font-size: 13px;
      line-height: 18px;
      color: #515461; }
    .filter__range-item:nth-of-type(1) {
      left: 0; }
      .filter__range-item:nth-of-type(1):before {
        content: 'От'; }
    .filter__range-item:nth-of-type(2) {
      right: 0; }
      .filter__range-item:nth-of-type(2):before {
        content: 'До'; }
  .filter__range.polzunok-container-6 {
    margin: 105px 0 0;
    box-sizing: border-box;
    outline: none; }
    .filter__range.polzunok-container-6:after {
      content: "";
      clear: both;
      display: table; }
    .filter__range.polzunok-container-6 .ui-slider {
      height: 4px;
      position: relative;
      background: #EBEBEB;
      border-radius: 10px; }
      .filter__range.polzunok-container-6 .ui-slider .ui-slider-range {
        height: 4px;
        position: absolute;
        z-index: 1;
        border: none;
        background: #7ABC2F; }
    .filter__range.polzunok-container-6 .ui-slider-handle {
      width: 16px;
      height: 16px;
      margin-left: -6px;
      position: absolute;
      top: -7px;
      cursor: pointer;
      background: #7ABC2F;
      z-index: 2;
      border-radius: 100px;
      border: 4px solid #ffffff; }
      .filter__range.polzunok-container-6 .ui-slider-handle:focus {
        outline: none; }
    .filter__range.polzunok-container-6 .polzunok-input-6-left,
    .filter__range.polzunok-container-6 .polzunok-input-6-right {
      display: inline-block;
      width: 100%;
      height: 58px;
      padding: 24px 15px 10px;
      font-size: 16px;
      line-height: 19px;
      color: #3F4042;
      border: 1px solid #EBEBEB;
      border-bottom: 3px solid #EBEBEB;
      border-radius: 3px;
      text-align: left; }
    .filter__range.polzunok-container-6 .polzunok-input-6-left {
      left: 0; }
    .filter__range.polzunok-container-6 .polzunok-input-6-right {
      right: 0; }
  .filter__group-check {
    margin-bottom: 7px; }
    .filter__group-check input[type=checkbox] {
      display: none; }
      .filter__group-check input[type=checkbox] + label {
        width: 100%;
        padding-left: 24px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 16px;
        color: #333333;
        cursor: pointer; }
        .filter__group-check input[type=checkbox] + label:before {
          content: "";
          width: 16px;
          height: 16px;
          display: inline-block;
          margin: -5px 5px 0 0;
          position: absolute;
          top: 10px;
          left: 0;
          background-color: #EBEBEB;
          border-radius: 3px;
          transition: color ease .3s; }
      .filter__group-check input[type=checkbox]:checked + label:before {
        background-repeat: no-repeat;
        background-image: url("../images/svg/check2.svg");
        background-size: 10px 8px;
        background-position: center;
        background-color: #7ABC2F; }
    .filter__group-check.open {
      display: block; }
  .filter__holder {
    position: relative; }
    .filter__item-list .filter__holder {
      height: 178px;
      overflow: hidden; }
    .filter__item-bord .filter__holder {
      padding: 7px 0;
      border-top: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB; }
  .filter__btn {
    height: 19px;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #3F4042;
    cursor: pointer; }
    .filter__btn svg {
      width: 17px;
      height: 11px;
      margin-right: 5px; }
      .filter__btn svg use {
        color: #3F4042; }
    .filter__btn:hover {
      color: #FFB800; }
      .filter__btn:hover svg use {
        color: #FFB800; }
    .filter__btn-b {
      padding: 0 38px;
      margin-top: 65px; }
      .filter__btn-b .button {
        width: 100%;
        display: block; }
  .filter__check {
    position: relative; }

.pagination {
  display: flex;
  align-items: center;
  justify-content: center; }
  .pagination-bl {
    margin: 20px 0; }
  .pagination .page-item {
    margin: 0 2px; }
    .pagination .page-item a, .pagination .page-item span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 39px;
      height: 39px;
      font-weight: 500;
      font-size: 15px;
      line-height: 121.03%;
      text-transform: uppercase;
      color: #333333 !important;
      border-radius: 10px;
      border: 1px solid transparent;
      background: #ffffff !important; }
      .pagination .page-item a:hover, .pagination .page-item span:hover {
        background: #7ABC2F !important; }
    .pagination .page-item span {
      cursor: inherit !important;
      color: #515461;
      transition: 0.3s; }
      .pagination .page-item span:hover {
        color: #333333;
        background: #ffffff !important; }
    .pagination .page-item.active a {
      border-color: #7ABC2F; }
    .pagination .page-item.prev, .pagination .page-item.next {
      width: auto;
      position: relative; }
      .pagination .page-item.prev .tx, .pagination .page-item.next .tx {
        width: auto; }
      .pagination .page-item.prev .arrow, .pagination .page-item.next .arrow {
        width: 39px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin: 0;
        border: 1px solid #EBEBEB; }
      .pagination .page-item.prev a, .pagination .page-item.next a {
        width: auto;
        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        background: transparent !important; }
        .pagination .page-item.prev a:hover, .pagination .page-item.next a:hover {
          color: #7ABC2F !important; }
          .pagination .page-item.prev a:hover .arrow, .pagination .page-item.next a:hover .arrow {
            border-color: #7ABC2F;
            background: #7ABC2F !important; }
    .pagination .page-item.next {
      margin-left: 25px; }
      .pagination .page-item.next a {
        padding-right: 60px; }
      .pagination .page-item.next .arrow {
        right: 0; }
    .pagination .page-item.prev {
      margin-right: 15px; }
      .pagination .page-item.prev a {
        padding-left: 60px; }
      .pagination .page-item.prev .arrow {
        left: 0;
        transform: rotate(180deg); }

.notice {
  margin: 20px 0; }
  .notice__inner {
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #DFF0D8; }
    .notice__inner p {
      margin-bottom: 0;
      font-size: 15px;
      line-height: 140%;
      color: #3F4042; }
  .notice__link {
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #3F4042; }
    .notice__link:hover {
      color: #4F742E; }

.card-product {
  margin-bottom: 80px;
  position: relative; }
  .card-product__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .card-product__inner-tw {
      display: flex;
      justify-content: space-between;
      margin: 45px 0;
      position: relative; }
  .card-product__slider {
    width: 42%;
    display: flex;
    padding-right: 20px;
    position: relative; }
    .card-product__slider-label {
      height: 43px;
      margin-top: 25px;
      position: relative; }
    .card-product__slider-nav {
      width: 78px; }
      .card-product__slider-nav-item {
        cursor: pointer;
        padding: 0 0 8px; }
        .card-product__slider-nav-item-img {
          width: 77px;
          height: 77px;
          position: relative;
          border: 1px solid #EBEBEB;
          overflow: hidden;
          border-radius: 8px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat; }
          .card-product__slider-nav-item-img:after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            opacity: 0;
            background: rgba(130, 163, 67, 0.2); }
      .card-product__slider-nav .slick-current .card-product__slider-nav-item-img {
        border-color: #82a343; }
        .card-product__slider-nav .slick-current .card-product__slider-nav-item-img:after {
          opacity: 1; }
    .card-product__slider-for {
      width: calc(100% - 78px);
      margin-left: 30px; }
      .card-product__slider-for-item {
        display: block;
        width: 428px;
        height: 428px;
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid #EBEBEB;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
      .card-product__slider-for .slick-arrow {
        background: url(../images/svg/arrow6.svg);
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.8; }
        .card-product__slider-for .slick-arrow:hover {
          opacity: 1; }
  .card-product__description {
    width: 360px; }
    .card-product__description .catalog__item-amount {
      display: none;
      width: 120px;
      height: 37px;
      margin-right: 15px;
      margin-bottom: 10px; }
      @media screen and (min-width: 768px) {
        .card-product__description .catalog__item-amount {
          width: 147px;
          height: 41px;
          position: relative;
          top: 2px; }
          .card-product__description .catalog__item-amount input {
            height: 41px; } }
    .card-product__description h1 {
      margin-bottom: 23px;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      color: #000000;
      font-family: "ProximaNova", sans-serif; }
    .card-product__description p {
      margin-bottom: 30px;
      font-family: "ProximaNova", sans-serif;
      color: #3F4042;
      font-size: 16px;
      line-height: 21px; }
    .card-product__description-top {
      display: flex;
      align-items: center;
      margin-bottom: 25px; }
      .card-product__description-top-item {
        position: relative;
        font-size: 14px;
        line-height: 16px;
        color: #3F4042; }
        .card-product__description-top-item + .card-product__description-top-item {
          padding-left: 30px; }
          .card-product__description-top-item + .card-product__description-top-item:before {
            content: '';
            width: 2px;
            height: 10px;
            display: inline-block;
            position: absolute;
            left: 15px;
            top: 3px;
            background: #9A9494; }
    .card-product__description-points {
      margin-bottom: 10px;
      padding-left: 25px;
      font-size: 14px;
      line-height: 16px;
      color: #3F4042;
      background-image: url("../images/svg/check.svg");
      background-size: 15px 15px;
      background-repeat: no-repeat;
      background-position: left center; }
    .card-product__description-info {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .card-product__description-numb {
      font-size: 16px;
      line-height: 140%;
      color: #333333; }
    .card-product__description-btns {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px; }
      .card-product__description-btns .button {
        display: inline-block;
        vertical-align: top;
        padding: 9px 33px;
        margin-right: 8px;
        margin-bottom: 10px; }
        .card-product__description-btns .button-all {
          padding: 5px 33px; }
        @media screen and (min-width: 768px) {
          .card-product__description-btns .button-all {
            padding: 7px 25px; } }
      .card-product__description-btns.am .button-primary {
        display: none; }
      .card-product__description-btns.am .catalog__item-amount {
        display: inline-flex;
        position: relative;
        top: 0; }
    .card-product__description-price {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px; }
      .card-product__description-price-old {
        font-size: 18px;
        line-height: 130%;
        color: #9A9494;
        text-decoration: line-through; }
      .card-product__description-price-now {
        margin-right: 20px;
        font-weight: 700;
        font-size: 26px;
        line-height: 130%;
        color: #333333; }
    .card-product__description-link {
      display: flex;
      align-items: center;
      padding: 15px;
      font-size: 16px;
      line-height: 22px;
      color: #4F742E;
      background-color: #ffffff;
      box-shadow: 3px 4px 14px rgba(67, 26, 26, 0.08);
      border-radius: 10px;
      background-image: url("../images/svg/arrow2.svg");
      background-size: 7px 9px;
      background-position: 95% center;
      background-repeat: no-repeat; }
      .card-product__description-link span {
        padding-left: 20px; }
  .card-product__all {
    width: 195px;
    padding-left: 20px; }
    .card-product__all.lnk {
      position: relative; }
      .card-product__all.lnk a {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2; }
    .card-product__all-logo {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      border-radius: 14px;
      border: 1px solid #EBEBEB;
      text-align: center; }
      .card-product__all-logo img {
        max-width: 100%; }
    .card-product__all-reviews {
      padding: 15px;
      margin-bottom: 20px;
      height: 90px;
      border-radius: 14px;
      border: 1px solid #EBEBEB;
      text-align: center;
      position: relative; }
      .card-product__all-reviews-rating {
        margin-bottom: 5px; }
        .card-product__all-reviews-rating-now {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          font-weight: 500;
          font-size: 20px;
          line-height: 130%;
          color: #333333; }
        .card-product__all-reviews-rating-all {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          line-height: 16px;
          color: #3F4042; }
      .card-product__all-reviews-list-item {
        display: inline-block;
        vertical-align: middle; }
        .card-product__all-reviews-list-item-act {
          display: inline-block;
          vertical-align: middle; }
  .card-product__left {
    width: 100%; }
  .card-product__right {
    width: 303px; }
  .card-product__tabs .nav-tabs {
    position: relative; }
    .card-product__tabs .nav-tabs:after {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #EBEBEB; }
  .card-product__tabs-holder {
    display: flex;
    justify-content: space-between; }
  .card-product__tabs-inner {
    padding-right: 120px;
    width: calc(100% - 303px); }
  .card-product__tabs-text {
    margin-bottom: 30px; }
    .card-product__tabs-text-mob-btn {
      display: none; }
    .card-product__tabs-text p {
      font-size: 16px;
      line-height: 175%;
      color: #515461; }
    .card-product__tabs-text-title {
      margin-top: 35px;
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }
    .card-product__tabs-text ol {
      padding-left: 25px; }
      .card-product__tabs-text ol li {
        font-size: 16px;
        line-height: 200%;
        color: #515461; }
  .card-product__tabs-label-item {
    display: inline-flex;
    align-items: center;
    min-height: 47px;
    padding-left: 55px;
    margin-right: 45px;
    margin-bottom: 10px;
    position: relative;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    color: #3F4042;
    letter-spacing: 0.07em;
    text-transform: uppercase; }
    .card-product__tabs-label-item img {
      position: absolute;
      top: 0;
      left: 0; }
  .card-product__tabs-storage {
    margin-bottom: 35px; }
    .card-product__tabs-storage-title {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 19px;
      color: #3F4042; }
    .card-product__tabs-storage p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 23px;
      color: #515461; }
  .card-product__tabs-table {
    width: 60%;
    margin-bottom: 40px; }
    .card-product__tabs-table-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
      position: relative; }
      .card-product__tabs-table-row:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        border: 1px dashed #DADADA; }
    .card-product__tabs-table-cell {
      position: relative;
      z-index: 2;
      font-size: 16px;
      line-height: 19px;
      color: #3F4042;
      background: #ffffff; }
      .card-product__tabs-table-cell-city {
        color: #4F742E; }
  .card-product__other {
    width: 85%;
    margin-top: 80px; }
  .card-product__product-row {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #EBEBEB; }
  .card-product__product-descr {
    padding: 14px 0;
    min-height: 84px;
    padding-left: 122px;
    position: relative; }
  .card-product__product-btn .button {
    padding: 11px 36px; }
  .card-product__product-btn-main {
    display: block;
    width: 100%;
    padding: 16px;
    background: #F5F4F4;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    color: #3F4042;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    text-align: center; }
    .open .card-product__product-btn-main {
      display: none; }
  .card-product__product-img {
    width: 84px;
    height: 84px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #EBEBEB;
    border-radius: 80px;
    overflow: hidden; }
    .card-product__product-img img {
      max-width: 100%; }
  .card-product__product-title {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #000000; }
    .card-product__product-title:hover {
      color: #4F742E; }
  .card-product__product-firm {
    font-size: 14px;
    line-height: 19px;
    color: #3F4042; }
  .card-product__product-price-now {
    color: #4F742E;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px; }
  .card-product__product-price-old {
    font-size: 12px;
    line-height: 14px;
    text-decoration: line-through;
    color: #9A9494; }
  .card-product__deliv {
    display: flex;
    align-items: center;
    padding: 17px 24px;
    border-radius: 15px;
    border: 1px solid #EBEBEB;
    text-align: center; }
    .card-product__deliv-tx {
      font-size: 14px;
      line-height: 19px;
      color: #3F4042; }
    .card-product__deliv-icon {
      margin-left: 13px;
      position: relative; }
      .card-product__deliv-icon-inf {
        width: 224px;
        display: none;
        padding: 10px 15px;
        position: absolute;
        bottom: 36px;
        right: -31px;
        font-size: 16px;
        line-height: 21px;
        color: #3F4042;
        background: #ffffff;
        border-radius: 15px;
        box-shadow: 3px 4px 14px rgba(67, 26, 26, 0.08); }
        .card-product__deliv-icon-inf:after {
          content: '';
          width: 21px;
          height: 21px;
          display: block;
          position: absolute;
          right: 30px;
          bottom: -10px;
          background-image: url("../images/svg/ar1.svg");
          background-repeat: no-repeat; }
      .card-product__deliv-icon:hover img ~ .card-product__deliv-icon-inf {
        display: block; }
  .card-product__bann {
    padding: 30px 50px 45px;
    margin-bottom: 20px;
    border-radius: 15px;
    border: 1px solid #EBEBEB;
    text-align: center; }
    .card-product__bann-icon {
      margin-bottom: 27px; }
    .card-product__bann-tx {
      margin-bottom: 32px;
      font-weight: 500;
      font-size: 18px;
      line-height: 139%;
      color: #333850; }
  .card-product__reviews-none-title {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 19px;
    color: #3F4042; }
  .card-product__reviews-none p {
    font-size: 16px;
    line-height: 23px;
    color: #515461; }
  .card-product__reviews-btn {
    display: block;
    width: 100%;
    padding: 16px;
    background: #F5F4F4;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    color: #3F4042;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    text-align: center; }
    .open .card-product__reviews-btn {
      display: none; }
  .card-product__reviews-info {
    width: 250px; }
  .card-product__reviews-item {
    display: flex;
    padding: 26px 40px;
    margin-bottom: 13px;
    border-radius: 15px;
    border: 1px solid #EBEBEB; }
  .card-product__reviews-title {
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #000000; }
  .card-product__reviews-data {
    font-size: 14px;
    line-height: 16px;
    color: #9A9494; }
  .card-product__reviews-rating {
    margin-bottom: 10px; }
    .card-product__reviews-rating-item {
      display: inline-block;
      vertical-align: middle; }
      .card-product__reviews-rating-item img {
        width: 14px; }
  .card-product__reviews-description {
    width: calc(100% - 250px);
    padding-left: 25px; }
    .card-product__reviews-description p {
      font-size: 14px;
      line-height: 161.5%;
      color: #515461; }
  .card-product__reviews-list-img {
    width: 79px;
    height: 77px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-right: 9px;
    border: 1px solid #ECEEF3;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden; }
    .card-product__reviews-list-img span {
      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #4F742E; }
    .card-product__reviews-list-img-all {
      border-color: #7ABC2F;
      position: relative;
      opacity: 0.9;
      cursor: pointer; }
      .card-product__reviews-list-img-all:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background: rgba(130, 163, 67, 0.2); }
  .card-product__top-mob {
    display: none; }
  .card-product__shop {
    display: block;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    border: 1px solid #EBEBEB;
    border-radius: 15px; }
    .card-product__shop:hover .card-product__shop-title {
      color: #4F742E; }
    .card-product__shop-img {
      width: 75px;
      height: 75px;
      margin-right: 20px; }
      .card-product__shop-img img {
        max-width: 100%;
        max-height: 75px; }
    .card-product__shop-tx {
      width: calc(100% - 75px); }
    .card-product__shop-tt {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 16px;
      color: #9A9494; }
    .card-product__shop-title {
      transition: 0.3s;
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }

.nav-tabs {
  margin-bottom: 40px;
  border: none; }

.nav-link {
  padding: 33px 20px;
  margin-right: 10px;
  position: relative;
  font-weight: 500;
  font-size: 13px;
  line-height: 121.03%;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #9A9494;
  border: none !important;
  background: transparent !important;
  border-radius: 0 !important; }
  .nav-link:after {
    content: '';
    height: 4px;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: #3F4042;
    opacity: 0;
    transition: 0.3s; }
  .nav-link.active:after {
    opacity: 1; }
  .nav-link:hover {
    color: #3F4042; }

.cart-banner {
  margin-bottom: 60px; }
  .cart-banner-tw {
    padding-left: 22px; }
  .cart-banner__inner {
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: #DFF0D8; }
    .cart-banner-tw .cart-banner__inner {
      border-radius: 8px;
      overflow: hidden; }
  .cart-banner__tx {
    width: 100%;
    padding-left: 30px; }
    .cart-banner-tw .cart-banner__tx {
      padding-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .cart-banner__tx p {
      margin-bottom: 0;
      font-size: 15px;
      line-height: 140%;
      color: #333333; }
      .cart-banner__tx p a {
        font-weight: 500;
        color: #4F742E;
        text-decoration: underline; }
        .cart-banner__tx p a:hover {
          color: #7ABC2F; }
  .cart-banner__title {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #000000; }
  .cart-banner__label {
    display: inline-block;
    vertical-align: middle;
    padding: 3px 8px;
    position: relative;
    top: -2px;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    background: #4F742E;
    border-radius: 8px; }
  .cart-banner__btn {
    display: block;
    font-size: 14px;
    line-height: 19px;
    color: #3F4042; }
    .cart-banner__btn-tw {
      font-weight: 500;
      color: #4F742E;
      text-decoration: underline; }
      .cart-banner__btn-tw:hover {
        color: #7ABC2F; }
    .cart-banner__btn img {
      margin-left: 20px; }
    .cart-banner__btn:hover {
      color: #4F742E; }

.cart__list h3 {
  margin-top: 40px; }

.cart__list-promo {
  height: 40px;
  width: 74%;
  position: relative; }
  .cart__list-promo input {
    width: 100%;
    height: 40px;
    padding: 5px 30px;
    border-radius: 3px;
    border: 1px solid #EBEBEB;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
    background: transparent; }
  .cart__list-promo-btn {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 3px;
    background: #4F742E;
    border: none;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s; }
    .cart__list-promo-btn:hover {
      background: #7ABC2F; }
  .cart__list-promo-done {
    padding-left: 20px;
    margin-top: 10px;
    color: #4F742E;
    font-size: 12px;
    line-height: 14px;
    background-image: url("../images/svg/check3.svg");
    background-repeat: no-repeat;
    background-size: 11px 11px;
    background-position: center left; }

.cart__list-all {
  display: flex;
  align-items: center;
  margin: 25px 0;
  padding: 14px 29px 45px;
  border-radius: 8px; }
  .cart__list-all-price, .cart__list-all-btn {
    max-width: 19.333%;
    flex: 0 0 19.333%; }
  .cart__list-all-btn {
    text-align: right; }
  .cart__list-all-title {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 19px;
    color: #3F4042; }
  .cart__list-all-tx {
    max-width: 19.333%;
    flex: 0 0 19.333%; }
    .cart__list-all-tx-title {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }
    .cart__list-all-tx-text {
      font-size: 14px;
      line-height: 19px;
      color: #3F4042; }

.cart__list-item {
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #EBEBEB; }
  .cart__list-item:last-of-type {
    border-bottom: 1px solid #EBEBEB; }
  .order .cart__list-item:first-of-type {
    border-top: none; }
  .order .cart__list-item:last-of-type {
    border-bottom: none; }

.cart__list-descr {
  max-width: 42%;
  flex: 0 0 42%;
  min-height: 93px;
  padding-top: 15px;
  padding-left: 130px;
  position: relative; }
  .cart__list-all .cart__list-descr {
    padding-left: 0;
    padding-top: 0; }
  .order .cart__list-descr {
    max-width: 71%;
    flex: 0 0 71%; }

.cart__list-img {
  width: 93px;
  height: 93px;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #ECEEF3;
  border-radius: 8px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.cart__list-title {
  display: block;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #000000; }
  .cart__list-title:hover {
    color: #4F742E; }

.cart__list-article {
  font-size: 14px;
  line-height: 19px;
  color: #3F4042; }

.cart__list-numb {
  max-width: 14.5%;
  flex: 0 0 14.5%; }
  .cart__list-numb.last {
    text-align: right; }
  .cart__list-numb-tx {
    font-size: 14px;
    line-height: 19px;
    color: #3F4042; }

.cart__list-price {
  margin-bottom: 7px; }
  .cart__list-price-now {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    color: #4F742E;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px; }
  .cart__list-price-old {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    line-height: 14px;
    text-decoration: line-through;
    color: #9A9494; }

.cart__list-delete {
  max-width: 14.5%;
  flex: 0 0 14.5%;
  text-align: right; }
  .cart__list-delete svg {
    width: 18px;
    height: 20px; }
    .cart__list-delete svg use {
      color: #9A9494; }

.cart__list-amount {
  max-width: 14.5%;
  flex: 0 0 14.5%; }
  .cart__list-amount input {
    background: transparent !important; }

.tx__inner {
  padding: 95px 100px;
  margin-bottom: 60px;
  display: flex;
  align-items: center; }

.tx__img {
  display: flex;
  align-items: center; }

.tx__text {
  padding-left: 20px; }
  .tx__text svg {
    width: 19px;
    height: 16px; }
    .tx__text svg use {
      color: #7ABC2F; }
  .tx__text p {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 175%;
    color: #515461; }

.tx__title {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000; }
  .tx__title.big {
    font-weight: 500;
    font-size: 100px;
    line-height: 121px;
    color: #000000;
    font-family: "ProximaNova", sans-serif; }

.order {
  margin-bottom: 70px; }
  .order .polzunok-container-5 {
    margin: 85px 0 28px;
    box-sizing: border-box;
    outline: none; }
    .order .polzunok-container-5:after {
      content: "";
      clear: both;
      display: table; }
  .order .ui-slider {
    height: 3px;
    margin-top: 25px;
    position: relative;
    background: #EBEBEB;
    border-radius: 10px; }
    .order .ui-slider-range {
      height: 3px;
      position: absolute;
      z-index: 1;
      border: none;
      background: #7ABC2F; }
      .order .ui-slider-range:before {
        content: '';
        width: 19px;
        height: 19px;
        display: block;
        position: absolute;
        top: -7px;
        right: -7px;
        border-radius: 100px;
        background: #ffffff;
        box-shadow: 0px 1px 6px #ECECEC;
        cursor: pointer; }
      .order .ui-slider-range:after {
        content: '';
        width: 7px;
        height: 7px;
        display: block;
        position: absolute;
        top: -1px;
        right: -1px;
        z-index: 1;
        border-radius: 100px;
        background: #7ABC2F;
        cursor: pointer; }
    .order .ui-slider-handle {
      width: 19px;
      height: 19px;
      display: none;
      border-radius: 100px;
      background: #ffffff;
      box-shadow: 0px 1px 6px #ECECEC; }
      .order .ui-slider-handle:focus {
        outline: none; }
  .order .polzunok-input-5-left {
    width: 100%;
    display: inline-block;
    height: 58px;
    padding: 26px 10px 10px 18px;
    font-size: 16px;
    line-height: 26px;
    background: #ffffff;
    border: 1px solid #D3D3D3;
    border-bottom: 2px solid #D3D3D3;
    border-radius: 0;
    color: #323436; }
  .order__inner {
    display: flex;
    flex-wrap: wrap; }
  .order__left {
    width: calc(100% - 317px);
    padding-right: 140px; }
  .order__right {
    width: 317px; }
  .order__wrap {
    padding: 20px 25px;
    margin-bottom: 35px;
    border: 1px solid #EBEBEB;
    border-radius: 15px;
    overflow: hidden; }
    .order__wrap-top {
      margin: -20px -25px 25px;
      padding: 23px 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #FAFAFA; }
    .order__wrap-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }
    .order__wrap-list-item {
      display: inline-block;
      vertical-align: middle;
      padding-right: 20px;
      position: relative;
      font-size: 14px;
      line-height: 16px;
      color: #3F4042; }
      .order__wrap-list-item.act {
        color: #4F742E; }
      .order__wrap-list-item:after {
        content: '';
        width: 3px;
        height: 3px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
        border-radius: 100px;
        background: #3F4042; }
      .order__wrap-list-item:last-of-type {
        padding-right: 0; }
        .order__wrap-list-item:last-of-type:after {
          display: none; }
  .order__input-cell {
    margin-bottom: 30px;
    position: relative; }
    .order__input-cell input {
      width: 100%;
      height: 58px;
      padding: 12px 18px 0 18px;
      border-radius: 3px 3px 0 0;
      border: 1px solid #EBEBEB;
      border-bottom: 2px solid #D3D3D3; }
      .order__input-cell input.form__input-effect ~ label, .order__input-cell input.form__textarea-effect ~ label {
        position: absolute;
        left: 18px;
        top: 17px;
        width: 100%;
        transition: 0.3s;
        z-index: 10;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        line-height: 19px;
        color: #3F4042;
        cursor: text !important; }
        .order__input-cell input.form__input-effect ~ label:hover, .order__input-cell input.form__textarea-effect ~ label:hover {
          cursor: text; }
      .order__input-cell input.form__input-effect:focus ~ label,
      .order__input-cell input.form__input-effect.has-content ~ label, .order__input-cell input.form__textarea-effect:focus ~ label,
      .order__input-cell input.form__textarea-effect.has-content ~ label {
        bottom: inherit;
        top: 4px;
        transition: 0.3s;
        font-size: 13px;
        line-height: 18px;
        color: #515461; }
    .order__input-cell-error input {
      border-bottom-color: #B16E6E; }
  .order__payment-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 25px; }
    .order__payment-list.fl {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .order__delivery .order__payment-list {
      margin-left: 0;
      margin-right: 0; }
    .order__payment-list-change {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      color: #3F4042; }
      .order__payment-list-change-tx {
        padding-right: 20px;
        font-size: 16px;
        line-height: 19px;
        color: #333333; }
      .order__payment-list-change svg {
        width: 16px;
        height: 16px;
        margin-right: 5px; }
        .order__payment-list-change svg use {
          color: #3F4042; }
      .order__payment-list-change:hover {
        color: #7ABC2F; }
        .order__payment-list-change:hover svg use {
          color: #7ABC2F; }
    .order__payment-list-item {
      position: relative; }
      .order__delivery .order__payment-list-item {
        margin-bottom: 7px; }
      .order__payment-list-item-in {
        width: 33.333%;
        max-width: 33.333%;
        padding: 0 15px; }
        .order__delivery .order__payment-list-item-in {
          width: 100%;
          max-width: 100%;
          padding: 0;
          margin-bottom: 16px; }
      .order__payment-list-item input {
        width: 100%; }
        .order__payment-list-item input[type=checkbox], .order__payment-list-item input[type=radio] {
          display: none; }
          .order__payment-list-item input[type=checkbox] + label, .order__payment-list-item input[type=radio] + label {
            width: 100%;
            padding: 13px 10px 13px 47px;
            margin-bottom: 0;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
            border-radius: 3px;
            border: 1px solid #EBEBEB;
            cursor: pointer; }
            .order__payment-list-item input[type=checkbox] + label:before, .order__payment-list-item input[type=radio] + label:before {
              content: "";
              width: 19px;
              height: 19px;
              display: inline-block;
              margin: -5px auto 0 auto;
              position: absolute;
              top: 20px;
              bottom: 0;
              left: 20px;
              background-color: #ffffff;
              box-shadow: 0px 1px 6px #ECEEF3;
              text-align: center;
              vertical-align: middle;
              transition: color ease .3s;
              border-radius: 100px; }
            .order__payment-list-item input[type=checkbox] + label:after, .order__payment-list-item input[type=radio] + label:after {
              content: '';
              width: 7px;
              height: 7px;
              display: block;
              opacity: 0;
              position: absolute;
              top: 21px;
              left: 26px;
              z-index: 1;
              border-radius: 100px;
              background: #7ABC2F; }
        .order__payment-list-item input:checked + label {
          border-color: #7ABC2F; }
          .order__payment-list-item input:checked + label:after {
            opacity: 1; }
    .order__payment-list-tx {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 19px;
      color: #3F4042; }
  .order__point-check {
    position: relative;
    margin-bottom: 10px; }
    .order__point-check input[type=checkbox] {
      display: none; }
      .order__point-check input[type=checkbox] + label {
        width: 100%;
        padding-left: 24px;
        font-size: 14px;
        line-height: 16px;
        color: #333333;
        cursor: pointer; }
        .order__point-check input[type=checkbox] + label:before {
          content: "";
          width: 16px;
          height: 16px;
          display: inline-block;
          margin: -5px 5px 0 0;
          position: absolute;
          top: 10px;
          left: 0;
          background-color: #EBEBEB;
          border-radius: 3px;
          transition: color ease .3s; }
      .order__point-check input[type=checkbox]:checked + label:before {
        background-repeat: no-repeat;
        background-image: url("../images/svg/check2.svg");
        background-size: 10px 8px;
        background-position: center;
        background-color: #7ABC2F; }
  .order__range {
    width: 280px; }
    .order__range-item {
      position: relative; }
      .order__range-item label {
        position: absolute;
        top: 5px;
        left: 18px;
        font-size: 13px;
        line-height: 18px;
        color: #515461; }
  .order__list {
    padding: 25px;
    border: 1px solid #EBEBEB;
    border-radius: 15px; }
    .order__list-title {
      margin-bottom: 22px;
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      color: #000000; }
    .order__list-all.prom {
      margin-bottom: 0; }
    .order__list-all + .order__list-all {
      margin-top: 4px; }
    .order__list-all-item {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }
      .prom .order__list-all-item {
        font-size: 14px;
        line-height: 16px;
        color: #4F742E; }
    .order__list-descr {
      margin-top: 10px;
      font-size: 12px;
      line-height: 15px;
      color: #9A9494; }
    .order__list-table {
      margin: 0 -5px 15px; }
      .order__list-table-tw {
        padding-bottom: 11px;
        border-bottom: 1px solid #EBEBEB; }
        .order__list-table-tw .order__list-table-row:after {
          display: none; }
      .order__list-table-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative; }
        .order__list-table-row:after {
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          position: absolute;
          bottom: 3px;
          left: 0;
          z-index: 1;
          border-bottom: 1px dashed #EBEBEB; }
      .order__list-table-item {
        padding: 7px 5px;
        position: relative;
        z-index: 3;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        background: #ffffff; }
        .order__list-table-item a {
          font-weight: 500;
          font-size: 15px;
          line-height: 140%;
          color: #4F742E;
          text-decoration: underline; }
          .order__list-table-item a:hover {
            color: #7ABC2F; }
    .order__list-promo {
      margin-bottom: 25px; }
      .order__list-promo .cart__list-promo {
        width: 100%; }
    .order__list-all {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 25px 0 20px; }

.form__input-error {
  display: block;
  margin-top: 5px;
  padding-left: 18px;
  font-size: 13px;
  line-height: 15px;
  color: #B16E6E; }

.check-in {
  margin: 70px 0 120px; }
  .check-in__inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .check-in__img {
    width: calc(100% - 465px);
    text-align: center; }
    .check-in__img img {
      max-width: 100%; }
  .check-in__tx {
    width: 465px; }
    .check-in__tx h1 {
      margin-bottom: 50px; }
    .check-in__tx p {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 19px;
      color: #3F4042; }
      .check-in__tx p a {
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        text-decoration: underline;
        color: #4F742E; }
        .check-in__tx p a:hover {
          color: #7ABC2F; }
  .check-in__input .order__input-cell {
    margin-bottom: 20px; }
  .check-in__soc {
    position: relative; }
    .check-in__soc:before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      margin: 42px 0 27px;
      background: #D3D3D3; }
    .check-in__soc:after {
      content: 'или';
      display: block;
      width: 45px;
      padding: 5px 10px;
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      margin: auto;
      background: #ffffff;
      font-size: 14px;
      line-height: 19px;
      color: #9A9494; }
    .check-in__soc-item {
      display: block;
      padding: 14px 30px;
      margin-bottom: 10px;
      position: relative;
      border-radius: 3px;
      border: 1px solid #EBEBEB;
      font-size: 14px;
      line-height: 19px;
      color: #3F4042;
      text-align: center; }
      .check-in__soc-item img {
        height: 22px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
        margin: auto; }
      .check-in__soc-item:hover {
        color: #4F742E;
        border-color: #4F742E; }

.lk {
  margin-bottom: 100px; }
  .lk__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
  .lk__left {
    width: calc(100% - 317px);
    padding-right: 133px; }
  .lk__group .col-lg-4 {
    margin-bottom: 20px; }
  .lk__group-item {
    padding: 30px;
    display: block;
    margin-bottom: 20px;
    position: relative;
    background: #ffffff;
    box-shadow: 1px 4px 13px rgba(173, 168, 154, 0.12);
    border-radius: 5px;
    transition: 0.3s;
    overflow: hidden; }
    .lk__group-item a {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
    .lk__group-item-img {
      margin-bottom: 35px; }
    .lk__group-item-tx {
      font-size: 16px;
      line-height: 21px;
      color: #3F4042; }
    .lk__group-item-title {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }
    .lk__group-item-label {
      display: inline-block;
      padding: 3px 10px;
      position: absolute;
      right: 10px;
      top: 11px;
      z-index: 1;
      font-size: 13px;
      line-height: 16px;
      color: #ffffff;
      background: #7ABC2F;
      border-radius: 10px; }
    .lk__group-item:hover {
      box-shadow: 1px 4px 13px rgba(173, 168, 154, 0.32); }
      .lk__group-item:hover .lk__group-item-title {
        color: #4F742E; }
  .lk__menu {
    width: 317px; }
    .lk__menu-inner {
      padding: 0 27px;
      border: 1px solid #EBEBEB;
      border-radius: 15px;
      background: #ffffff; }
    .lk__menu ul {
      margin-bottom: 0; }
      .lk__menu ul li {
        border-bottom: 1px solid #EBEBEB; }
        .lk__menu ul li a {
          display: flex;
          align-items: center;
          padding: 19px 0;
          font-weight: 500;
          font-size: 13px;
          line-height: 121.03%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: #3F4042; }
          .lk__menu ul li a svg {
            width: 16px;
            height: 16px;
            margin-right: 5px; }
            .lk__menu ul li a svg use {
              color: #3F4042; }
          .lk__menu ul li a:hover {
            color: #4F742E; }
            .lk__menu ul li a:hover svg use {
              color: #4F742E; }
        .lk__menu ul li.active a {
          color: #4F742E; }
          .lk__menu ul li.active a svg use {
            color: #4F742E; }
        .lk__menu ul li:last-of-type {
          border-bottom: none; }
  .lk__setting .button-group {
    margin-top: 30px; }
  .lk__setting p {
    font-size: 16px;
    line-height: 19px;
    color: #333333; }
  .lk__setting-form {
    width: 400px;
    margin-top: 45px;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #333333; }
    .lk__setting-form-title {
      margin-bottom: 25px; }
    .lk__setting-form-message {
      width: 423px;
      padding: 13px 25px;
      position: absolute;
      right: -450px;
      top: 0;
      border-radius: 3px;
      background: #DFF0D8;
      font-size: 13px;
      line-height: 137.9%;
      color: #333333;
      background: url("../images/svg/bg.svg");
      background-size: cover;
      background-position: center left; }
    .lk__setting-form-btn {
      width: 40px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 8px;
      z-index: 11;
      border-radius: 3px;
      background: #4F742E;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      cursor: pointer;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .lk__setting-form-btn:hover {
        background: #7ABC2F; }
    .lk__setting-form-edit {
      width: 18px;
      height: 26px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 15px;
      bottom: 0;
      margin: auto; }
      .lk__setting-form-edit:hover {
        opacity: 0.7; }
    .lk__setting-form-cell {
      margin-bottom: 25px;
      position: relative; }
      .lk__setting-form-cell input {
        width: 100%;
        height: 58px;
        padding: 12px 18px 0 18px;
        border-radius: 3px 3px 0 0;
        border: 1px solid #EBEBEB;
        border-bottom: 2px solid #D3D3D3; }
        .lk__setting-form-cell input.form__input-effect ~ label, .lk__setting-form-cell input.form__textarea-effect ~ label {
          position: absolute;
          left: 18px;
          top: 17px;
          width: 100%;
          transition: 0.3s;
          z-index: 10;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 16px;
          line-height: 19px;
          color: #3F4042;
          cursor: text !important;
          font-weight: 400; }
          .lk__setting-form-cell input.form__input-effect ~ label:hover, .lk__setting-form-cell input.form__textarea-effect ~ label:hover {
            cursor: text; }
        .lk__setting-form-cell input.form__input-effect:focus ~ label,
        .lk__setting-form-cell input.form__input-effect.has-content ~ label, .lk__setting-form-cell input.form__textarea-effect:focus ~ label,
        .lk__setting-form-cell input.form__textarea-effect.has-content ~ label {
          bottom: inherit;
          top: 4px;
          transition: 0.3s;
          font-size: 13px;
          line-height: 18px;
          color: #515461; }

.orders {
  margin-bottom: 80px; }
  .orders__prod {
    display: none; }
    .open .orders__prod {
      display: block; }
    .orders__prod .catalog__item-list {
      padding: 20px 30px;
      border: none;
      border-bottom: 1px solid #ECECEC; }
      .orders__prod .catalog__item-list:last-of-type {
        border: none; }
      .orders__prod .catalog__item-list:hover {
        border-color: #ECECEC; }
  .orders__form {
    width: 65%; }
    .orders__form-item {
      display: flex;
      align-items: center;
      margin-bottom: 18px; }
      .orders__form-item-bt {
        align-items: flex-start; }
    .orders__form-title {
      width: 200px;
      padding-right: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #323436; }
    .orders__form-inp {
      width: calc(100% - 200px); }
      .orders__form-inp input {
        width: 100%;
        height: 55px;
        padding: 5px 20px;
        font-size: 16px;
        margin-bottom: 0;
        border: 1px solid #D3D3D3; }
      .orders__form-inp p {
        margin-top: 15px;
        font-size: 13px;
        line-height: 140%;
        color: #CBCBCB; }
    .orders__form-btns .button {
      margin-bottom: 10px;
      min-width: 230px;
      display: inline-block;
      margin-right: 20px;
      font-weight: 700;
      font-size: 12px;
      line-height: 125%;
      text-align: center; }
  .orders__inner {
    display: flex; }
  .orders__left {
    width: calc(100% - 240px);
    padding-right: 50px; }
  .orders__item {
    padding: 20px 25px;
    margin-bottom: 20px;
    border: 1px solid #EAEAEA;
    border-radius: 12px; }
    .orders__item + .button-center {
      margin-top: 30px; }
    .orders__item-product {
      padding: 30px 0 0; }
      .orders__item-product-btn {
        display: block;
        padding: 17px 10px;
        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        color: #3F4042;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        background: #F5F4F4;
        text-align: center;
        cursor: pointer; }
        .orders__item-product-btn:hover {
          background: #7ABC2F;
          color: #ffffff; }
        .open .orders__item-product-btn {
          display: none; }
      .orders__item-product-title {
        font-size: 16px;
        line-height: 19px;
        color: #000000; }
      .orders__item-product-row {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #F9F9F9; }
        .orders__item-product-row:last-of-type {
          border-bottom: none; }
      .orders__item-product-left {
        padding-top: 7px;
        min-height: 64px;
        padding-left: 87px;
        position: relative; }
      .orders__item-product-img {
        width: 64px;
        height: 64px;
        border: 1px solid #ECEEF3;
        border-radius: 8px;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; }
      .orders__item-product-name {
        display: block;
        margin-bottom: 10px;
        color: #000000;
        font-size: 14px;
        line-height: 16px; }
        .orders__item-product-name:hover {
          color: #4F742E; }
      .orders__item-product-price {
        font-weight: 500;
        font-size: 16px;
        line-height: 121.03%;
        color: #4F742E; }
      .orders__item-product-rating-title {
        font-size: 13px;
        line-height: 18px;
        color: #333333; }
      .orders__item-product-rating-im-item {
        display: inline-block; }
    .orders__item-bottom {
      display: inline-block;
      padding-left: 28px;
      position: relative;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #323436; }
      .orders__item-bottom img {
        position: absolute;
        left: 0; }
      .orders__item-bottom:hover {
        color: #323436;
        opacity: 0.8; }
    .orders__item-descr {
      padding: 15px 22px 10px;
      background: #FAFAFA; }
      .orders__item-descr .map {
        height: 195px; }
      .orders__item-descr p {
        margin-bottom: 2px;
        font-size: 13px;
        line-height: 18px;
        color: #3F4042; }
      .orders__item-descr-rating .orders__item-descr-title {
        display: inline-block;
        margin-right: 25px; }
      .orders__item-descr-rating-im {
        display: inline-block; }
        .orders__item-descr-rating-im-item {
          display: inline-block; }
      .orders__item-descr-link {
        display: inline-block;
        font-weight: 700;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #323436; }
      .orders__item-descr-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        color: #7C7C7C;
        font-size: 14px;
        line-height: 16px; }
        .orders__item-descr-title a {
          font-weight: 500;
          font-size: 15px;
          line-height: 140%;
          color: #4F742E;
          text-decoration: underline; }
          .orders__item-descr-title a:hover {
            color: #7ABC2F; }
      .orders__item-descr-count-tit {
        display: inline-block;
        margin-right: 5px;
        color: #3F4042;
        font-size: 16px;
        line-height: 19px; }
      .orders__item-descr-count-st {
        display: inline-block;
        padding: 2px 5px;
        font-size: 13px;
        line-height: 16px;
        color: #ffffff;
        background: #FFB800;
        border-radius: 10px; }
        .orders__item-descr-count-st.tw {
          background: #7ABC2F; }
        .orders__item-descr-count-st.th {
          background: #C7E893; }
        .orders__item-descr-count-st.fr {
          background: #FFB800; }
      .orders__item-descr-recipient-tx {
        display: inline-block;
        color: #323436;
        font-size: 16px;
        line-height: 140%; }
    .orders__item-al {
      padding: 20px 25px 20px 65px;
      cursor: pointer; }
      .orders__item-al .orders__title-main {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%; }
  .orders__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative; }
    .orders__top-arrow {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -40px;
      margin: auto; }
      .orders__top-arrow svg {
        width: 18px;
        height: 18px;
        transform: rotate(-90deg); }
        .orders__top-arrow svg use {
          color: #CBCBCB; }
      .open .orders__top-arrow svg {
        transform: rotate(90deg); }
    .orders__item-al .orders__top {
      margin-bottom: 0; }
    .orders__top-big {
      margin-top: 40px;
      align-items: flex-end; }
  .orders__title p {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 140%;
    color: #CBCBCB; }
  .orders__title .orders__status {
    margin-bottom: 10px;
    text-transform: inherit;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%; }
    .orders__title .orders__status-success {
      color: #323436; }
      .orders__title .orders__status-success:before {
        background-color: #323436;
        background-size: 8px 6px; }
  .orders__title-main {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #000000; }
    .orders__item-al .orders__title-main {
      font-weight: 500;
      font-size: 18px;
      line-height: 120%; }
  .orders__title-min {
    font-size: 14px;
    line-height: 16px;
    color: #3F4042; }
    .orders__top-big .orders__title-min {
      font-size: 18px;
      line-height: 120%;
      color: #323436; }
  .orders__status {
    display: flex;
    align-items: center;
    text-align: right;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3F4042; }
    .orders__status:before {
      content: '';
      width: 6px;
      height: 6px;
      display: inline-block;
      margin-right: 7px;
      border-radius: 100px;
      background-repeat: no-repeat;
      background-position: center; }
    .orders__status-success:before {
      background-color: #7ABC2F;
      background-size: 8px 6px; }
    .orders__status-cancel {
      color: #B16E6E; }
      .orders__status-cancel:before {
        background-size: 16px 16px; }
  .orders__right {
    width: 240px; }
  .orders__pages-item {
    display: flex;
    align-items: center;
    min-height: 39px;
    padding: 12px 10px 9px 52px;
    margin-bottom: 5px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #323436;
    border-radius: 4px; }
    .orders__pages-item svg {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20px;
      margin: auto; }
      .orders__pages-item svg use {
        color: #323436; }
    .orders__pages-item.active {
      color: #B16E6E;
      background: #323436;
      border-radius: 7px; }
      .orders__pages-item.active svg use {
        color: #B16E6E; }
    .orders__pages-item:hover {
      color: #B16E6E; }
      .orders__pages-item:hover svg use {
        color: #B16E6E; }

.policy {
  margin-bottom: 100px; }
  .policy__inner h3 {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: #333333; }
  .policy__inner p {
    margin-bottom: 45px;
    font-size: 16px;
    line-height: 154%;
    color: #515461; }
  .policy__inner ul {
    margin-bottom: 40px; }
    .policy__inner ul li {
      margin-bottom: 2px;
      padding-left: 25px;
      position: relative;
      font-size: 16px;
      line-height: 154%;
      color: #515461; }
      .policy__inner ul li:before {
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        position: absolute;
        top: 9px;
        left: 0;
        border-radius: 10px;
        background: #7ABC2F; }

.modal .close {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -75px;
  top: 14px;
  border: 1px solid #EBEBEB;
  border-radius: 100px; }

.modal-content {
  padding: 50px 70px;
  border: none;
  border-radius: 0;
  background: #ffffff; }

.modal-dialog {
  max-width: 600px;
  border: none;
  border-radius: 0; }

.modal-reviews p {
  font-size: 16px;
  line-height: 170%;
  color: #333850; }

.modal-reviews__title {
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 26px;
  line-height: 130%;
  color: #000000; }

.modal-reviews__rating {
  flex-wrap: wrap;
  margin-bottom: 20px;
  display: flex;
  align-items: center; }
  .modal-reviews__rating-numb {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: #333333; }
  .modal-reviews__rating-all {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    line-height: 16px;
    color: #4F742E; }
  .modal-reviews__rating-title {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    line-height: 16px;
    color: #333333; }
  .modal-reviews__rating-list {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle; }
  .modal-reviews__rating-item {
    display: inline-block;
    vertical-align: middle; }

.modal-reviews__inp {
  margin-bottom: 20px;
  position: relative; }
  .modal-reviews__inp textarea {
    width: 100%;
    height: 175px;
    padding: 25px 18px 0 18px;
    border-radius: 3px 3px 0 0;
    border: 1px solid #EBEBEB;
    border-bottom: 2px solid #D3D3D3; }
    .modal-reviews__inp textarea.form__input-effect ~ label, .modal-reviews__inp textarea.form__textarea-effect ~ label {
      position: absolute;
      left: 18px;
      top: 17px;
      width: 100%;
      transition: 0.3s;
      z-index: 10;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      line-height: 19px;
      color: #3F4042;
      cursor: text !important; }
      .modal-reviews__inp textarea.form__input-effect ~ label:hover, .modal-reviews__inp textarea.form__textarea-effect ~ label:hover {
        cursor: text; }
    .modal-reviews__inp textarea.form__input-effect:focus ~ label,
    .modal-reviews__inp textarea.form__input-effect.has-content ~ label, .modal-reviews__inp textarea.form__textarea-effect:focus ~ label,
    .modal-reviews__inp textarea.form__textarea-effect.has-content ~ label {
      bottom: inherit;
      top: 4px;
      transition: 0.3s;
      font-size: 13px;
      line-height: 18px;
      color: #515461; }

.modal-reviews__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px; }

.modal-reviews__upload {
  display: flex;
  align-items: center; }
  .modal-reviews__upload-item {
    width: 83px;
    height: 83px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #EBEBEB; }
    .modal-reviews__upload-item-none {
      border: none; }
    .modal-reviews__upload-item .close-i {
      position: absolute;
      right: 5px;
      top: 0;
      z-index: 3;
      cursor: pointer; }
      .modal-reviews__upload-item .close-i img {
        width: 9px; }
  .modal-reviews__upload-tx {
    padding-left: 30px; }
    .modal-reviews__upload-tx-title {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 140%;
      color: #333333; }
    .modal-reviews__upload-tx-text {
      font-size: 12px;
      line-height: 14px;
      color: #9A9494; }

.point__inner {
  margin-bottom: 80px; }

.point__table {
  width: 81%;
  margin-bottom: 30px; }
  .point__table-row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #EBEBEB; }
  .point__table-cell {
    padding-top: 23px;
    padding-bottom: 23px;
    padding-right: 10px; }
    .point__table-cell-title {
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 14px;
      line-height: 16px;
      color: #7C7C7C; }
    .point__table-cell:first-of-type, .point__table-cell:last-of-type {
      width: 200px; }
    .point__table-cell:last-of-type {
      text-align: right;
      padding-right: 0; }
    .point__table-cell:nth-of-type(2) {
      width: calc(100% - 400px); }

.point__data {
  font-size: 14px;
  line-height: 19px;
  color: #3F4042; }

.point__title {
  font-size: 16px;
  line-height: 19px;
  color: #000000; }

.point__price {
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  color: #4F742E; }
  .point__price:after {
    display: inline-block;
    margin-left: 3px;
    font-family: "PT Sans", sans-serif;
    content: "\20cf";
    font-weight: 700; }

.brands__inner {
  margin-bottom: 80px; }

.brands__list {
  margin-bottom: 5px; }
  .brands__list-item {
    height: 33px;
    padding: 5px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 16px;
    line-height: 19px;
    color: #3F4042; }
    .brands__list-item.not {
      color: #D7D7D7 !important; }
    .brands__list-item.active {
      font-size: 16px;
      line-height: 19px;
      color: #4A7336;
      background: #DFF0D8;
      border-radius: 10px; }
      .brands__list-item.active:hover {
        color: #ffffff !important;
        background: #4F742E; }
    .brands__list-item:hover {
      color: #7ABC2F; }

.brands__all {
  margin-top: 43px; }
  .brands__all-title {
    margin-bottom: 26px;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #000000; }
  .brands__all-item {
    margin-bottom: 18px; }
  .brands__all-inner {
    display: flex;
    flex-wrap: wrap; }
    .brands__all-inner-tw {
      width: 50%; }
  .brands__all-item {
    flex: 0 0 25%;
    max-width: 25%;
    display: inline-block;
    color: #3F4042;
    font-size: 16px;
    line-height: 19px; }
    .brands__all-item span {
      padding-right: 10px; }
    .brands__all-item-link span {
      font-weight: 500;
      font-size: 15px;
      line-height: 140%;
      color: #4F742E;
      text-decoration: underline; }
      .brands__all-item-link span:hover {
        color: #7ABC2F; }
    .brands__all-item span {
      transition: 0.3s; }
    .brands__all-item:hover span {
      color: #7ABC2F; }
    .brands__all-inner-tw .brands__all-item {
      flex: 0 0 50%;
      max-width: 50%; }

.about {
  margin-top: -30px;
  padding-top: 30px;
  background-color: #F6F9F9; }
  .about-text {
    padding: 77px 0;
    margin-bottom: -250px;
    position: relative;
    z-index: 3; }
    .about-text .container {
      position: relative;
      z-index: 4; }
    .about-text:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background-image: url("../images/bg-b1.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
    .about-text h1 {
      margin-bottom: 28px;
      font-weight: 500;
      font-size: 32px;
      line-height: 37px; }
    .about-text__img img {
      max-width: 100%; }
    .about-text__tx p {
      margin-bottom: 45px;
      font-size: 24px;
      line-height: 157.5%;
      color: #3F4042; }
      .about-text__tx p span {
        color: #7ABC2F;
        font-style: italic;
        position: relative; }
        .about-text__tx p span:after {
          content: '';
          width: 100%;
          height: 20px;
          display: block;
          position: absolute;
          bottom: -15px;
          left: 0;
          z-index: 2;
          background-image: url("../images/svg/line.svg");
          background-repeat: no-repeat;
          background-position: 10px center; }
  .about-all {
    padding: 300px 0 150px;
    background-color: #5DAF5B;
    background-image: url("../images/svg/line2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
    .about-all__item p {
      font-size: 18px;
      line-height: 154%;
      color: #000000; }
    .about-all__item-title {
      margin-left: 20px;
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      color: #000000; }
      .about-all__item-title-min {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 154%;
        color: #ffffff; }
        .about-all__item-title-min:before {
          content: '';
          width: 10px;
          height: 12px;
          display: inline-block;
          margin-right: 20px;
          background-image: url("../images/svg/about-icon-l.svg");
          background-repeat: no-repeat;
          background-size: 10px 12px; }
    .about-all__item-top {
      margin-bottom: 70px;
      min-height: 77px;
      display: flex;
      align-items: center; }
  .about-work {
    padding: 115px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
    .about-work h2 {
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      text-align: center; }
    .about-work .col-md-6 {
      margin-bottom: 20px; }
    .about-work__inner {
      position: relative; }
      .about-work__inner:before {
        content: '';
        width: 257px;
        height: 146px;
        display: block;
        position: absolute;
        top: 0;
        right: -30px;
        z-index: 2;
        background-image: url("../images/about-work-img1.png");
        background-repeat: no-repeat; }
      .about-work__inner:after {
        content: '';
        width: 221px;
        height: 89px;
        display: block;
        position: absolute;
        bottom: -39px;
        left: 182px;
        z-index: 2;
        background-image: url("../images/about-work-img2.png");
        background-repeat: no-repeat; }
    .about-work__item {
      padding: 30px;
      height: 100%;
      min-height: 193px;
      border-radius: 10px;
      background: #ffffff;
      border: 1px solid #3F4042; }
      .about-work__item-title {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        color: #000000; }
      .about-work__item-tx {
        font-size: 16px;
        line-height: 154%;
        color: #515461; }

.align-items-flex-end {
  align-items: flex-end; }

.pay-list {
  margin-bottom: 90px; }
  .pay-list__row {
    padding: 20px 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #EBEBEB; }
    .pay-list__row:last-of-type {
      border-bottom: 1px solid #EBEBEB; }
  .pay-list__icon {
    width: 200px; }
  .pay-list__title {
    width: 445px;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #000000; }
  .pay-list__tx {
    width: calc(100% - 645px);
    font-size: 16px;
    line-height: 154%;
    color: #515461; }

.pay-descrip {
  margin-bottom: 120px;
  font-size: 18px;
  line-height: 154%;
  color: #3F4042; }
  .pay-descrip p {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 154%;
    color: #3F4042; }
  .pay-descrip h2 + p {
    margin-top: 20px; }
  .pay-descrip .col-xl-6 {
    margin-bottom: 30px; }
  .pay-descrip__img img {
    max-width: 100%; }
  .pay-descrip__item {
    padding: 30px;
    height: 100%;
    border: 1px solid #3F4042;
    border-radius: 10px;
    background: #ffffff; }
    .pay-descrip__item-all {
      font-size: 16px;
      line-height: 154%;
      color: #515461; }
      .pay-descrip__item-all:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        width: 23px;
        height: 23px;
        background-image: url("../images/svg/inf2.svg");
        background-repeat: no-repeat; }
    .pay-descrip__item-title {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }
    .pay-descrip__item-tx {
      font-size: 16px;
      line-height: 154%;
      color: #515461; }

.alphabet {
  margin-bottom: 85px; }
  .information__alphabet .alphabet {
    margin-top: 50px; }
  .alphabet__list {
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap; }
    .alphabet__list:last-of-type {
      margin-bottom: 0; }
  .alphabet__item {
    display: inline-block;
    vertical-align: middle;
    height: 33px;
    padding: 6px 10px;
    color: #3F4042;
    font-size: 16px;
    line-height: 19px;
    border-radius: 10px;
    text-transform: uppercase; }
    .alphabet__item.not {
      color: #D7D7D7; }
    .alphabet__item.active {
      background: #DFF0D8;
      color: #4A7336;
      text-transform: inherit; }
      .alphabet__item.active:hover {
        background: #DFF0D8;
        color: #4A7336; }
    .alphabet__item:hover {
      color: #4A7336; }
  .alphabet-list {
    margin-top: 40px; }
    .alphabet-list-letters {
      margin-bottom: 22px;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #000000; }
    .alphabet-list-lt {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
      position: relative; }
      .alphabet-list-lt.tw {
        width: 50%; }
    .alphabet-list-item {
      padding: 0 5px;
      margin-bottom: 13px;
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
      font-size: 18px;
      line-height: 24px;
      color: #000000; }
      .alphabet-list-item.link {
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        color: #4F742E;
        text-decoration: underline; }
        .alphabet-list-item.link:hover {
          color: #7ABC2F; }
      .tw .alphabet-list-item {
        flex: 0 0 50%;
        max-width: 50%; }
      .alphabet-list-item:hover {
        color: #468294; }

@media screen and (max-width: 1850px) {
  .header__bottom-info-item {
    margin-left: 15px; } }

@media screen and (max-width: 1700px) {
  .catalog-min .col-lg-2-1 {
    flex: 0 0 25%;
    max-width: 25%; }
    /*.catalog-min .col-lg-2-1:last-of-type {
      display: none; }*/
  .catalog-subscription .col-lg-2-1 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media screen and (max-width: 1600px) {
  .container {
    padding-right: 40px;
    padding-left: 40px; } }

@media screen and (max-width: 1500px) {
  .header__top-menu ul li {
    margin-left: 15px; }
  .header__search {
    width: 40%; }
  .header__city {
    margin-right: 20px; }
  .menu {
    width: 260px;
    padding: 12px 20px; }
    .menu__submenu {
      width: calc(100% - 240px);
      left: 240px; }
  .wrapper {
    padding-left: 260px; }
  .bottom-block__info {
    padding: 50px 0 30px; }
  .lk__left {
    width: calc(100% - 250px);
    padding-right: 30px; }
  .lk__menu {
    width: 250px; }
  .card-product__all {
    width: 160px;
    padding-left: 10px; } }

@media screen and (max-width: 1400px) {
  .card-product__slider-label {
    display: inline-block; }
  .about-text {
    padding: 77px 0 200px; }
  .pay-list__title {
    width: 300px; }
  .pay-list__icon {
    width: 100px; }
  .pay-list__tx {
    width: calc(100% - 400px); }
  .catalog-min .col-lg-2-1 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
    .catalog-min .col-lg-2-1:nth-of-type(4) {
      display: none; }
  .catalog-subscription .col-lg-2-1 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .delivery__order {
    padding: 40px 45px; }
  .card-product__inner {
    flex-wrap: wrap; }
  .card-product__slider {
    width: calc(100% - 520px);
    margin-right: 0; }
  .card-product__all {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    margin-top: 20px; }
    .card-product__all-reviews, .card-product__all-logo {
      margin-right: 20px;
      height: 110px;
      padding: 25px;
      display: inline-block;
      vertical-align: top; }
  .card-product__description {
    width: 500px; }
  .order__left {
    padding-right: 50px; }
  .order__payment-list {
    margin: 0 -5px; }
    .order__payment-list-item label {
      padding: 13px 6px 13px 44px !important; }
    .order__payment-list-item-in {
      padding: 0 5px;
      margin-bottom: 10px; }
  .order__wrap .cart__list-item {
    padding: 20px 0 40px;
    position: relative;
    justify-content: space-between; }
  .order__wrap .cart__list-numb {
    padding-left: 130px;
    max-width: 100%;
    flex: 0 0 100%;
    position: absolute;
    bottom: 20px; }
    .order__wrap .cart__list-numb.last {
      padding-left: 0;
      max-width: 29%;
      flex: 0 0 29%;
      position: relative;
      bottom: 0; } }

@media screen and (max-width: 1300px) {
  .bottom-block__inner {
    display: block; }
  .bottom-block__info {
    width: 100%; }
  .footer__menu li {
    margin-right: 15px; }
  .footer__tel, .footer__mail {
    display: flex;
    justify-content: flex-end;
    margin-left: 15px;
    margin-bottom: 7px; }
  .footer__top-info {
    display: block;
    text-align: right; }
  .card-product__tabs-table {
    width: 100%; }
  .card-product__tabs-inner {
    padding-right: 50px; }
  .card-product__reviews-info {
    width: 160px; }
  .card-product__reviews-description {
    width: calc(100% - 160px);
    padding-left: 10px; }
  .tx__inner {
    padding: 95px 30px; }
  .actions-item__left {
    width: 400px; }
  .actions-item__right {
    width: calc(100% - 400px); } }

@media screen and (max-width: 1250px) {
  .header__top-inner {
    display: block; } }

@media screen and (max-width: 1200px) {
  .about-work__inner:before {
    right: 0;
    top: -25px; }
  .about-all__item-title {
    font-size: 26px;
    line-height: 34px; }
  .container {
    padding-right: 20px;
    padding-left: 20px; }
  .banner__al {
    margin: 0 -15px;
    display: flex;
    align-items: center; }
  .banner__min-in {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 15px; }
  .banner__big {
    margin-bottom: 20px; }
  .subscription-list__item {
    margin-bottom: 20px; }
  .card-product__slider {
    width: 410px; }
    .card-product__slider-for {
      width: 300px; }
  .card-product__description {
    width: calc(100% - 410px);
    padding-left: 20px; }
  .card-product__other {
    width: 100%; }
  .card-product__reviews {
    display: block; }
    .card-product__reviews-item {
      display: block; }
    .card-product__reviews-info {
      width: 100%;
      margin-bottom: 20px; }
    .card-product__reviews-description {
      width: 100%;
      padding-left: 0; }
  .cart__list-numb {
    max-width: 21.75%;
    flex: 0 0 21.75%; }
    .cart__list-numb-tw {
      display: none; }
  .cart__list-amount {
    padding: 0 5px;
    max-width: 21.75%;
    flex: 0 0 21.75%; }
  .order__inner {
    display: block; }
  .order__left, .order__right {
    padding-right: 0;
    width: 100%; }
  .lk__setting-form-message {
    width: 100%;
    margin-top: 10px;
    position: relative;
    left: 0;
    right: 0;
    background-image: none;
    background-color: #DFF0D8;
    border-radius: 3px; }
  .point__table {
    width: 100%; } }

@media screen and (max-width: 1100px) {
  .catalog-min .col-lg-2-1 {
    flex: 0 0 50%;
    max-width: 50%; }
    /*.catalog-min .col-lg-2-1:nth-of-type(3) {
      display: none; }*/ }

@media screen and (max-width: 991px) {
  .card-product__reviews-none p br {
    display: none; }
  .card-product__slider .card-product__slider-label {
    left: 10px; }
  .about-text {
    padding: 20px 0 200px; }
    .about-text__img {
      margin-top: 30px;
      text-align: center; }
  .about-all {
    padding: 150px 0 50px; }
    .about-all__item {
      margin-bottom: 40px; }
      .about-all__item:last-of-type {
        margin-bottom: 0; }
      .about-all__item-top {
        margin-bottom: 30px; }
  .pay-descrip__img {
    text-align: center;
    margin-top: 30px; }
  .header {
    overflow: hidden;
    background: #ffffff; }
    .header__top {
      display: none; }
    .header__toggler {
      width: 28px;
      height: 28px;
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      z-index: 5;
      border-radius: 100px;
      background-color: #FAB70C;
      background-image: url("../images/svg/toggler.svg");
      background-repeat: no-repeat;
      background-size: 11px 11px;
      background-position: center;
      cursor: pointer; }
      .open .header__toggler {
        background-image: url("../images/svg/close2.svg");
        background-size: 15px 15px; }
    .header__search {
      width: 100%;
      margin: 12px 0 0; }
      .header__search input {
        height: 43px;
        font-size: 15px;
        line-height: 121.03%; }
    .header__logo-mob {
      display: block; }
      .header__logo-mob img {
        display: block;
        margin: 0 auto; }
    .header__bottom {
      padding: 14px 0;
      background: #ffffff; }
      .header__bottom-inner {
        display: block;
        position: relative; }
      .header__bottom-info-notif {
        left: 22px;
        top: -3px; }
      .header__bottom-info-item {
        width: 29px;
        height: 29px;
        display: none;
        color: transparent !important; }
        .header__bottom-info-item img {
          display: none; }
        .header__bottom-info-item-cart, .header__bottom-info-item-enter {
          display: block;
          position: absolute;
          top: 0;
          z-index: 5; }
        .header__bottom-info-item-cart {
          right: 10px;
          background-image: url("../images/svg/icon-menu2.svg");
          background-repeat: no-repeat;
          background-size: 29px 29px; }
        .header__bottom-info-item-enter {
          right: 55px;
          background-image: url("../images/svg/icon-menu.svg");
          background-repeat: no-repeat;
          background-size: 29px 29px; }
    .header__menu-mob {
      display: none;
      width: 100%;
      height: calc(100vh - 53px);
      min-height: 100%;
      padding-bottom: 100px;
      position: fixed;
      top: 53px;
      left: 0;
      z-index: 110;
      background: #ffffff;
      border-top: 1px solid #EBEBEB;
      overflow: auto; }
      .open .header__menu-mob {
        display: block; }
    .header__menu-icon {
      display: flex;
      border-bottom: 1px solid #EBEBEB; }
      .header__menu-icon-item {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 20px 10px 20px 20px;
        position: relative; }
        .header__menu-icon-item:first-of-type {
          border-right: 1px solid #EBEBEB; }
      .header__menu-icon-notif {
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20px;
        left: 40px;
        border-radius: 100px;
        background: #4F742E;
        font-weight: 700;
        font-size: 10px;
        line-height: 121.03%;
        text-transform: uppercase;
        color: #ffffff; }
      .header__menu-icon-tit {
        margin-left: 15px;
        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #3F4042; }
    .header__menu-btn-catalog {
      display: block;
      padding: 22px 20px;
      font-weight: 500;
      font-size: 13px;
      line-height: 121.03%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #3F4042;
      background-image: url("../images/svg/arrow2.svg");
      background-repeat: no-repeat;
      background-position: 98% center;
      background-size: 9px 12px;
      border-bottom: 1px solid #EBEBEB; }
    .header__menu-main {
      padding: 20px;
      border-bottom: 1px solid #EBEBEB; }
      .header__menu-main li {
        margin-bottom: 20px; }
        .header__menu-main li:last-of-type {
          margin-bottom: 0; }
        .header__menu-main li a {
          font-size: 16px;
          line-height: 19px;
          color: #3F4042; }
    .header__menu-info {
      padding: 20px; }
    .header__city {
      margin-bottom: 20px;
      color: #3F4042;
      margin-right: 0; }
    .header__tel {
      color: #3F4042; }
      .header__tel a {
        display: block;
        margin-left: 0;
        margin-top: 13px;
        font-weight: 500;
        font-size: 18px;
        line-height: 121.03%; }
  .menu {
    display: none;
    padding: 0 0 100px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 112;
    overflow: auto;
    background: #ffffff; }
    .menu.open {
      display: block; }
    .menu .logo {
      display: none; }
    .menu__submenu {
      width: 100%;
      height: auto;
      display: none;
      left: 0;
      padding: 20px 0;
      position: relative;
      border-top: 1px solid #EBEBEB;
      overflow: inherit; }
      .menu__submenu-list {
        display: block;
        float: none; }
      .menu__submenu-logo {
        width: 100%;
        padding: 20px 0 10px;
        position: relative;
        right: 0; }
        .menu__submenu-logo img {
          max-width: 120px;
          display: inline-block;
          vertical-align: middle; }
    .menu > ul {
      padding: 0 20px; }
      .menu > ul > li > a {
        pointer-events: none;
        cursor: default; }
      .menu > ul > li.open .menu__submenu {
        display: block; opacity: 1; visibility: visible;}
    .menu__top {
      display: block;
      position: relative;
      text-align: center; }
      .menu__top-title {
        width: 100%;
        padding: 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 121.03%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #3F4042;
        background: #F5F4F4; }
      .menu__top-arrow {
        width: 9px;
        height: 26px;
        position: absolute;
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto; }
        .menu__top-arrow img {
          width: 9px;
          height: 12px; }
      .menu__top-close {
        width: 14px;
        height: 26px;
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto; }
        .menu__top-close img {
          width: 14px;
          height: 14px; }
  .banner {
    padding: 20px 0; }
  .wrapper {
    padding-left: 0; }
  .catalog-min .col-lg-2-1 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
    /*.catalog-min .col-lg-2-1:nth-of-type(3) {
      display: block; }*/
  .bottom-block {
    background: #F5F4F4; }
    .bottom-block__img {
      display: none; }
  .delivery-tw {
    margin-bottom: 30px; }
  .card-product {
    margin-bottom: 40px; }
    .card-product__slider {
      width: 100%;
      flex-wrap: wrap; }
      .card-product__slider-for {
        width: 100%;
        order: 1;
        margin-left: 0; }
        .card-product__slider-for .slick-arrow {
          display: none !important; }
        .card-product__slider-for-item {
          height: 330px;
          background-size: contain; }
      .card-product__slider-nav {
        margin: 0 -3px; }
        .card-product__slider-nav .slick-slide {
          padding: 0 3px; }
        .card-product__slider-nav-item {
          width: 75px; }
      .card-product__slider-nav {
        width: 100%;
        order: 2; }
    .card-product h1 {
      margin-bottom: 25px;
      display: none; }
    .card-product__description {
      width: 100%;
      padding-left: 0;
      margin-top: 25px; }
      .card-product__description p {
        margin-bottom: 20px; }
      .card-product__description-top {
        margin-bottom: 15px;
        display: none; }
      .card-product__description-link {
        background-position: 98% center; }
      .card-product__description-btns {
        margin-bottom: 20px; }
        .card-product__description-btns .button {
          margin-right: 17px; }
    .card-product__top-mob {
      display: block; }
      .card-product__top-mob h1,
      .card-product__top-mob .card-product__description-top {
        display: flex; }
    .card-product__all-reviews, .card-product__all-logo {
      height: 94px;
      padding: 18px 10px;
      margin-right: 14px; }
    .card-product__reviews-item {
      padding: 18px 15px; }
    .card-product__reviews-info {
      margin-bottom: 10px; }
      .card-product__reviews-info-rating, .card-product__reviews-info-data {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px; }
    .card-product__tabs-holder {
      display: block; }
    .card-product__tabs-label-item {
      width: 84px;
      margin-right: 9px;
      padding: 60px 20px 13px;
      background: #ffffff;
      box-shadow: 3px 4px 14px rgba(67, 26, 26, 0.08);
      border-radius: 10px;
      text-align: center;
      justify-content: center; }
      .card-product__tabs-label-item:last-of-type {
        margin-right: 0; }
      .card-product__tabs-label-item img {
        top: 0;
        right: 0;
        margin: auto; }
    .card-product__tabs-inner {
      width: 100%;
      padding-right: 0; }
    .card-product__tabs-text {
      margin-bottom: 55px; }
      .card-product__tabs-text-mob {
        display: none; }
        .card-product__tabs-text-mob.open {
          display: block; }
        .card-product__tabs-text-mob-btn {
          display: block;
          margin-top: 20px;
          padding: 13px;
          font-weight: 500;
          font-size: 13px;
          line-height: 121.03%;
          color: #3F4042;
          text-align: center;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          border: 1px solid #3F4042;
          border-radius: 10px; }
          .card-product__tabs-text-mob-btn.open {
            display: none; }
    .card-product__right {
      width: 100%;
      margin-top: 30px; }
    .card-product__inner-tw {
      margin: 0; }
    .card-product__bann {
      margin-bottom: 10px; }
    .card-product__other {
      margin-top: 35px;
      width: 100%; }
  .nav-link {
    padding: 15px; }
  .nav-tabs {
    overflow: auto;
    display: flex;
    flex-wrap: inherit;
    margin-bottom: 25px; }
  .tx__inner {
    padding: 45px 0px; }
  .check-in {
    margin: 50px 0; }
    .check-in__inner {
      display: block; }
    .check-in__tx {
      width: 100%; }
    .check-in__img {
      display: none; }
  .lk {
    margin-bottom: 50px; }
    .lk__left {
      width: 100%;
      padding-right: 0;
      order: 2; }
    .lk__menu {
      order: 1;
      width: 100%;
      margin-bottom: 30px; }
    .lk__setting-form {
      width: 100%; } }

@media screen and (max-width: 768px) {
  .order__delivery .order__payment-list.fl {
    display: block; }
  .order__payment-list-change-tx {
    font-size: 14px;
    margin-bottom: 10px;
    padding-right: 0; }
  .card-product__slider-label {
    display: block;
    margin-top: 0; }
  .about-text h1 {
    font-weight: 500;
    font-size: 26px;
    line-height: 130%; }
  .about-text__tx p {
    font-size: 20px;
    line-height: 30px; }
  .about-work {
    padding: 70px 0; }
    .about-work h2 {
      font-size: 26px;
      line-height: 130%; }
    .about-work__inner:before {
      display: none; }
    .about-work__inner:after {
      left: 0; }
  .about-all__item p {
    font-size: 16px;
    line-height: 24px; }
  .about-all__item-title {
    font-size: 22px;
    line-height: 30px; }
    .about-all__item-title-min {
      font-size: 16px;
      line-height: 24px; }
  .catalog-brand-title__in-list {
    right: inherit;
    left: 0; }
    .catalog-brand-title__in-list:after {
      right: inherit;
      left: 30px; }
  .alphabet {
    margin-bottom: 50px; }
    .alphabet-list-item {
      flex: 0 0 50%;
      max-width: 50%;
      font-size: 16px;
      line-height: 22px; }
    .alphabet-list-lt.tw {
      width: 100%; }
  .pay-descrip {
    margin-bottom: 50px; }
    .pay-descrip p {
      font-size: 16px;
      line-height: 22px; }
    .pay-descrip__item {
      padding: 15px; }
  .pay-list {
    margin-bottom: 50px; }
    .pay-list__row {
      display: block; }
    .pay-list__icon, .pay-list__title, .pay-list__tx {
      width: 100%; }
    .pay-list__icon {
      margin-bottom: 10px; }
    .pay-list__title {
      margin-bottom: 10px; }
  h1 {
    font-weight: 500;
    font-size: 26px;
    line-height: 130%; }
  .actions {
    margin-bottom: 50px; }
  .title-main {
    margin: 11px 0 25px; }
    .title-main-tw {
      padding-right: 0 !important; }
    .title-main__numb {
      font-size: 14px;
      line-height: 16px; }
  .button-all {
    padding: 7px 15px;
    font-size: 10px;
    line-height: 121.03%; }
    .button-all span {
      display: none; }
  .button-bord {
    padding: 8px 19px;
    font-size: 14px; }
  .banner {
    padding: 20px 0; }
    .banner__big {
      height: auto; }
      .banner__big-slider-item {
        padding: 19px 16px 40px;
        height: auto; }
        .banner__big-slider-item .button {
          display: none; }
      .banner__big-slider-title {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 20.0608px;
        line-height: 130%;
        color: #000000; }
      .banner__big-slider-tx {
        margin-bottom: 10px;
        font-size: 14.0426px;
        line-height: 17px; }
    .banner__al {
      margin: 0 -10px; }
    .banner__min {
      height: auto;
      padding: 15px 9px;
      min-height: 65px;
      margin-bottom: 0; }
      .banner__min-in {
        padding: 0 10px; }
      .banner__min-title {
        font-weight: 500;
        font-size: 6.58741px;
        line-height: 130%; }
  .banner .slick-arrow {
    display: none !important; }
  .catalog {
    margin: 30px 0 50px; }
    .catalog-min {
      margin-bottom: 45px; }
      .catalog-min-tw .col-lg-2-1 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        margin-bottom: 0; }
      .catalog-min .row {
        margin: 0 -7px; }
      .catalog-min .col-lg-2-1 {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 7px; }
        /*.catalog-min .col-lg-2-1:nth-of-type(3) {
          display: none; }*/
      .catalog-min__top {
        margin-bottom: 10px;
        padding: 7px 12px; }
        .catalog-min__top-title {
          width: 55%;
          font-size: 14px;
          line-height: 16px; }
          .catalog-min__top-title img {
            display: none; }
    .catalog__item-bt {
      padding-bottom: 105px; }
    .catalog__item-label {
      width: 27px;
      height: 27px; }
      .catalog__item-label + .catalog__item-label {
        left: 35px; }
        .catalog__item-label + .catalog__item-label + .catalog__item-label {
          left: 57px; }
      .catalog__item-label:before {
        width: 23px;
        height: 23px; }
      .catalog__item-label:after {
        width: 21px;
        height: 21px; }
      .catalog__item-label span {
        font-size: 7.57895px;
        line-height: 9px; }
    .catalog__item-title {
      font-size: 12px;
      line-height: 121.03%; }
    .catalog__item-numb {
      font-size: 10px;
      line-height: 11px; }
    .catalog__item-info {
      display: block;
      padding-bottom: 10px; }
    .catalog__item-buy {
      height: 27px;
      width: 100%;
      font-size: 10px;
      line-height: 121.03%;
      border-radius: 5px; }
      .catalog__item-buy img {
        width: 15px; }
    .catalog__item-amount {
      width: 100%;
      height: 30px; }
      .catalog__item-amount .up,
      .catalog__item-amount .down {
        width: 22px;
        height: 22px; }
      .catalog__item-amount input {
        font-size: 12px; }
    .catalog__item-offer {
      padding: 7px 15px;
      font-size: 10px;
      line-height: 11px; }
    .catalog__item-price {
      margin-bottom: 5px; }
      .catalog__item-price-old {
        display: inline-block;
        margin-right: 5px;
        font-size: 10px;
        line-height: 11px; }
      .catalog__item-price-now {
        display: inline-block;
        font-size: 14px;
        line-height: 16px; }
    .catalog-brand-title {
      padding: 15px 0 30px; }
      .catalog-brand-title__holder {
        display: block;
        margin-top: 17px; }
      .catalog-brand-title__main {
        margin-bottom: 18px; }
    .catalog__top {
      flex-wrap: wrap;
      margin-bottom: 0;
      border: none;
      padding-top: 0; }
    .catalog__sorting {
      margin-bottom: 20px;
      order: 1;
      max-width: 100%;
      flex: 0 0 100%;
      text-align: left; }
      .catalog__sorting-list {
        margin-left: 0; }
        .catalog__sorting-list li a {
          font-size: 12px;
          padding: 7px 11px; }
      .catalog__sorting-title {
        font-size: 12px; }
    .catalog__list .row {
      margin: 0 -7px; }
    .catalog__list .col-lg-2-1 {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0 7px; }
    .catalog-subscription {
      margin-bottom: 20px; }
      .catalog-subscription .row {
        margin: 0 -7px; }
      .catalog-subscription .col-lg-2-1 {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 7px; }
      .catalog-subscription__top {
        display: block;
        margin-bottom: 10px;
        border: none; }
        .catalog-subscription__top p {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 18px; }
      .catalog-subscription__item-title {
        padding-top: 15px;
        font-size: 14px; }
  .delivery {
    padding: 15px 0 40px; }
    .delivery-tw {
      padding: 30px 0; }
      .delivery-tw__title {
        margin-bottom: 10px;
        font-size: 19px;
        line-height: 26px; }
      .delivery-tw__img {
        margin-bottom: 30px; }
      .delivery-tw__info {
        padding-left: 0; }
        .delivery-tw__info p {
          margin-bottom: 25px;
          font-size: 14px; }
      .delivery-tw__list {
        display: block; }
        .delivery-tw__list-item {
          display: flex;
          margin-right: 0;
          margin-bottom: 20px; }
          .delivery-tw__list-item:last-of-type {
            margin-bottom: 0; }
    .delivery__info {
      padding: 0 0 30px; }
      .delivery__info-title {
        margin-bottom: 16px; }
      .delivery__info p {
        font-size: 13px; }
    .delivery__order {
      padding: 29px 16px 40px; }
      .delivery__order-title {
        font-size: 18px; }
      .delivery__order p {
        margin-bottom: 25px;
        font-size: 14px; }
  .bottom-block__title {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 25px; }
  .bottom-block__info {
    padding: 30px 0 15px; }
  .footer__menu {
    margin-bottom: 25px; }
    .footer__menu li {
      display: block;
      margin-bottom: 10px;
      margin-right: 0; }
      .footer__menu li a {
        text-transform: inherit; }
  .footer__top-inner {
    display: block;
    padding: 18px 0; }
  .footer__tel, .footer__mail {
    justify-content: flex-start;
    margin-left: 0;
    margin-bottom: 4px; }
  .footer__tel {
    font-weight: 500;
    font-size: 18px;
    line-height: 121.03%;
    color: #4F742E; }
  .footer__mail {
    font-size: 14px;
    line-height: 16px;
    text-transform: inherit; }
  .footer__bottom {
    position: relative; }
    .footer__bottom-inner {
      display: block;
      padding: 24px 0; }
    .footer__bottom-descr {
      display: block;
      width: 100%; }
    .footer__bottom-info li a {
      font-size: 12px;
      line-height: 24px; }
  .footer__dev {
    margin-top: 15px;
    font-size: 12px;
    line-height: 24px; }
  .footer__soc {
    position: absolute;
    top: -56px;
    right: 0; }
  .breadcrumb-block {
    margin: 8px 0; }
  .breadcrumb-item {
    display: none; }
    .breadcrumb-item:before {
      display: none !important; }
    .breadcrumb-item:nth-last-of-type(2) {
      display: inline-block; }
      .breadcrumb-item:nth-last-of-type(2) a {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 150%; }
        .breadcrumb-item:nth-last-of-type(2) a:before {
          content: '<';
          display: inline-block;
          vertical-align: middle;
          width: 14px;
          height: 100%;
          margin-right: 0; }
  .actions-item {
    margin-bottom: 50px; }
    .actions-item__info {
      display: block; }
      .actions-item__info-item {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px; }
    .actions-item__left {
      width: 100%;
      margin-bottom: 30px; }
    .actions-item__right {
      width: 100%;
      padding-left: 0; }
    .actions-item__inner {
      display: block; }
  .actions__item-img {
    height: 160px; }
  .actions__item-tx {
    padding: 19px 17px 45px; }
  .actions-slider {
    margin: 30px 0; }
    .actions-slider__inner .title-main-tw .button {
      display: none; }
    .actions-slider__sldr .slick-slide {
      width: 266px; }
  .filter {
    order: 2;
    max-width: 100%;
    flex: 0 0 100%; }
    .filter__btn {
      height: auto;
      width: 100%;
      padding: 12px;
      text-align: center;
      border: 2px solid #3F4042;
      border-radius: 10px; }
  .pagination {
    padding: 0 39px;
    position: relative; }
    .pagination .page-item {
      margin: 0;
      position: relative; }
      .pagination .page-item.prev, .pagination .page-item.next {
        width: 39px;
        height: 39px;
        margin: 0;
        position: relative; }
        .pagination .page-item.prev .tx, .pagination .page-item.next .tx {
          display: none; }
        .pagination .page-item.prev a, .pagination .page-item.next a {
          padding: 0; }
      .pagination .page-item.next {
        margin-left: 0;
        right: -42px; }
      .pagination .page-item.prev {
        left: -42px; }
  .subscription-list {
    margin-bottom: 10px; }
    .subscription-list__item-tx {
      font-size: 14px;
      line-height: 18px; }
  .subscription-tx__inner {
    display: block; }
  .card-product__reviews-btn {
    display: block;
    margin-top: 20px;
    padding: 13px;
    font-weight: 500;
    font-size: 13px;
    line-height: 121.03%;
    color: #3F4042;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    border: 1px solid #3F4042;
    border-radius: 10px;
    background: transparent; }
  .card-product__product-row {
    display: block;
    padding-left: 110px;
    position: relative; }
  .card-product__product-descr {
    padding: 0;
    position: inherit;
    min-height: inherit; }
  .card-product__product-btn {
    margin-top: 22px; }
    .card-product__product-btn-main {
      padding: 13px;
      border-radius: 10px;
      border: 1px solid #3F4042;
      background: transparent; }
  .card-product__product-price {
    margin-top: 13px; }
    .card-product__product-price-now, .card-product__product-price-old {
      display: inline-block; }
  .card-product__product-img {
    position: absolute;
    left: -110px; }
  .card-product__product-title {
    margin-bottom: 5px; }
  .card-product__slider {
    width: 100%;
    flex-wrap: wrap; }
    .card-product__slider-for {
      width: 100%;
      order: 1;
      margin-left: 0; }
      .card-product__slider-for .slick-arrow {
        display: none !important; }
      .card-product__slider-for-item {
        height: 330px;
        background-size: contain; }
    .card-product__slider-nav {
      margin: 0 -3px; }
      .card-product__slider-nav .slick-slide {
        padding: 0 3px; }
      .card-product__slider-nav-item {
        width: 75px; }
    .card-product__slider-nav {
      width: 100%;
      order: 2; }
  .card-product__description p {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 146%; }
  .card-product__description-link {
    font-size: 14px;
    line-height: 146%;
    background-position: 98% center; }
  .card-product__tabs-holder {
    display: block; }
  .card-product__tabs-table {
    margin-bottom: 20px; }
    .card-product__tabs-table-cell {
      font-size: 14px;
      line-height: 160%; }
      .card-product__tabs-table-cell-city {
        color: #4F742E; }
    .card-product__tabs-table-row {
      margin-bottom: 10px; }
      .card-product__tabs-table-row:after {
        bottom: 4px; }
  .card-product__tabs-storage-title {
    margin-bottom: 10px; }
  .card-product__tabs-storage p {
    font-size: 14px;
    line-height: 170%; }
  .card-product__tabs-label-text p, .card-product__tabs-label-text ol li {
    font-size: 14px;
    line-height: 170%; }
  .subscription-tx__inner p {
    font-size: 14px;
    line-height: 18px; }
  .cart-banner {
    margin-bottom: 50px; }
    .cart-banner-tw {
      padding-left: 0; }
      .cart-banner-tw .cart-banner__inner {
        display: block;
        padding: 130px 20px 30px;
        text-align: center;
        border-radius: 15px;
        background-image: url("../images/cart-banner2-2.png");
        background-repeat: no-repeat;
        background-position: center 11%; }
      .cart-banner-tw .cart-banner__tx {
        padding: 0;
        display: block; }
        .cart-banner-tw .cart-banner__tx p {
          margin-bottom: 12px; }
    .cart-banner__inner {
      display: block;
      padding: 135px 20px 30px;
      text-align: center;
      border-radius: 15px;
      background-image: url("../images/cart-banner1-1.png");
      background-repeat: no-repeat;
      background-position: top center; }
    .cart-banner__tx {
      padding-left: 0; }
    .cart-banner__img {
      display: none; }
    .cart-banner__title {
      margin-right: 0;
      margin-bottom: 0; }
    .cart-banner__btn {
      margin-top: 20px;
      text-align: center; }
      .cart-banner__btn img {
        display: block;
        margin: 10px auto 0; }
    .cart-banner__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 139%; }
  .cart__list-item {
    display: block;
    padding: 25px 15px 15px 110px;
    position: relative; }
  .cart__list-title {
    margin-bottom: 8px; }
  .cart__list-descr, .cart__list-numb, .cart__list-amount, .cart__list-delete {
    max-width: 100%;
    flex: 0 0 100%; }
  .cart__list-descr {
    padding: 0;
    position: inherit;
    min-height: inherit;
    margin-bottom: 20px; }
  .cart__list-amount {
    margin-bottom: 25px; }
    .cart__list-amount .catalog__item-amount {
      width: 128px; }
  .cart__list-img {
    position: absolute;
    left: -110px;
    top: 0; }
  .cart__list-delete {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 15px;
    background-repeat: no-repeat;
    background-image: url("../images/svg/close-i.svg");
    background-size: 13px 13px; }
    .cart__list-delete svg {
      display: none; }
  .cart__list-all {
    flex-wrap: wrap;
    padding: 0;
    margin: 45px 0; }
    .cart__list-all .cart__list-descr {
      margin-bottom: 30px; }
    .cart__list-all-btn {
      max-width: 100%;
      flex: 0 0 100%; }
      .cart__list-all-btn .button {
        width: 100%;
        margin-top: 20px; }
    .cart__list-all-tx, .cart__list-all-price {
      max-width: 50%;
      flex: 0 0 50%; }
    .cart__list-all-tx-title {
      margin-bottom: 10px; }
    .cart__list-all-price {
      text-align: right; }
  .cart__list-promo {
    width: 100%; }
  .tx__inner {
    display: block;
    padding: 25px 0px;
    margin-bottom: 40px;
    text-align: center; }
  .tx__title {
    font-size: 20px;
    line-height: 24px; }
    .tx__title.big {
      font-size: 60px;
      line-height: 80px; }
  .tx__img img {
    display: block;
    margin: auto;
    max-width: 200px; }
  .tx__text {
    padding-left: 0;
    margin-top: 20px; }
    .tx__text p {
      font-size: 14px;
      line-height: 146%; }
    .tx__text .button {
      display: block;
      width: 100%;
      padding: 15px 24px; }
  .order__delivery {
    padding-bottom: 15px; }
  .order__range {
    width: 100%;
    padding-bottom: 30px; }
  .order__wrap {
    padding: 0 10px; }
    .order__wrap-top {
      display: block;
      margin: 0 -10px 20px;
      padding: 10px; }
  .order__payment-list {
    display: block; }
    .order__payment-list-item-in {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%; }
  .order__input-cell {
    margin-bottom: 20px; }
  .order .cart__list-item {
    padding: 25px 15px 15px 110px; }
  .order .cart__list-descr {
    margin-bottom: 10px; }
  .order .cart__list-numb.last {
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 16px; }
  .order .cart__list-descr, .order .cart__list-numb {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
    position: relative;
    bottom: 0;
    text-align: left; }
  .policy {
    margin-bottom: 50px; }
    .policy__inner p, .policy__inner ul li {
      font-size: 14px;
      line-height: 20px; }
    .policy__inner p {
      margin-bottom: 30px; }
    .policy__inner h3 {
      font-size: 18px; }
  .orders__top {
    display: block; }
  .orders__title {
    margin-bottom: 10px; }
  .orders__item {
    padding: 20px 10px; }
    .orders__item-descr-title {
      display: block; }
      .orders__item-descr-title span {
        display: block; }
    .orders__item-product-row {
      display: block; }
    .orders__item-product-right {
      margin-top: 10px; }
  .filter-bl {
    width: 100%; }
  .filter__top-close {
    display: none !important; }
    .filter-bl .filter__top-close {
      display: flex !important;
      left: inherit;
      right: 10px;
      top: 17px; }
  .modal-content {
    padding: 20px 20px 40px; }
  .modal .close {
    right: 10px;
    border-color: #7ABC2F;
    background: #7ABC2F; }
  .modal-reviews__title {
    padding-right: 70px; }
  .modal-reviews__upload {
    display: block; }
    .modal-reviews__upload-tx {
      padding-left: 0;
      margin-top: 15px; }
  .modal-reviews__bottom {
    display: block; }
  .modal-reviews__rating-list {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 10px; }
  .point__inner {
    margin-bottom: 40px; }
  .point__table {
    width: 100%; }
    .point__table-row {
      padding: 15px 0;
      display: block; }
    .point__table-cell {
      width: 100% !important;
      padding: 3px 0;
      text-align: left !important; }
  .brands__inner {
    margin-bottom: 40px; }
  .brands__all-inner-tw {
    width: 100%; }
  .brands__all-item {
    flex: 0 0 50%;
    max-width: 50%; } }
